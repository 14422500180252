import { JSONSchema7 } from "json-schema";

export const PeriodCheckupMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    previousPainLevel: {
      description: "Previously logged pain level (null if not logged yet)",
      type: ["null", "number"],
    },
  },
  $schema: "http://json-schema.org/draft-07/schema#",
};
