import { JSONSchema7 } from "json-schema";

export const ImageCarouselMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    carouselTitle: {
      description: "Carousel title",
      type: "string",
    },
    carouselDescription: {
      description: "Carousel description text",
      type: "string",
    },
    carouselImageUrls: {
      description: "List of carousel image URLs to show",
      type: "array",
      items: {
        type: "string",
      },
    },
  },
  required: ["carouselDescription", "carouselImageUrls", "carouselTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
