import { gql } from "@apollo/client";
import { paramCase } from "change-case";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { Removed } from "../../components/Removed/Removed";
import { TimeAgo } from "../../components/TimeAgo/TimeAgo";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, useAdminGiftCardsQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatDate } from "../../services/formatDate";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query GiftCards($filter: AdminCategoriesFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      categories(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        categories {
          ...CategoryInfo
        }
      }
    }
  }
`;

interface GiftCardsFilterData extends FilterBaseData {
  creatorId: string;
  giftCardCode: string;
  recipientsEmail: string;
  recipientsName: string;
  sendersEmail: string;
  sendersName: string;
}

export const GiftCardListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<GiftCardsFilterData>((params) => ({
    creatorId: params.creatorId ?? "",
    giftCardCode: params.giftCardCode ?? "",
    recipientsEmail: params.recipientsEmail ?? "",
    recipientsName: params.recipientsName ?? "",
    sendersEmail: params.sendersEmail ?? "",
    sendersName: params.sendersName ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const {
    data: giftCardsData,
    loading: giftCardsLoading,
    error: giftCardsError,
  } = useAdminGiftCardsQuery({
    variables: {
      filter: {
        creatorId: params.creatorId.length > 0 ? params.creatorId : undefined,
        giftCardCode: params.giftCardCode.length > 0 ? params.giftCardCode : undefined,
        recipientsEmail: params.recipientsEmail.length > 0 ? params.recipientsEmail : undefined,
        recipientsName: params.recipientsName.length > 0 ? params.recipientsName : undefined,
        sendersEmail: params.sendersEmail.length > 0 ? params.sendersEmail : undefined,
        sendersName: params.sendersName.length > 0 ? params.sendersName : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  // pagination info
  const giftCards = useMemo(
    () => giftCardsData?.admin.giftCards.giftCards ?? [],
    [giftCardsData?.admin.giftCards.giftCards],
  );

  const total = giftCardsData?.admin.giftCards.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "creatorId",
        label: "Purchaser ID",
        defaultValue: params.creatorId,
      },
      {
        field: "text",
        type: "text",
        name: "giftCardCode",
        label: "Code",
        defaultValue: params.giftCardCode,
      },
      {
        field: "text",
        type: "text",
        name: "recipientsEmail",
        label: "Recipient's Email",
        defaultValue: params.recipientsEmail,
      },
      {
        field: "text",
        type: "text",
        name: "recipientsName",
        label: "Recipient's Name",
        defaultValue: params.recipientsName,
      },
      {
        field: "text",
        type: "text",
        name: "sendersEmail",
        label: "Senders Email",
        defaultValue: params.sendersEmail,
      },
      {
        field: "text",
        type: "text",
        name: "sendersName",
        label: "Senders Name",
        defaultValue: params.sendersName,
      },
    ],
    [
      params.creatorId,
      params.giftCardCode,
      params.recipientsEmail,
      params.recipientsName,
      params.sendersEmail,
      params.sendersName,
    ],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Purchaser ID",
      },
      {
        label: "Code",
      },
      {
        label: "Used",
      },
      {
        label: "Recipient's email",
      },
      {
        label: "Senders email",
      },
      {
        label: "Type(duration)",
      },
      {
        label: "Purchased",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      giftCards.map((giftCard) => {
        return {
          id: giftCard.id,
          cells: [
            {
              content: giftCard.creatorId ?? "n/a",
            },
            {
              content: giftCard.deletedDate ? <Removed>{giftCard.code}</Removed> : giftCard.code,
            },
            {
              content: giftCard.activationDate ? <TimeAgo date={giftCard.activationDate!}></TimeAgo> : "no",
            },
            {
              content: giftCard.toEmail,
            },
            {
              content: giftCard.creator?.email ?? "n/a",
            },
            {
              content: giftCard.metaData.identifier.toUpperCase(),
            },
            {
              content: formatDate(giftCard.createdDate),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_GIFT_CARDS,
                UserScopeEnum.ADMIN_GIFT_CARDS_INFO,
              ],
              onClick: (id) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "gift-cards",
                    page: "gift-card",
                    id: id,
                  }),
                }),
            },
            {
              label: "Purchaser details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_USERS, UserScopeEnum.ADMIN_USERS_INFO],
              onClick: () =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "users",
                    page: "user",
                    id: giftCard.creatorId ?? undefined,
                  }),
                }),
            },
            giftCard.activatedById
              ? {
                  label: "Activator details",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMIN_USERS,
                    UserScopeEnum.ADMIN_USERS_INFO,
                  ],
                  onClick: () =>
                    navigate({
                      pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                        menu: "users",
                        page: "user",
                        id: giftCard.activatedById!,
                      }),
                    }),
                }
              : null,
          ],
        };
      }),
    [giftCards, navigate],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: GiftCardsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "gift-cards" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (giftCardsError) {
    return <ErrorView title="Fetching categories failed" error={giftCardsError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Gift Cards"
          fields={filters}
          viewerscopes={viewer.scopes}
          loading={giftCardsLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={giftCardsLoading}
          stats={{ resultCount: total, pageCount }}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_GIFT_CARDS,
            UserScopeEnum.ADMIN_GIFT_CARDS_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "gift-cards",
                page: "gift-card",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "gift-cards" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />
      </View>
    </>
  );
};
