import { gql } from "@apollo/client";
import { paramCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateAppReviewModal } from "../../modals/CreateAppReviewModal/CreateAppReviewModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, LocaleEnum, MatchModeEnum, useAppReviewsQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { limitTextLength } from "../../services/limitTextLength";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query AppReviews($filter: AdminAppReviewsFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      adminAppReviews(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        appReviews {
          ...AppReviewInfo
        }
      }
    }
  }
`;

interface AppReviewsFilterData extends FilterBaseData {
  appReviewId: string;
  categoryId: string;
  authorName: string;
}

export const AppReviewsListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<AppReviewsFilterData>((params) => ({
    appReviewId: params.appReviewId ?? "",
    categoryId: params.categoryId ?? "",
    authorName: params.authorName ?? "",

    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    locale: params.locale ? (params.locale.split(",") as LocaleEnum[]) : [],
  }));

  const {
    data: appReviewsData,
    loading: appReviewsLoading,
    error: appReviewsError,
  } = useAppReviewsQuery({
    variables: {
      filter: {
        appReviewId: params.appReviewId.length > 0 ? params.appReviewId : undefined,
        categoryId: params.categoryId.length > 0 ? params.categoryId : undefined,
        authorName: params.authorName.length > 0 ? params.authorName : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const [isCreateAppReviewModalOpen, setIsCreateAppReviewModalOpen] = useState(false);

  // pagination info
  const appReviews = useMemo(
    () => appReviewsData?.admin.adminAppReviews.appReviews ?? [],
    [appReviewsData?.admin.adminAppReviews.appReviews],
  );

  const total = appReviewsData?.admin.adminAppReviews.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "appReviewId",
        label: "App review id",
        defaultValue: params.appReviewId,
      },
      {
        field: "text",
        type: "text",
        name: "categoryId",
        label: "Category id",
        defaultValue: params.categoryId,
      },
      {
        field: "text",
        type: "text",
        name: "authorName",
        label: "Author name",
        defaultValue: params.authorName,
      },
    ],
    [params.authorName, params.appReviewId, params.categoryId],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Review",
      },
      {
        label: "Rating",
        center: true,
      },
      {
        label: "Author name",
      },
      {
        label: "Author title",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      appReviews.map((appReview) => {
        return {
          id: appReview.id,
          cells: [
            {
              content: limitTextLength(appReview.review),
            },
            {
              content: appReview.rating,
              center: true,
            },
            {
              content: appReview.authorName,
            },
            {
              content: appReview.authorTitle,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_COURSES,
                UserScopeEnum.ADMIN_COURSES_INFO,
              ],
              onClick: (appReviewId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "app-reviews",
                    page: "app-review",
                    id: appReviewId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_COURSES,
                UserScopeEnum.ADMIN_COURSES_UPDATE_INFO,
              ],
              onClick: (appReviewId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "app-reviews",
                    page: "app-review",
                    id: appReviewId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [appReviews, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create app review",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_APP_REVIEWS,
          UserScopeEnum.ADMIN_APP_REVIEWS_CREATE,
        ],
        onClick: () => setIsCreateAppReviewModalOpen(true),
      },
    ],
    [],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: AppReviewsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "app-reviews" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (appReviewsError) {
    return <ErrorView title="Fetching app reviews failed" error={appReviewsError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="App reviews"
          fields={filters}
          actions={viewActions}
          viewerscopes={viewer.scopes}
          loading={appReviewsLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={appReviewsLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_APP_REVIEWS,
            UserScopeEnum.ADMIN_APP_REVIEWS_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "app-reviews",
                page: "app-review",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "app-reviews" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />

        <CreateAppReviewModal
          open={isCreateAppReviewModalOpen}
          onClickOutside={() => setIsCreateAppReviewModalOpen(false)}
        />
      </View>
    </>
  );
};
