import { JSONSchema7 } from "json-schema";

export const JourneyMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    journeyId: {
      description: "Unique identifier of the journey",
      type: "string",
    },
    journeyImageUrl: {
      description: "Feed item image url",
      type: ["null", "string"],
    },
    journeyPostTitle: {
      description: "Feed journey post title",
      type: "string",
    },
    journeyPostText: {
      description: "Feed journey post description text",
      type: "string",
    },
  },
  required: ["journeyId", "journeyPostText", "journeyPostTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
