import { gql } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { P } from "../../components/Paragraph/Paragraph";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DeleteTrackModal } from "../../modals/DeleteTrackModal/DeleteTrackModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  CollaborationTypeEnum,
  Track,
  TrackByIdQueryResult,
  useAdminDeleteTrackMutation,
  useAdminUpdateTrackInfoMutation,
  UserScopeEnum,
  useTrackByIdQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getCategoryNameValueList } from "../../services/getCategoryNameValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTrackNameValueList } from "../../services/getTrackNameValueList";
import { getTranslationInput } from "../../services/getTranslationInput";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query TrackById($trackId: ID!) {
    admin {
      track(trackId: $trackId) {
        ...TrackInfo
        category {
          ...CategoryInfo
        }
      }
    }
  }
`;

gql`
  mutation AdminUpdateTrackInfo(
    $trackId: ID!
    $title: TranslationInput!
    $description: TranslationInput!
    $wowzaTrackName: String!
    $trailerUrl: String
    $trackDurationSeconds: Int!
    $isFree: Boolean!
    $comment: String!
    $smallBackgroundImage: Upload
    $largeBackgroundImage: Upload
    $trackNumber: Int!
  ) {
    adminUpdateTrackInfo(
      trackId: $trackId
      title: $title
      description: $description
      wowzaTrackName: $wowzaTrackName
      trailerUrl: $trailerUrl
      trackDurationSeconds: $trackDurationSeconds
      isFree: $isFree
      comment: $comment
      smallBackgroundImage: $smallBackgroundImage
      largeBackgroundImage: $largeBackgroundImage
      trackNumber: $trackNumber
    ) {
      ...TrackInfo
    }
  }
`;

export interface TrackDetailsViewProps {
  viewer: ViewerInfo;
}

type TrackByIdQueryTrackInfo = NonNullable<TrackByIdQueryResult["data"]>["admin"]["track"];

export const TrackDetailsView: React.FC<TrackDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: trackData,
    loading: trackLoading,
    error: trackError,
  } = useTrackByIdQuery({
    variables: {
      trackId: params.id ?? "",
    },
  });

  const track = trackData?.admin.track;

  // handle error
  if (trackError) {
    return <ErrorView title="Loading track info failed" error={trackError} />;
  }

  // handle loading
  if (trackLoading || !track) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <TrackDetailsHeader track={track} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<TrackDetailsInfo track={track} viewer={viewer} />} />
        <Route path="edit" element={<TrackDetailsEdit track={track} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface TrackProps extends FlexProps {
  track: TrackByIdQueryTrackInfo;
  viewer: ViewerInfo;
}

export const TrackDetailsHeader: React.FC<TrackProps> = ({ track, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const { category } = track;
  const [isDeleteTrackModalOpen, setIsDeleteTrackModalOpen] = useState(false);
  const [adminDeleteTrack, adminDeleteTrackResult] = useAdminDeleteTrackMutation();
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_DELETE],
        onClick: () => {
          setIsDeleteTrackModalOpen(true);
        },
      },
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "tracks",
                  page: "track",
                  id: track.id,
                }),
              }),
          }
        : {
            label: "Edit",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "tracks",
                  page: "track",
                  id: track.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, track.id],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      category
        ? {
            label: "Category",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "categories",
                  page: "category",
                  modifier: category.id,
                }),
              }),
          }
        : null,
    ],
    [category, navigate],
  );

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Track details</Title>
      </Header>

      <DeleteTrackModal
        open={isDeleteTrackModalOpen}
        onCancel={() => setIsDeleteTrackModalOpen(false)}
        onClickOutside={() => setIsDeleteTrackModalOpen(false)}
        loading={adminDeleteTrackResult.loading}
        error={adminDeleteTrackResult.error}
        onSubmit={async () => {
          const response = await adminDeleteTrack({
            variables: {
              trackIds: track.id,
            },
          });

          if (response.data) {
            setIsDeleteTrackModalOpen(false);

            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "tracks",
              }),
            });
          }
        }}
      />
    </>
  );
};

export const TrackDetailsInfo: React.FC<TrackProps> = ({ track, viewer }) => {
  const navigate = useNavigate();

  const { category } = track;
  const trackDetails = getTrackNameValueList(track as Track);
  const categoryDetails = category ? getCategoryNameValueList(category) : null;

  return (
    <WithCollaboration referenceId={track.id} type={CollaborationTypeEnum.TRACK} viewer={viewer}>
      <Title>Track</Title>
      <NameValueList items={trackDetails} />

      {category && categoryDetails ? (
        <>
          <Title
            marginTop="half"
            actions={[
              {
                label: "Details",
                onClick: () =>
                  navigate({
                    pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                      menu: "categories",
                      page: "category",
                      id: category.id,
                    }),
                  }),
              },
            ]}
          >
            Category
          </Title>
          <NameValueList items={categoryDetails} />
        </>
      ) : (
        <>
          <Title marginTop>Category</Title>
          <P italic light>
            The track does not have associated category
          </P>
        </>
      )}
    </WithCollaboration>
  );
};

type EditTrackData = {
  wowzaTrackName: string;
  smallBackgroundImageUrl: string;
  largeBackgroundImageUrl: string;
  trailerUrl: string;
  trackDurationSeconds: string;
  isFree: "true" | "false";
  trackNumber: string;
  comment: string;
};

export const TrackDetailsEdit: React.FC<TrackProps> = ({ track, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateTrackInfo, adminUpdateTrackInfoResult] = useAdminUpdateTrackInfoMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "title",
        label: "Title",
        defaultValue: track.titleTranslations,
      },
      {
        field: "translation",
        name: "description",
        label: "Description",
        defaultValue: track.descriptionTranslations,
      },
      {
        field: "text",
        name: "wowzaTrackName",
        label: "Wowza track name",
        defaultValue: track.wowzaTrackName,
        rules: {
          required: "Please provide wowza track name",
        },
      },
      {
        field: "text",
        name: "trailerUrl",
        label: "Trailer url",
        defaultValue: track.trailerUrl ?? undefined,
      },
      {
        field: "text",
        name: "trackDurationSeconds",
        label: "Track duration seconds",
        defaultValue: track.trackDurationSeconds.toString(),
        rules: {
          required: "Please provide track duration seconds",
          min: 0,
        },
      },
      {
        field: "radio",
        name: "isFree",
        label: "Is track free",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: track.isFree ? "true" : "false",
      },
      {
        field: "upload",
        type: "text",
        name: "smallBackgroundImage",
        label: "Small background image (leave blank to not change)",
      },
      {
        field: "upload",
        type: "text",
        name: "largeBackgroundImage",
        label: "Large background image (leave blank to not change)",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated track info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
      {
        field: "text",
        type: "text",
        name: "trackNumber",
        label: "Track number",
        defaultValue: track.trackNumber.toString(),
      },
    ],
    [
      track.descriptionTranslations,
      track.isFree,
      track.titleTranslations,
      track.trackDurationSeconds,
      track.trailerUrl,
      track.wowzaTrackName,
      track.trackNumber,
    ],
  );

  const onSubmit = useCallback(
    async (data: EditTrackData) => {
      const smallBackgroundImage = getFileUploadById("smallBackgroundImage");
      const largeBackgroundImage = getFileUploadById("largeBackgroundImage");

      const response = await adminUpdateTrackInfo({
        variables: {
          ...data,
          title: getTranslationInput("title", data),
          description: getTranslationInput("description", data),
          trackId: track.id,
          trackDurationSeconds: parseInt(data.trackDurationSeconds),
          smallBackgroundImage,
          largeBackgroundImage,
          isFree: data.isFree === "true",
          trackNumber: data.trackNumber ? parseInt(data.trackNumber) : 0,
        },
        refetchQueries: ["CollaborationById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "tracks",
            page: "track",
            id: track.id,
          }),
        });
      }
    },
    [adminUpdateTrackInfo, navigate, track.id],
  );

  return (
    <WithCollaboration referenceId={track.id} type={CollaborationTypeEnum.TRACK} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Track info"
        loading={adminUpdateTrackInfoResult.loading}
        error={adminUpdateTrackInfoResult.error}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
