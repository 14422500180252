import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { CourseByIdQueryCourseInfo } from "../../views/CourseDetailsView/CourseDetailsView";

export interface DeleteCourseModalProps extends ModalProps {
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  loading: boolean;
  lessons: CourseByIdQueryCourseInfo["lessons"];
}

export const DeleteCourseModal: React.FC<DeleteCourseModalProps> = ({
  onSubmit,
  onCancel,
  lessons,
  loading,
  ...rest
}) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      loading: loading,
      onClick: onSubmit,
    },
  ];
  return (
    <Modal title="Confirm deleting courses" actions={modalActions} {...rest}>
      <P center marginBottom={5}>
        Are you sure you wish to delete these courses
      </P>
      {lessons.length && (
        <>
          <P>If you delete this course, the following lessons will also be deleted:</P>
          <ul>
            {lessons.map((lesson) => (
              <li style={{ marginBottom: 12 }}>{lesson.title}</li>
            ))}
          </ul>
        </>
      )}
    </Modal>
  );
};
