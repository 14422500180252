import { JSONSchema7 } from "json-schema";

export const ReviewMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    reviewId: {
      description: "reviewId",
      type: "string",
    },
  },
  required: ["reviewId"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
