export const changelog = [
  "Improved validation error handling",
  "Added SEO fields to Blog articles",
  "Added image upload views",
  "Added Admin feed forms and views",
  "Changed main menu style",
  "Implemented FAQ and FAQ topic managment views",
  "Added age restriction flags for categories,articles,courses and labels",
  "Added metadata fields for blog article views",
  "Added track reordering field to category info and edit views",
  "Upgraded storybook to use webpack 5",
  "Updated header logo, color",
  "Implemented displaying user timezone",
  "Updated dependencies",
  "Fixed payments filtering by created date range",
  "Added new cycles and water intake options, quiz answers to user details view",
  "Integrated markdown editor to creating and editing of app reviews, authors, courses and lessons",
  "Improved generated form typings and rendering",
  "Implemented markdown field type and integrated it to generated form component",
  "Improved various admin forms, detail views, navigation",
  "Implemented limiting text length in data tables",
  "Implemented single row component",
  "Improved translations editing and displaying, tag component",
  "Improved generated filtering to include default filters",
  "Improved creating course modal",
  "Implemented scrollable modal",
  "Improved create product modal",
  "Implemented generated form date field validation",
  "Refactored creating gift card to be a separate component",
  "Implemented adding app review author avatar",
  "Upgraded and improved storyboard",
  "Implemented server down error view",
  "Implement blog articles creating, editing, removing",
  "Implemented full weight order functionality for lessons, courses and labels",
  "Implemented beginning reviews details view",
  "Implemented beginning reviews list view",
  "Implemented uploading images for tracks",
  "Improved main menu design",
  "Implemented new left main menu based admin design",
  "Implemented create author modal",
  "Implemented admin updating author info",
  "Implemented update admin author mutation",
  "Implemented single authors view",
  "Implemented list of authors view",
  "Implemented create admin author mutation",
  "Implemented admin author by id resolver",
  "Implemented admin authors resolver",
  "Implemented author entity",
  "Updated dependencies",
  "Implemented reloading list of tracks and categories after creating new track/category",
  "Integrated limiting admin UI by scopes for all existing views",
  "Implemented showing data table action buttons based on available user scopes",
  "Added transaltion fields to create track modal",
  "Implemented review info editing",
  "Implemented uploading category small and large background images when creating and updating category",
  "Implemented generated field file upload support",
  "Implemented category small and large background image support",
  "Implemented category small and large background",
  "Implemented upload image service",
  "Improved entity definitions, unified field lengths",
  "Improved subscription details, track details, user details views",
  "Improved categories list, gift card list, payment list, review list, track list views",
  "Implemented admin cross-linking navigation",
  "Implemented review seeder",
  "Review list now shows only pending items by default",
  "Implemented review pending/accepted/declined state",
  "Implemented user avatar updating",
  "Improved detail views uniformity, using same content everywhere",
  "Improved cross-linking between menus",
  "Improved reviews, users, payments, products",
  "Implemented updating category title and description translations",
  "Implemented updating track title and description translations",
  "Implemented generated form translation field type",
  "Implemented rendering translations in admin track and category detail views",
  "Implemented sending push notifications to specific topic",
  "Implemented sending push notifications to specific user",
  "Implemented displaying list of user firebase cloud messaging tokens in details view",
  "Implemented user password reset",
  "Implemented disabling subscriptions",
  "Implemented admin reviews page",
  "Implemented admin tracks page",
  "Implemented admin categories page",
  "Implemented admin gift card view",
  "Implemented updating subscription info",
  "It's now possible to filter subscriptions and users by id",
  "Users details view subscription details button now opens subscription details view",
  "Subscriptions list user name is now clickable and opens user details view",
  "Main menu now uses actual links so can be right-click opened in new tab etc",
  "Implemented filtering by date ranges",
  "Header now uses action bar as well",
  "Implemented generated form action bar support",
  "Implemented action bar component",
  "Refactored filter to use generated form as well",
  "Implemented generated form multi-layout support",
  "Improved Flex component",
  "Improved modal backdrop",
  "Implemented displaying number of results and pages in data-table",
  "Implemented subscriptions list and filters",
  "Implemented date range filter field",
  "Implemented test data seeders for gift cards and subscriptions",
  "Implemented admin user details payment details",
  "Refactored Subscription to be called Product and UserSubscription to be called Subscription",
  "Implemented name-value list component",
  "Implemented header component for generated admin view headers",
  "Improved user details layout",
  "Implemented showing user history in details view",
  "Implemented admin user history resolver",
  "Implemented showing list of changes after updating user",
  "Implemented diff component",
  "Implemented generated form textarea support",
  "Implemented collaboration markdown support",
  "Implemented time-ago display component",
  "Implemented manually adding collaboration comment",
  "Implemented showing collaboration entries in user details view",
  "Implemented collaboration resolver",
  "Implemented serializing users",
  "Implemented storing collaboration entries",
  "Implemented collaboration schema and entity",
  "Implemented update user form",
  "Implemented updating user info",
  "Implemented showing combined server side and form validation errors",
  "Added Products main menu item",
  "Refactored REGULAR role to USER",
  "Implemented with collaboration wrapper",
  "Implemented example collaboration component",
  "Implemented example admin user history layout",
  "Implemented edit user form",
  "Implemented generated form component",
  "Implemented splitting user details view into sub-view components",
  "Implemented displaying user subscriptions",
  "Implemented server root typings support, improved resolvers",
  "Implemented login as user in user list view",
  "Refactored login as user modal as reusable component that can be used in several views",
  "Implemented error view validation error handling",
  "Implemented modal error handling support",
  "Implemented logging in as any user from user details view",
  "Implemented admin mutation to login as any user",
  "Implemented user list enable/disable actions",
  "Implemented bulk update of user status in users list",
  "Implemented updating status of multiple users at the same time",
  "Implemented dropdown loading state",
  "Implemented activating user",
  "Implemented disabling user",
  "Implemented button group button loading state",
  "Implemented user details view delete user confirmation modal",
  "Implemented modals support",
  "Implemented admin data cache logic",
  "Implemented data table open handler logic",
  "Implemented routing for user details view",
  "Implemented admin resolver for getting user info by id",
  "Implemented admin users list filtering",
  "Implemented enum list based filtering support",
  "Implemented filtering users by registration date (after/before selected date)",
  "Implemented filter by date support",
  "Implemented users filter preserving and restoring options in URL search params",
  "Upgraded nexus js to @nexus/schema",
  "Implemented fetch mode",
  "Implement pagination input type",
  "Implemented users pagination",
  "Implemented extended AdminUser type",
  "Implemented pagination component",
  "Implemented fetching list of users",
  "Implemented data table loading state",
  "Implemented data table",
  "Implemented select field for multi selection",
  "Implemented select field for single selection",
  "Implemented radio button component",
  "Implemented checkbox component",
  "Implemented custom scrollbar",
  "Improved dropdown",
  "Created filtering design, component",
  "Created data table design, component",
  "Implemented logging out",
  "Implemented viewer menu",
  "Implemented admin main layout",
  "Implemented admin menu navigation",
  "Implemented dropdown component",
  "Implemented main menu",
  "Implemented login page",
  "Removed translations support",
  "Removed unused components",
  "Implemented base layout based on the client project",
];
