import { JSONSchema7 } from "json-schema";

export const ConsultationMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    consultationTitle: {
      description: "Title",
      type: "string",
    },
    consultationVideoImageUrl: {
      description: "The video placeholder image url",
      type: "string",
    },
    consultationVideoId: {
      description: "The vimeo video id to show",
      type: "string",
    },
    consultationId: {
      description: "Unique identifier of the consultation",
      type: "string",
    },
  },
  required: ["consultationId", "consultationTitle", "consultationVideoId", "consultationVideoImageUrl"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
