import React, { useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { Column } from "../../components/Column/Column";
import { Container } from "../../components/Container/Container";
import { Flex } from "../../components/Flex/Flex";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { P } from "../../components/Paragraph/Paragraph";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  AdminFeedItemAudienceTypeEnum,
  CollaborationTypeEnum,
  useAdminFeedTopicsQuery,
  useUpdateAdminFeedItemMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getMetadataFromFields } from "../../services/getMetadataFromFields";
import { getMetadataFormFields } from "../../services/getMetadataSchema";
import { validateNumeric } from "../../validators/validateNumeric";
import { AdminFeedItemDetailsProps } from "./AdminFeedItemDetailsView";

export const AdminFeedItemDetailsEdit: React.FC<AdminFeedItemDetailsProps> = ({ adminFeedItem, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateAdminInfo] = useUpdateAdminFeedItemMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateAdminFeedItem }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Admin feed item has been updated",
        message: `Updated admin feed item "${updateAdminFeedItem.type}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "feed-items",
          page: "items",
          id: adminFeedItem.id,
        }),
      });
    },
    onError: (error) => {
      setToast({
        type: ToastType.ERROR,
        title: "Admin feed item updated has failed",
        message: `Update admin feed item fail with message: "${error.message}".`,
        isOpen: true,
      });
    },
  });

  const { data: topicsData } = useAdminFeedTopicsQuery();

  // metadata form configuration
  const metadataFormFields = getMetadataFormFields(adminFeedItem.type, adminFeedItem.adminFeedMetadata);

  const formRef = useRef<HTMLFormElement>(null);
  const formMethods = useForm({
    defaultValues: {
      adminFeedMetadata: metadataFormFields.reduce((acc, cur) => {
        const name = cur.name.split(".")[2];

        //@ts-ignore
        acc.push({ [name]: cur.defaultValue });

        return acc;
      }, []),
    },
  });
  const { handleSubmit } = formMethods;

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "checkbox",
        name: "audience",
        label: "Audience who will see this item",
        options: getEnumFormOptions(AdminFeedItemAudienceTypeEnum),
        rules: { required: "Please choose at least one audience type" },
        defaultValue: adminFeedItem.audience,
      },
      {
        field: "radio",
        name: "isPinned",
        label: "Is pinned to the top",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether the item is pinned to the top" },
        defaultValue: adminFeedItem.isPinned ? "true" : "false",
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Is the post target for 18+ audience",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: adminFeedItem.isAdultOnly ? "true" : "false",
      },
      {
        field: "date",
        name: "startDate",
        label: "Show item from given date",
        defaultValue: new Date(adminFeedItem.startDate),
      },
      {
        field: "date",
        name: "endDate",
        label: "Show item until given date (leave empty to not use)",
        defaultValue: adminFeedItem.endDate ? new Date(adminFeedItem.endDate) : undefined,
        clearable: true,
      },
      {
        field: "text",
        type: "text",
        name: "startDay",
        label: "Show item starting from given days after registration (0 for first day, empty to not use)",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive start day number",
          }),
        },
        defaultValue: adminFeedItem.startDay ? adminFeedItem.startDay.toString() : undefined,
      },
      {
        field: "text",
        type: "text",
        name: "endDay",
        label: "Show item starting until given days after registration (leave empty to not use)",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive end day number",
          }),
        },
        defaultValue: adminFeedItem.endDay ? adminFeedItem.endDay.toString() : undefined,
      },
      {
        field: "text",
        type: "text",
        name: "likeCount",
        label: "Like count",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive like count",
          }),
        },
        defaultValue: adminFeedItem.likeCount.toString(),
      },
      {
        field: "text",
        type: "text",
        name: "shareCount",
        label: "Share count",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive share count",
          }),
        },
        defaultValue: adminFeedItem.shareCount.toString(),
      },
      // {
      //   field: "text",
      //   type: "text",
      //   name: "version",
      //   label: "Metadata version",
      //   rules: { required: "Please provide metadata version" },
      //   defaultValue: adminFeedItem.version.toString(),
      // },
      {
        field: "checkbox",
        name: "topicIds",
        label: "Admin feed item topics",
        options: topicsData
          ? topicsData.admin.adminFeedTopics.map((topic) => ({ value: topic.id, label: topic.name }))
          : [],
        defaultValue: adminFeedItem.topics.map((topic) => topic.id),
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated admin feed item info",
      },
    ],
    [
      adminFeedItem.audience,
      adminFeedItem.endDate,
      adminFeedItem.endDay,
      adminFeedItem.isPinned,
      adminFeedItem.isAdultOnly,
      adminFeedItem.likeCount,
      adminFeedItem.shareCount,
      adminFeedItem.startDate,
      adminFeedItem.startDay,
      adminFeedItem.topics,
      topicsData,
      // adminFeedItem.version,
    ],
  );

  const onSubmit = async (data: any) => {
    updateAdminInfo({
      variables: {
        ...adminFeedItem,
        ...data,
        adminFeedItemId: adminFeedItem.id,
        isPinned: data.isPinned === "true",
        isAdultOnly: data.isAdultOnly === "true",
        startDay: data.startDay ? parseInt(data.startDay, 10) : null,
        endDay: data.endDay ? parseInt(data.endDay, 10) : null,
        endDate: data.endDate ? data.endDate : null,
        likeCount: parseInt(data.likeCount, 10),
        shareCount: parseInt(data.shareCount, 10),
        version: parseInt(data.version, 10),
        topicIds: data.topicIds,
        metadata: getMetadataFromFields(data.metadata),
      },
    });
  };

  return (
    <WithCollaboration referenceId={adminFeedItem.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
      <FormProvider {...formMethods}>
        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Flex overflow row={false} expanded={false}>
            {fields.map((field) => (
              <Container overflow key={field.name} expanded>
                <FormField field={field} />
              </Container>
            ))}

            <h3>Metadata</h3>

            {metadataFormFields.length === 0 && <P>No metadata required</P>}

            {metadataFormFields.map((field) => (
              <Container overflow key={field.name} expanded>
                <FormField field={field} />
              </Container>
            ))}
          </Flex>

          <Column marginTop="half" crossAxisAlignment="flex-end">
            <BlockButton inline tertiary loading={false} type="submit">
              Update item
            </BlockButton>
          </Column>
        </Form>
      </FormProvider>
    </WithCollaboration>
  );
};
