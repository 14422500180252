import { sentenceCase } from "change-case";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminFeedItemRejectModal } from "../../modals/AdminFeedItemRejectModal/AdminFeedItemRejectModal";
import { AdminFeedItemStatusInteractionModal } from "../../modals/AdminFeedItemStatusInteractionModal/AdminFeedItemStatusInteractionModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  AdminFeedItemStatusEnum,
  UpdateAdminFeedItemStatusInteractionMutation,
  UserScopeEnum,
  useUserAdminFeedItemStatusInteractionQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminFeedItemDetailsProps } from "./AdminFeedItemDetailsView";

export const AdminFeedItemDetailsHeader: React.FC<AdminFeedItemDetailsProps> = ({ adminFeedItem, viewer, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const setToast = useSetRecoilState(toastState);
  const [showAdminFeedStatusInteractionModal, setShowAdminFeedStatusInteractionModal] = useState(false);
  const [showAdminFeedRejectModal, setShowAdminFeedRejectModal] = useState(false);

  const { data, loading, error } = useUserAdminFeedItemStatusInteractionQuery({
    variables: {
      adminFeedItemId: adminFeedItem.id,
    },
  });

  // detect edit mode
  const isEditMode = modifier === "edit";
  // check if user can review item (created by others)
  const showReviewButton =
    adminFeedItem.creatorId !== viewer.id &&
    adminFeedItem.status !== AdminFeedItemStatusEnum.APPROVED &&
    adminFeedItem.status !== AdminFeedItemStatusEnum.REJECTED;
  const approved = adminFeedItem.status === AdminFeedItemStatusEnum.APPROVED;
  const rejected = adminFeedItem.status === AdminFeedItemStatusEnum.REJECTED;

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "feed-items",
                  page: "items",
                  id: adminFeedItem.id,
                }),
              }),
          }
        : !approved && !rejected
        ? {
            label: "Edit",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN, UserScopeEnum.ADMIN_FEED_UPDATE_ITEM],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "feed-items",
                  page: "items",
                  id: adminFeedItem.id,
                  modifier: "edit",
                }),
              }),
          }
        : null,
      approved
        ? {
            label: "Reject",
            authorizedScopes: [UserScopeEnum.SUPERADMIN],
            onClick: () => {
              setShowAdminFeedRejectModal(true);
            },
          }
        : null,
      rejected
        ? {
            label: "Rejected",
            onClick: () => {
              setToast({
                type: ToastType.WARNING,
                title: "Admin feed item status rejected",
                message: `Rejected items cannot be changed`,
                isOpen: true,
              });
            },
          }
        : null,
      showReviewButton
        ? {
            label: "Review",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED_ITEM_REVIEW],
            onClick: () => {
              setShowAdminFeedStatusInteractionModal(true);
            },
          }
        : null,
    ],
    [isEditMode, approved, rejected, showReviewButton, navigate, adminFeedItem.id, setToast],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  if (loading) {
    return <LoadingView />;
  }

  // handle error
  if (error) {
    return <ErrorView title="Loading admin feed item status data failed" error={error} />;
  }

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Admin feed item "{sentenceCase(adminFeedItem.type)}"</Title>
      </Header>
      <AdminFeedItemStatusInteractionModal
        previousStatus={data?.admin.userAdminFeedItemStatusInteraction?.status}
        adminFeedItemId={adminFeedItem.id}
        open={showAdminFeedStatusInteractionModal}
        onClickOutside={() => setShowAdminFeedStatusInteractionModal(false)}
        onCompleted={(data: UpdateAdminFeedItemStatusInteractionMutation) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Status review has been submitted",
            message: `Your review status: ${data.updateAdminFeedItemStatusInteraction.status}`,
            isOpen: true,
          });

          // hide modal
          setShowAdminFeedStatusInteractionModal(false);
        }}
      />
      <AdminFeedItemRejectModal
        adminFeedItemId={adminFeedItem.id}
        open={showAdminFeedRejectModal}
        onCompleted={() => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Admin feed item has been rejected",
            message: `Admin feed item has been rejected and will not be shown to users`,
            isOpen: true,
          });

          // hide modal
          setShowAdminFeedRejectModal(false);
        }}
        onClickOutside={() => setShowAdminFeedRejectModal(false)}
      />
    </>
  );
};
