import { gql } from "@apollo/client";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Form } from "../../components/Form/Form";
import { GridBox } from "../../components/GridBox/GridBox";
import { Logo } from "../../components/Logo/Logo";
import { Panel } from "../../components/Panel/Panel";
import { TextField } from "../../components/TextField/TextField";
import { View } from "../../components/View/View";
import { useUserUpdateForgotPasswordMutation } from "../../schema";
import { getFieldErrors } from "../../services/getFieldErrors";
import { ReactComponent as PasswordIcon } from "../../theme/icons/password-icon.svg";
import { validateMinimumLength } from "../../validators/validateMinimumLength";
import styles from "./PasswordResetView.module.scss";

interface LoginFormValues {
  newPassword: string;
  passwordConfirm: string;
}

// log user in
gql`
  mutation UserUpdateForgotPassword($token: String!, $newPassword: String!) {
    userUpdateForgotPassword(token: $token, newPassword: $newPassword) {
      id
    }
  }
`;

export const ForgotResetView: React.FC = () => {
  const params = useParams();

  const { register, handleSubmit, formState, watch } = useForm<LoginFormValues>();

  // // setup login mutation
  const [resetPassword, resetPasswordResult] = useUserUpdateForgotPasswordMutation({
    awaitRefetchQueries: true,
  });

  // get combined client and server side field errors
  const fieldError = getFieldErrors(resetPasswordResult.error, formState.errors);

  const token = params.token;

  if (!token) {
    throw new Error("Token missing");
  }

  // login user on submit
  const onSubmit: SubmitHandler<LoginFormValues> = async ({ newPassword }) => {
    const response = await resetPassword({
      variables: { token, newPassword },
    });

    // redirect to stored page or next page if login is successful
    if (response.data) {
      alert("password updated");
    }

    if (response.errors) {
      alert("password reset token expired or invalid");
    }
  };

  return (
    <View center>
      <Panel pad overflow raised className={styles.panel}>
        <Logo small />
        <Form padTop="half" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="password"
            name="newPassword"
            label="New Password"
            required={true}
            leading={<PasswordIcon />}
            error={fieldError.newPassword}
            autoComplete="off"
            register={register("newPassword", { validate: validateMinimumLength(8) })}
          />
          <TextField
            type="password"
            name="passwordConfirm"
            label="Confirm Password"
            leading={<PasswordIcon />}
            autoComplete="off"
            error={fieldError.passwordConfirm}
            required={true}
            register={register("passwordConfirm", {
              validate: (value) => {
                return watch("newPassword") === value;
              },
            })}
          />
          <GridBox half />
          <BlockButton tertiary type="submit" onClick={handleSubmit(onSubmit)}>
            Update
          </BlockButton>
        </Form>
      </Panel>
    </View>
  );
};
