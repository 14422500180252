import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  LabelTypeEnum,
  LocaleEnum,
  useAdminCreateArticleMutation,
  useArticlesQuery,
  useAuthorsQuery,
  useLabelsQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";

type CreateArticleData = {
  title: string;
  description: string;
  body: string;
  background: string;
  orderWeight: string;
  locale: LocaleEnum;
  authorId: string;
  labelIds: string[];
  relatedArticleIds: string[];
  readDurationMinutes: string;
  sources: string;
  isAdultOnly: string;
  smallBackgroundAltText: string;
  focusKeyword: string;
  descriptionMetaText: string;
};

export const CreateArticleModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const [adminCreateArticle, adminCreateArticleResult] = useAdminCreateArticleMutation();
  const { data: authorsData } = useAuthorsQuery();
  const { data: labelsData } = useLabelsQuery({
    variables: {
      filter: {
        type: [LabelTypeEnum.BLOG],
      },
    },
  });
  const { data: articlesData } = useArticlesQuery();

  const createArticleFields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "authorId",
        label: "Author",
        options: authorsData
          ? authorsData.admin.authors.authors.map((author) => ({ value: author.id, label: author.name }))
          : [],
        rules: { required: "Please choose author" },
      },
      {
        field: "checkbox",
        name: "labelIds",
        label: "Labels",
        options: labelsData
          ? labelsData.admin.labels.labels.map((label) => ({ value: label.id, label: label.title }))
          : [],
        rules: { required: "Please choose at least one label" },
      },
      {
        field: "checkbox",
        name: "relatedArticleIds",
        label: "Related Articles",
        options: articlesData
          ? articlesData.admin.articles.articles.map((article) => ({ value: article.id, label: article.title }))
          : [],
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        rules: { required: "Please provide title" },
      },
      {
        field: "radio",
        name: "locale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: LocaleEnum.EN,
        rules: { required: "Please choose locale to show given content in" },
      },
      {
        field: "textarea",
        name: "description",
        label: "Description",
        rules: { required: "Please provide text" },
      },
      {
        field: "markdown",
        name: "body",
        label: "Content",
        rules: { required: "Please provide text" },
      },
      {
        field: "upload",
        type: "text",
        name: "background",
        label: "Small background image",
        rules: { required: "Please provide background image" },
      },
      {
        field: "upload",
        type: "text",
        name: "largeBackground",
        label: "Large background image",
      },
      {
        field: "text",
        type: "text",
        name: "readDurationMinutes",
        label: "Read duration (minutes)",
      },
      {
        field: "textarea",
        type: "text",
        name: "sources",
        label: "Sources (one per line)",
      },
      {
        field: "text",
        type: "text",
        name: "orderWeight",
        label: "Order weight (optional, larger shown later)",
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Adult only (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: "false",
      },
      {
        field: "text",
        type: "text",
        name: "smallBackgroundAltText",
        label: "SEO Background Alt text",
      },
      {
        field: "text",
        type: "text",
        name: "focusKeyword",
        label: "SEO Focus-Keyword Slug",
      },
      {
        field: "text",
        type: "text",
        name: "descriptionMetaText",
        label: "SEO description meta text",
      },
    ],
    [authorsData, labelsData, articlesData],
  );

  const onCreateArticleSubmit = useCallback(
    async (data: CreateArticleData) => {
      const backgroundImage = getFileUploadById("background");
      const largeBackgroundImage = getFileUploadById("largeBackground");
      const response = await adminCreateArticle({
        variables: {
          title: data.title,
          authorId: data.authorId.toString(),
          labelIds: data.labelIds,
          relatedArticleIds: data.relatedArticleIds,
          locale: data.locale,
          description: data.description,
          body: data.body,
          background: backgroundImage,
          largeBackground: largeBackgroundImage,
          readDurationMinutes: parseInt(data.readDurationMinutes),
          sources: data.sources.length > 0 ? data.sources.split("\n") : null,
          orderWeight: parseInt(data.orderWeight),
          isAdultOnly: data.isAdultOnly === "true",
          smallBackgroundAltText: data.smallBackgroundAltText,
          descriptionMetaText: data.descriptionMetaText,
          focusKeyword: data.focusKeyword,
        },
        refetchQueries: ["Articles"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "articles",
            page: "article",
            id: response.data.adminCreateArticle.id,
          }),
        });
      }
    },
    [adminCreateArticle, navigate],
  );

  return (
    <Modal error={adminCreateArticleResult.error} title="Create new blog article" {...rest}>
      <GeneratedForm
        loading={adminCreateArticleResult.loading}
        error={adminCreateArticleResult.error}
        onSubmit={onCreateArticleSubmit}
      >
        <GeneratedForm.Fields fields={createArticleFields} />
      </GeneratedForm>
    </Modal>
  );
};
