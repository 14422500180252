import { sentenceCase } from "change-case";
import { DateTime } from "luxon";
import { MdShield, MdVerifiedUser } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { Column } from "../components/Column/Column";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { P } from "../components/Paragraph/Paragraph";
import { Row } from "../components/Row/Row";
import { AdminUserInfoFragment, UserInfoFragment } from "../schema";
import styles from "../views/UserDetailsView/UserDetailsView.module.scss";
import { formatDate } from "./formatDate";
import { formatDatetime } from "./formatDatetime";
import { formatReadableObject } from "./formatReadableObject";
import { getTimezoneOffsetMinutes } from "./getTimezoneOffsetMinutes";

export function getUserNameValueList(user: AdminUserInfoFragment | UserInfoFragment): NameValueListItem[] {
  // sometimes we only have a regular user to work with that does not include all the detailed information
  if (isAdminUser(user)) {
    const timezoneOffsetMinutes = getTimezoneOffsetMinutes(user.timezone);

    return [
      {
        name: "User unique id / RevenueCat id",
        value: (
          <a href={`https://app.revenuecat.com/customers/beea2751/${user.id}`} target="_blank" rel="noreferrer">
            {user.id}
          </a>
        ),
      },
      {
        name: "User Stripe id",
        value: user.stripeCustomerId ?? "n/a",
      },
      {
        name: "Name",
        value: user.name,
      },
      {
        name: user.emailValidatedAt ? "Email (Verified)" : "Email",
        value: (
          <P className="user-role">
            {user.emailValidatedAt ? (
              <MdVerifiedUser className={styles["icon-active"]} />
            ) : (
              <MdShield className={styles["icon"]} />
            )}
            {user.email}
          </P>
        ),
      },
      {
        name: "Status",
        value: user.status ? sentenceCase(user.status) : "n/a",
      },
      {
        name: "Is Test user ?",
        value: user.isTestUser ? "yes" : "no",
      },
      {
        name: "Registration method",
        value: user.registrationSource
          ? sentenceCase(user.registrationSource)
          : sentenceCase(inferRegistrationSource(user) ?? "n/a"),
      },
      {
        name: `Subscription data (${user.hasSubscription === true ? "Subscribed" : "No subscription"})`,
        value: <pre>{user.subscriptionData ? formatReadableObject(user.subscriptionData) : "n/a"}</pre>,
      },
      {
        name: "Registered date",
        value: DateTime.fromISO(user.createdDate).toFormat("ff"),
      },
      {
        name: "User Goal",
        value: sentenceCase(user.userGoal),
      },
      {
        name: "Timezone",
        value: `${user.timezone} (${timezoneOffsetMinutes > 0 ? "+" : ""}${timezoneOffsetMinutes / 60}h)`,
      },
      {
        name: `User Device Info`,
        value: user.deviceInfo ?? "n/a",
      },
      {
        name: "Roles",
        value: (
          <>
            <P className="user-role">{user.roles.map((role) => sentenceCase(role)).join(" / ")}</P>
            <Tooltip anchorSelect=".user-role" place="bottom">
              <Column>
                {user.scopes.map((scope, index) => (
                  <Row key={index}>{sentenceCase(scope)}</Row>
                ))}
              </Column>
            </Tooltip>
          </>
        ),
      },
      {
        name: "Facebook ID",
        value: user.facebookId ?? "n/a",
      },
      {
        name: "Google ID",
        value: user.googleId ?? "n/a",
      },
      {
        name: "Apple ID",
        value: user.appleId ?? "n/a",
      },
      {
        name: "Due date",
        value: formatDate(user.dueDate),
      },
      {
        name: "User birthday date",
        value: formatDate(user.birthdayDate),
      },
      {
        name: "First quiz answers",
        value:
          user.firstQuizAnswers && user.firstQuizAnswers !== "{}" ? (
            <pre>{formatReadableObject(user.firstQuizAnswers)}</pre>
          ) : (
            "n/a"
          ),
      },
      {
        name: "Second quiz answers",
        value:
          user.secondQuizAnswers && user.secondQuizAnswers !== "{}" ? (
            <pre>{formatReadableObject(user.secondQuizAnswers)}</pre>
          ) : (
            "n/a"
          ),
      },
      {
        name: "Last period start date",
        value: formatDatetime(user.lastPeriodStartDate),
      },
      {
        name: "Last period end date",
        value: formatDatetime(user.lastPeriodEndDate),
      },
      {
        name: "Is elligible for Free trial",
        value: user.isEligibleForFreeTrial ? "yes" : "no",
      },
      {
        name: "Has shared app to friend(s)",
        value: user.hasSharedAppToFriend ? "yes" : "no",
      },
      {
        name: "Cycle length manually set value",
        value: user.cycleLength ?? "n/a",
      },
      {
        name: "Period length manually set value",
        value: user.periodLength ?? "n/a",
      },
      {
        name: "Luteal phase length manually set value",
        value: user.lutealLength ?? "n/a",
      },
      {
        name: "User wants to use fertility prediction",
        value: user.fertilityPrediction ? "Yes" : "No",
      },
      {
        name: "User wants to see cycle sequence number ",
        value: user.showCycleSequence ? "Yes" : "No",
      },
      {
        name: "User wants to see cycle moon phases",
        value: user.showMoonPhases ? "Yes" : "No",
      },
      {
        name: "User has 'Do not disturb' mode turned on",
        value: user.areNotificationsDisabled ? "Yes" : "No",
      },
      {
        name: "User's set daily water intake goal",
        value: `${(user.waterDailyIntakeGoalMilliliters / 1000).toFixed(1)} L`,
      },
      {
        name: "User's set water container volume",
        value: `${(user.waterContainerVolumeMilliliters / 1000).toFixed(1)} L`,
      },
    ];
  }

  return [
    {
      name: "User unique id",
      value: user.id,
    },
    {
      name: "Name",
      value: user.name,
    },
    {
      name: "Email",
      value: user.email,
    },
  ];
}

function inferRegistrationSource(user: AdminUserInfoFragment) {
  if (user.googleId) {
    return !user.facebookId && !user.appleId ? "GOOGLE" : null;
  } else if (user.facebookId) {
    return !user.appleId ? "FACEBOOK" : null;
  } else if (user.appleId) {
    return "APPLE";
  }
  return "EMAIL";
}

function isAdminUser(user: Record<string, unknown>): user is AdminUserInfoFragment {
  return user.status !== undefined;
}
