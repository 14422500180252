import React from "react";
import { Span } from "../Span/Span";
import styles from "./Removed.module.scss";

export interface RemovedProps {
  children: React.ReactNode;
}

export const Removed: React.FC<RemovedProps> = ({ children, ...rest }) => {
  return (
    <Span className={styles["removed"]} {...rest}>
      {children}
    </Span>
  );
};
