import { JSONSchema7 } from "json-schema";

export const QuoteMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    quoteImageUrl: {
      description: "Quote image URL",
      type: "string",
    },
    quoteAuthorName: {
      description: "Optional quote author name (null if not used / baked into the image)",
      type: ["null", "string"],
    },
    quoteText: {
      description: "Optional quote text (null if not used / baked into the image)",
      type: ["null", "string"],
    },
  },
  required: ["quoteImageUrl"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
