import { sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CategoryTypeEnum, LocaleEnum, useAdminCreateCategoryMutation, useLabelsQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { getUrlName } from "../../services/getUrlName";
import { validateFileSize } from "../../validators/validateFileSize";
import { validateNumeric } from "../../validators/validateNumeric";

type CreateCategoryData = {
  title: string;
  type: CategoryTypeEnum;
  description: string;
  urlName: string;
  largeBackgroundImage: string;
  labelIds: string[];
  orderWeight: string;
  trailerVimeoId: string;
  trailerYoutubeId: string;
  isAdultOnly: string;
};

type PartialCreateCategoryData = Omit<CreateCategoryData, "labelIds">;

export const CreateCategoryModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const [urlName, setUrlName] = useState("");

  const [adminCreateCategory, adminCreateCategoryResult] = useAdminCreateCategoryMutation();

  const { data: labelsData } = useLabelsQuery();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "type",
        label: "Type",
        options: Object.keys(CategoryTypeEnum).map((categoryType) => {
          return { value: categoryType, label: sentenceCase(categoryType) };
        }),
        rules: { required: "Please provide type" },
      },
      {
        field: "translation",
        name: "title",
        label: "Title",
        rules: { required: "Please provide title" },
        onChange: (locale, value) => {
          if (locale === LocaleEnum.EN) {
            setUrlName(getUrlName(value));
          }
        },
      },
      {
        field: "translation",
        name: "altTitle",
        label: "Alternative Title",
        onChange: (locale, value) => {
          if (locale === LocaleEnum.EN) {
            setUrlName(getUrlName(value));
          }
        },
      },
      {
        field: "translation",
        name: "description",
        label: "Description",
        rules: { required: "Please provide description" },
      },
      {
        field: "text",
        type: "text",
        name: "urlName",
        label: "Url name",
        rules: { required: "Please provide url name" },
        onChange: (e) => setUrlName(getUrlName(e.target.value)),
        defaultValue: urlName,
      },
      {
        field: "checkbox",
        name: "labelIds",
        label: "Labels",
        options: labelsData
          ? labelsData.admin.labels.labels.map((label) => ({ value: label.id, label: label.title }))
          : [],
      },
      {
        field: "text",
        type: "number",
        name: "orderWeight",
        rules: {
          required: "Please provide weight",
          validate: validateNumeric(),
        },
        label: "Weight",
      },
      {
        field: "upload",
        type: "text",
        name: "smallBackgroundImage",
        label: "Small background image",
        rules: {
          required: "Please provide small background image",
          fileValidation: validateFileSize("smallBackgroundImage"),
        },
      },
      {
        field: "upload",
        type: "text",
        name: "largeBackgroundImage",
        label: "Large background image",
        rules: {
          required: "Please provide large background image",
          fileValidation: validateFileSize("largeBackgroundImage"),
        },
      },
      {
        field: "upload",
        type: "text",
        name: "trailerCoverImage",
        label: "Trailer cover image",
        rules: {
          validate: {
            fileValidation: validateFileSize("trailerCoverImage"),
          },
        },
      },
      {
        field: "text",
        type: "text",
        name: "trailerVimeoId",
        label: "Trailer vimeo video id",
      },
      {
        field: "text",
        type: "text",
        name: "trailerYoutubeId",
        label: "Trailer youtube video id",
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Adult only (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
      },
    ],
    [labelsData, urlName],
  );

  const onCreateCategorySubmit = useCallback(
    async (data: CreateCategoryData) => {
      const smallBackgroundImage = getFileUploadById("smallBackgroundImage");
      const largeBackgroundImage = getFileUploadById("largeBackgroundImage");
      const trailerCoverImage = getFileUploadById("trailerCoverImage");

      const response = await adminCreateCategory({
        variables: {
          type: data.type,
          title: getTranslationInput("title", data as PartialCreateCategoryData),
          altTitle: getTranslationInput("altTitle", data as PartialCreateCategoryData),
          description: getTranslationInput("description", data as PartialCreateCategoryData),
          urlName: data.urlName,
          orderWeight: data.orderWeight ? parseInt(data.orderWeight) : null,
          smallBackgroundImage,
          largeBackgroundImage,
          trailerCoverImage,
          trailerVimeoId: data.trailerVimeoId,
          trailerYoutubeId: data.trailerYoutubeId,
          isAdultOnly: data.isAdultOnly === "true",
          labelIds: data.labelIds,
        },
        // update list of categories after creating new one
        refetchQueries: ["Categories"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "categories",
            page: "category",
            id: response.data.adminCreateCategory.id,
          }),
        });
      }
    },
    [adminCreateCategory, navigate],
  );

  return (
    <Modal title="Create new category" error={adminCreateCategoryResult.error} {...rest}>
      <GeneratedForm
        loading={adminCreateCategoryResult.loading}
        error={adminCreateCategoryResult.error}
        onSubmit={onCreateCategorySubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
