import classNames from "classnames";
import React, { useState } from "react";
import { FieldError } from "react-hook-form";
import { ReactComponent as ClearIcon } from "../../theme/icons/clear-icon.svg";
import { Checkbox } from "../Checkbox/Checkbox";
import { Container } from "../Container/Container";
import { DropdownMenu, DropdownItem } from "../DropdownMenu/DropdownMenu";
import { Flex, FlexProps } from "../Flex/Flex";
import { Row } from "../Row/Row";
import styles from "./SelectField.module.scss";

export interface SelectFieldOption {
  value: string;
  label: string;
}

interface BaseSelectFieldProps extends Omit<FlexProps, "defaultValue"> {
  name: string;
  label: string;
  options: SelectFieldOption[];
  error?: FieldError;
  selected?: string | string[] | null;
  multi?: boolean;
  onToggle?(option: SelectFieldOption): void;
  onSelection?(option: SelectFieldOption): void;
  onClear?(): void;
}

export interface MultiSelectFieldProps extends BaseSelectFieldProps {
  multi: true;
  selected?: string[] | null;
  required?: boolean;
}

export interface SingleSelectFieldProps extends BaseSelectFieldProps {
  multi?: false;
  selected?: string | null;
  required?: boolean;
}

export type SelectFieldProps = MultiSelectFieldProps | SingleSelectFieldProps;

// work in progress..
export const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(function SelectField(
  { label, options, error, selected, multi, className, required, onToggle, onClear, onSelection, ...rest },
  ref,
) {
  const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);

  // validate selected argument type
  if (multi && !Array.isArray(selected)) {
    throw new Error("Expecting array of selected options when 'multi' mode is used");
  } else if (!multi && Array.isArray(selected)) {
    throw new Error("Expecting single selected options when 'multi' mode is not used");
  }

  const clearButton = <ClearIcon title="Clear" className={styles["clear-icon"]} onClick={onClear} />;
  const selectedValues = selected === undefined ? [] : Array.isArray(selected) ? selected : [selected];
  const selectedOptions = options.filter((option) => selectedValues.includes(option.value));
  const hasValue = selectedOptions.length > 0;

  return (
    <Flex
      column
      overflow
      ref={ref}
      className={classNames(
        styles.field,
        {
          [styles["field--has-value"]]: hasValue,
          [styles["field--has-error"]]: error !== undefined,
        },
        className,
      )}
      {...rest}
    >
      <label className={styles.label}>
        {label} {required === true ? <span> * </span> : ""}
      </label>
      <Row>
        <Container className={styles.value} onClick={() => setIsOptionsDropdownOpen(!isOptionsDropdownOpen)}>
          {selectedOptions.map((option) => option.label).join(", ")}
        </Container>
        {onClear && clearButton}
      </Row>
      <div className={styles.border} />
      {/* TODO: support showing error */}
      <Container className={styles.error}>{error && error.message}</Container>
      <DropdownMenu
        right={1}
        top={8}
        open={isOptionsDropdownOpen}
        onClickOutside={() => setIsOptionsDropdownOpen(false)}
      >
        {options.map((option, index) => (
          <DropdownItem
            key={index}
            leading={<Checkbox radio={!multi} active={selectedValues.includes(option.value)} />}
            onClick={() => {
              // call on toggle callback if exists
              if (onToggle) {
                onToggle(option);
              }

              // can not call onToggle out of the component it will override GeneratedForm component's onToggle callback
              // so onSelection is used for outside of the component
              if (onSelection) {
                onSelection(option);
              }

              // close the dropdown if not multi selection
              if (!multi) {
                setIsOptionsDropdownOpen(false);
              }
            }}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Flex>
  );
});

// export interface OptionIndicatorProps extends FlexProps {
//   selected?: boolean;
// }

// export const OptionIndicator: React.FC<OptionIndicatorProps> = ({ selected, className, ...rest }) => (
//   <Container className={classNames(styles.wrap, className)} {...rest}>
//     SelectField {selected}
//   </Container>
// );
