import React from "react";
import { config } from "../../config";
import { useAdminLoginAsUserMutation } from "../../schema";
import { Modal, ModalProps, ModalAction } from "../Modal/Modal";
import { P } from "../Paragraph/Paragraph";

export interface LoginAsUserInfo {
  id: string;
  name: string;
}

export interface LoginAsUserModalProps extends ModalProps {
  user: LoginAsUserInfo;
  onCancel?: VoidFunction;
}

export const LoginAsUserModal: React.FC<LoginAsUserModalProps> = ({ user, onCancel, ...rest }) => {
  // login as user
  const [adminLoginAsUser, adminLoginAsUserResult] = useAdminLoginAsUserMutation();

  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Login",
      danger: true,
      onClick: async () => {
        const result = await adminLoginAsUser({
          variables: {
            userId: user.id,
          },
        });

        // redirect to main site profile view on success
        if (result.data) {
          window.location.href = `${config.mainSiteUrl}/my/profile/home`;
        }
      },
    },
  ];

  return (
    <Modal
      title="Confirm login as user"
      actions={modalActions}
      error={adminLoginAsUserResult.error}
      onClickOutside={onCancel}
      {...rest}
    >
      <P center>
        Are you sure you want to login as <strong>{user.name}</strong>?
      </P>
      <P center padTop="half">
        You will be logged out from your current account, logged in as requested user and redirected to the main app
        url.
      </P>
      <P center padTop="half">
        To be able to use the admin interface again, you must log out and login again as your main admin account.
      </P>
    </Modal>
  );
};
