import { FormFieldOptions } from "../components/GeneratedForm/GeneratedForm";

export type MetadataFormFields = Array<MetadataFormFieldOptions>;

export type MetadataFormFieldOptions = Pick<FormFieldOptions, "name" | "field">;

export const getMetadataFromFields = (data: Array<Object>) => {
  const result = {};

  data.flatMap(Object.entries).forEach((formField) => {
    const [key, value] = formField;

    if (Array.isArray(value)) {
      const valuesArray = value.flatMap(Object.entries).reduce((acc, cur) => {
        acc.push(cur[1]);

        return acc;
      }, [] as string[]);
      Object.assign(result, { [key]: valuesArray });
    } else {
      Object.assign(result, { [key]: value });
    }
  });

  return result;
};
