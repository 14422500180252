import { gql } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { Modal } from "../../components/Modal/Modal";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { P } from "../../components/Paragraph/Paragraph";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  AuthorByIdQueryResult,
  CollaborationTypeEnum,
  useAdminUpdateAuthorInfoMutation,
  useAuthorByIdQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getAuthorNameValueList } from "../../services/getAuthorNameValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query AuthorById($authorId: ID!) {
    admin {
      author(authorId: $authorId) {
        ...AuthorInfo
      }
    }
  }
`;

gql`
  mutation adminUpdateAuthorInfo(
    $authorId: ID!
    $name: String!
    $description: String!
    $title: String!
    $avatar: Upload
    $background: Upload
    $homepageUrl: String
    $facebookUrl: String
    $instagramUrl: String
    $twitterUrl: String
    $comment: String!
  ) {
    adminUpdateAuthorInfo(
      authorId: $authorId
      name: $name
      description: $description
      title: $title
      avatar: $avatar
      background: $background
      homepageUrl: $homepageUrl
      facebookUrl: $facebookUrl
      instagramUrl: $instagramUrl
      twitterUrl: $twitterUrl
      comment: $comment
    ) {
      ...AuthorInfo
    }
  }
`;

type AuthorByIdQueryAuthorInfo = NonNullable<AuthorByIdQueryResult["data"]>["admin"]["author"];

export interface AuthorDetailsViewProps {
  viewer: ViewerInfo;
}

export const AuthorDetailsView: React.FC<AuthorDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: authorData,
    loading: authorLoading,
    error: authorError,
  } = useAuthorByIdQuery({
    variables: {
      authorId: params.id ?? "",
    },
  });

  // get author info
  const author = authorData?.admin.author;

  // handle error
  if (authorError) {
    return <ErrorView title="Loading author info failed" error={authorError} />;
  }

  // handle loading
  if (authorLoading || !author) {
    return <LoadingView />;
  }

  // render author details, the view is split into subview components
  return (
    <View pad="half">
      <AuthorDetailsHeader author={author} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<AuthorDetailsInfo author={author} viewer={viewer} />} />
        <Route path="edit" element={<AuthorDetailsEdit author={author} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface AuthorProps extends FlexProps {
  author: AuthorByIdQueryAuthorInfo;
  viewer: ViewerInfo;
}

export const AuthorDetailsHeader: React.FC<AuthorProps> = ({ author, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "authors",
                  page: "author",
                  id: author.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_AUTHORS,
              UserScopeEnum.ADMIN_AUTHORS_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "authors",
                  page: "author",
                  id: author.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [author.id, isEditMode, navigate],
  );

  return (
    <>
      <Header actions={actions} {...rest}>
        <Title>Author details</Title>
      </Header>

      <Modal
        title="Confirm disable author"
        actions={[
          {
            label: "Cancel",
            onClick: () => setShowConfirmDisableModal(false),
          },
        ]}
        open={showConfirmDisableModal}
        onClickOutside={() => setShowConfirmDisableModal(false)}
      >
        <P center>
          Are you sure you want to disable author for <strong>{author.title}</strong>?
        </P>
        <P center padTop="half">
          The author can be re-activated in the future.
        </P>
      </Modal>
    </>
  );
};

export const AuthorDetailsInfo: React.FC<AuthorProps> = ({ author, viewer }) => {
  const authorDetails = getAuthorNameValueList(author);

  return (
    <WithCollaboration referenceId={author.id} type={CollaborationTypeEnum.CATEGORY} viewer={viewer}>
      <Title>Author</Title>
      <NameValueList items={authorDetails} />
    </WithCollaboration>
  );
};

type EditAuthorData = {
  name: string;
  description: string;
  title: string;
  avatarUrl: string;
  backgroundUrl: string;
  homepageUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  comment: string;
};

export const AuthorDetailsEdit: React.FC<AuthorProps> = ({ author, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateAuthorInfo, adminUpdateAuthorInfoResult] = useAdminUpdateAuthorInfoMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "name",
        label: "name",
        defaultValue: author.name,
      },
      {
        field: "markdown",
        name: "description",
        label: "Description",
        defaultValue: author.description,
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: author.title,
      },
      {
        field: "text",
        type: "text",
        name: "homepageUrl",
        label: "Homepage url",
        defaultValue: author.homepageUrl ?? "",
      },
      {
        field: "text",
        type: "text",
        name: "facebookUrl",
        label: "Facebook url",
        defaultValue: author.facebookUrl ?? "",
      },
      {
        field: "text",
        type: "text",
        name: "instagramUrl",
        label: "Instagram url",
        defaultValue: author.instagramUrl ?? "",
      },
      {
        field: "text",
        type: "text",
        name: "twitterUrl",
        label: "Twitter url",
        defaultValue: author.twitterUrl ?? "",
      },
      {
        field: "upload",
        type: "text",
        name: "avatarImage",
        label: "Avatar image (leave blank to not change)",
      },
      {
        field: "upload",
        type: "text",
        name: "backgroundImage",
        label: "Background image (leave blank to not change)",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated author info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
    ],
    [
      author.description,
      author.facebookUrl,
      author.homepageUrl,
      author.instagramUrl,
      author.name,
      author.title,
      author.twitterUrl,
    ],
  );

  const onSubmit = useCallback(
    async (data: EditAuthorData) => {
      const avatar = getFileUploadById("avatarImage");
      const background = getFileUploadById("backgroundImage");

      // update author data
      const response = await adminUpdateAuthorInfo({
        variables: {
          ...data,
          authorId: author.id,
          avatar,
          background,
        },
        refetchQueries: ["CollaborationById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "authors",
            page: "author",
            id: author.id,
          }),
        });
      }
    },
    [adminUpdateAuthorInfo, author.id, navigate],
  );

  return (
    <WithCollaboration referenceId={author.id} type={CollaborationTypeEnum.CATEGORY} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Author"
        error={adminUpdateAuthorInfoResult.error}
        loading={adminUpdateAuthorInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
