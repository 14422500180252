import { JSONSchema7 } from "json-schema";

export const PollMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    pollImageUrl: {
      type: ["null", "string"],
    },
    pollId: {
      description: "Unique identifier of the poll",
      type: "string",
    },
    pollQuestion: {
      description: "Poll question text",
      type: "string",
    },
    pollTotalCount: {
      description: "Total number of answers",
      type: "number",
    },
    answerOptions: {
      description: "List of poll options",
      type: "array",
      items: {
        $ref: "#/definitions/PollOptionMetadata",
      },
    },
  },
  required: ["answerOptions", "pollId", "pollQuestion", "pollTotalCount"],
  definitions: {
    PollOptionMetadata: {
      type: "object",
      properties: {
        adminFeedPollOptionId: {
          description: "Option unique identifier",
          type: "string",
        },
        adminFeedPollOptionAnswer: {
          description: "Text of the option",
          type: "string",
        },
        count: {
          description: "Count of how many people have chosen this option",
          type: "number",
        },
        percentage: {
          description: "Percentage of how many people have chosen this option",
          type: "number",
        },
        adminFeedPollOptionIconUrl: {
          type: ["null", "string"],
        },
        optionOrdinal: {
          type: "number",
        },
      },
      required: ["adminFeedPollOptionAnswer", "adminFeedPollOptionId", "count", "optionOrdinal", "percentage"],
    },
  },
  $schema: "http://json-schema.org/draft-07/schema#",
};
