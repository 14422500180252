import { gql } from "@apollo/client";
import { sentenceCase } from "change-case";
import { DateTime } from "luxon";
import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { MdShield, MdVerifiedUser } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { LoginAsUserInfo, LoginAsUserModal } from "../../components/LoginAsUserModal/LoginAsUserModal";
import { Pagination } from "../../components/Pagination/Pagination";
import { P } from "../../components/Paragraph/Paragraph";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { DisableUserModal } from "../../modals/DisableUserModal/DisableUserModal";
import { MarkUserAsTestUserModal } from "../../modals/MarkUserAsTestUserModal/MarkUserAsTestUserModal";
import { SendResetPasswordEmailModal } from "../../modals/SendResetPasswordEmailModal/SendResetPasswordEmailModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  UserStatusEnum,
  useUsersQuery,
  MatchModeEnum,
  ConditionModeEnum,
  useAdminUpdateUserStatusMutation,
  UserRoleEnum,
  UserScopeEnum,
  useSendPasswordResetEmailMutation,
  useAdminMarkUsersAsTestUsersMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import styles from "./UserListView.module.scss";

// fetch filtered and paginated list of admin users
gql`
  query Users($filter: AdminUsersFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      users(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        users {
          ...AdminUserInfo
        }
      }
    }
  }
`;

interface UsersFilterData extends FilterBaseData {
  userId: string;
  email: string;
  name: string;
  emailValidatedAt: boolean | null;
  status: UserStatusEnum[];
  role: UserRoleEnum[];
  registeredAfter: Date | null;
  registeredBefore: Date | null;
  hasSubscription: boolean | null;
  isTestUser: boolean | null;
}

// TODO: implement pagination "show all"
export const UserListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();
  const [confirmLoginAsUserInfo, setConfirmLoginAsUserInfo] = useState<LoginAsUserInfo>();

  // parse filter url parameters
  const params = useUrlParams<UsersFilterData>((params) => ({
    userId: params.userId ?? "",
    email: params.email ?? "",
    name: params.name ?? "",
    emailValidatedAt:
      params.emailValidatedAt !== undefined ? (params.emailValidatedAt === "true" ? true : false) : null,
    registeredAfter: params.registeredAfter ? new Date(params.registeredAfter) : null,
    registeredBefore: params.registeredBefore ? new Date(params.registeredBefore) : null,
    status: params.status ? (params.status.split(",") as UserStatusEnum[]) : [],
    role: params.role ? (params.role.split(",") as UserRoleEnum[]) : [],
    hasSubscription: params.hasSubscription !== undefined ? (params.hasSubscription === "true" ? true : false) : null,
    isTestUser: params.isTestUser !== undefined ? (params.isTestUser === "true" ? true : false) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  // load list of filtered and paginated entries
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useUsersQuery({
    variables: {
      filter: {
        userId: params.userId.length > 0 ? params.userId : undefined,
        email: params.email.length > 0 ? params.email : undefined,
        emailValidatedAt: params.emailValidatedAt !== null ? params.emailValidatedAt : undefined,
        name: params.name.length > 0 ? params.name : undefined,
        registeredAfter: params.registeredAfter ? formatApiDate(params.registeredAfter) : undefined,
        registeredBefore: params.registeredBefore ? formatApiDate(params.registeredBefore) : undefined,
        status: params.status.length > 0 ? params.status : undefined,
        role: params.role.length > 0 ? params.role : undefined,
        hasSubscription: params.hasSubscription !== null ? params.hasSubscription : undefined,
        isTestUser: params.isTestUser !== null ? params.isTestUser : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  // update user status, separated to be able to show correct loading state (mutation defined in UserDetailsView)
  const [adminUpdateUserActiveStatus, adminUpdateUserActiveStatusResult] = useAdminUpdateUserStatusMutation();
  const [adminUpdateUserDisableStatus, adminUpdateUserDisableStatusResult] = useAdminUpdateUserStatusMutation();
  const [adminMarkAsTestUsers, adminMarkAsTestUsersResult] = useAdminMarkUsersAsTestUsersMutation();
  const [sendPasswordResetEmail, sendPasswordResetEmailResult] = useSendPasswordResetEmailMutation();

  const [isDisableUserModalOpen, setIsDisableUserModalOpen] = useState(false);
  const [isMarkTestUserModalOpen, setIsMarkTestUserModalOpen] = useState(false);
  const [confirmDisableUserIds, setConfirmDisableUserIds] = useState<string | string[]>([]);
  const [sendPasswordResetUserEmail, setSendPasswordResetUserEmail] = useState<string>("");
  const [sendPasswordResetModalOpen, setSendPasswordResetModalOpen] = useState(false);

  // pagination info
  const users = useMemo(() => usersData?.admin.users.users ?? [], [usersData?.admin.users.users]);
  const total = usersData?.admin.users.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "userId",
        label: "User id",
        defaultValue: params.userId,
      },
      {
        field: "text",
        type: "text",
        name: "email",
        label: "Email",
        defaultValue: params.email,
      },
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Name",
        defaultValue: params.name,
      },
      {
        field: "date",
        name: "registeredAfter",
        label: "Registered after",
        defaultValue: params.registeredAfter,
      },
      {
        field: "date",
        name: "registeredBefore",
        label: "Registered before",
        defaultValue: params.registeredBefore,
      },
      {
        field: "checkbox",
        name: "status",
        label: "Status",
        options: Object.keys(UserStatusEnum).map((userStatus) => {
          return { value: userStatus, label: sentenceCase(userStatus) };
        }),
        defaultValue: params.status,
      },
      {
        field: "checkbox",
        name: "role",
        label: "Role",
        options: Object.keys(UserRoleEnum).map((userRole) => {
          return { value: userRole, label: sentenceCase(userRole) };
        }),
        defaultValue: params.role,
      },
      {
        field: "radio",
        name: "hasSubscription",
        label: "Has active subscription",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.hasSubscription !== null ? (params.hasSubscription ? "true" : "false") : null,
      },
      {
        field: "radio",
        name: "isTestUser",
        label: "Is test user",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.isTestUser !== null ? (params.isTestUser ? "true" : "false") : null,
      },
      {
        field: "radio",
        name: "emailValidatedAt",
        label: "Has email verified",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.emailValidatedAt !== null ? (params.emailValidatedAt ? "true" : "false") : null,
      },
    ],
    [
      params.userId,
      params.email,
      params.name,
      params.registeredAfter,
      params.registeredBefore,
      params.status,
      params.role,
      params.hasSubscription,
      params.isTestUser,
      params.emailValidatedAt,
    ],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Name",
      },
      {
        label: "Email",
      },
      {
        label: "",
      },
      {
        label: "Roles",
      },
      {
        label: "Registered date",
        center: true,
      },
      {
        label: "Status",
        center: true,
      },
      {
        label: "Subscription status",
        center: true,
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      users.map((user) => {
        // TODO: this will be used when restoring "Login as" functionality
        // const isRegularUser = user.roles.length === 1 && user.roles[0] === UserRoleEnum.USER;

        const subType = user.subscriptionData?.type;
        const subStatus = user.subscriptionData?.subscriptionStatus;

        const subscription = user.hasSubscription ? subStatus ?? (subType === "GIFT_CARD" ? "gift" : "active") : "none";

        return {
          id: user.id,
          cells: [
            {
              content: user.name,
            },

            {
              content: (
                <P className="user-role">
                  {user.emailValidatedAt ? (
                    <MdVerifiedUser className={styles["icon-active"]} />
                  ) : (
                    <MdShield className={styles["icon"]} />
                  )}
                  {user.email}
                </P>
              ),
            },
            {
              content: user.isTestUser ? <P>Tester</P> : null,
            },
            {
              content: user.roles.map((role) => sentenceCase(role)).join(", "),
            },
            {
              content: DateTime.fromISO(user.createdDate).toFormat("dd/MM/yyyy"),
              center: true,
            },
            {
              content: sentenceCase(user.status),
              center: true,
            },
            {
              content: sentenceCase(subscription),
              center: true,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_USERS, UserScopeEnum.ADMIN_USERS_INFO],
              onClick: (userId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "user", id: userId }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_USERS,
                UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
              ],
              onClick: (userId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "users",
                    page: "user",
                    id: userId,
                    modifier: "edit",
                  }),
                }),
            },
            user.status !== UserStatusEnum.DISABLED
              ? {
                  label: "Disable",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMIN_USERS,
                    UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
                    UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
                  ],
                  loading: adminUpdateUserDisableStatusResult.loading,
                  onClick: () => {
                    setConfirmDisableUserIds(user.id);
                    setIsDisableUserModalOpen(true);
                  },
                }
              : null,
            user.status !== UserStatusEnum.ACTIVE
              ? {
                  label: "Activate",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMIN_USERS,
                    UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
                    UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
                  ],
                  loading: adminUpdateUserActiveStatusResult.loading,
                  onClick: () =>
                    adminUpdateUserActiveStatus({
                      variables: {
                        userIds: [user.id],
                        status: UserStatusEnum.ACTIVE,
                      },
                    }),
                }
              : null,

            !user.isTestUser
              ? {
                  label: "Mark as Test User",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMIN_USERS,
                    UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
                    UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
                  ],
                  loading: adminMarkAsTestUsersResult.loading,
                  onClick: () => {
                    adminMarkAsTestUsers({
                      variables: {
                        userIds: [user.id],
                        isTestUser: true,
                      },
                    });
                  },
                }
              : null,
            user.status === UserStatusEnum.ACTIVE
              ? {
                  label: "Reset password",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMIN_USERS,
                    UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
                    UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
                  ],
                  loading: adminUpdateUserActiveStatusResult.loading,
                  onClick: () => {
                    setSendPasswordResetUserEmail(user.email);
                    setSendPasswordResetModalOpen(true);
                  },
                }
              : null,
            // TODO: this might make sense again once we build a web interface where the user can log in
            // only allow loggin in as regular users
            // isRegularUser
            //   ? {
            //       label: "Login as user",
            //       authorizedScopes: [
            //         UserScopeEnum.SUPERADMIN,
            //         UserScopeEnum.ADMIN_USERS,
            //         UserScopeEnum.ADMIN_USERS_LOGIN_AS_USER,
            //       ],
            //       onClick: () => setConfirmLoginAsUserInfo(user),
            //     }
            //   : // TODO: support logging in as admin?
            //     null /*{
            //       label: "Login as admin",
            //       authorizedScopes: [
            //         UserScopeEnum.SUPERADMIN,
            //         UserScopeEnum.ADMIN_USERS,
            //         UserScopeEnum.ADMIN_USERS_LOGIN_AS_ADMIN,
            //       ],
            //       onClick: () => setConfirmLoginAsUserInfo(user),
            //     }*/,
          ],
        };
      }),
    [
      adminMarkAsTestUsers,
      adminMarkAsTestUsersResult.loading,
      adminUpdateUserActiveStatus,
      adminUpdateUserActiveStatusResult.loading,
      adminUpdateUserDisableStatusResult.loading,
      navigate,
      users,
    ],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Mark as Test User(s)",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_USERS,
          UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
          UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
        ],
        loading: adminUpdateUserDisableStatusResult.loading,
        onClick: (userIds) => {
          setConfirmDisableUserIds(userIds);
          setIsMarkTestUserModalOpen(true);
        },
      },
      {
        label: "Disable",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_USERS,
          UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
          UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
        ],
        loading: adminUpdateUserDisableStatusResult.loading,
        onClick: (userIds) => {
          setConfirmDisableUserIds(userIds);
          setIsDisableUserModalOpen(true);
        },
      },
      {
        label: "Activate",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_USERS,
          UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
          UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
        ],
        loading: adminUpdateUserActiveStatusResult.loading,
        onClick: (userIds) =>
          adminUpdateUserActiveStatus({
            variables: {
              userIds,
              status: UserStatusEnum.ACTIVE,
            },
          }),
      },
    ],
    [
      adminUpdateUserDisableStatusResult.loading,
      adminUpdateUserActiveStatus,
      adminUpdateUserActiveStatusResult.loading,
      setConfirmDisableUserIds,
      setIsDisableUserModalOpen,
    ],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: UsersFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (usersError) {
    return <ErrorView title="Fetching users failed" error={usersError} />;
  }

  // render view
  return (
    <>
      <View scrollable>
        <Filter
          title="Users"
          fields={filters}
          viewerscopes={viewer.scopes}
          loading={usersLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={usersLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_USERS, UserScopeEnum.ADMIN_USERS_INFO]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "user", modifier: row.id }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />

        <DisableUserModal
          open={isDisableUserModalOpen}
          onCancel={() => setIsDisableUserModalOpen(false)}
          onClickOutside={() => setIsDisableUserModalOpen(false)}
          loading={adminUpdateUserDisableStatusResult.loading}
          error={adminUpdateUserDisableStatusResult.error}
          onSubmit={async () => {
            const response = await adminUpdateUserDisableStatus({
              variables: {
                userIds: confirmDisableUserIds,
                status: UserStatusEnum.DISABLED,
              },
            });

            if (response.data) {
              setIsDisableUserModalOpen(false);
            }
          }}
        />

        <MarkUserAsTestUserModal
          open={isMarkTestUserModalOpen}
          userCount={confirmDisableUserIds.length}
          onCancel={() => setIsMarkTestUserModalOpen(false)}
          onClickOutside={() => setIsMarkTestUserModalOpen(false)}
          loading={adminMarkAsTestUsersResult.loading}
          error={adminMarkAsTestUsersResult.error}
          onSubmit={async () => {
            const response = await adminMarkAsTestUsers({
              variables: {
                userIds: confirmDisableUserIds,
                isTestUser: true,
              },
            });

            if (response.data) {
              setIsMarkTestUserModalOpen(false);
            }
          }}
        />

        <SendResetPasswordEmailModal
          email={sendPasswordResetUserEmail}
          open={sendPasswordResetModalOpen}
          onCancel={() => setSendPasswordResetModalOpen(false)}
          onClickOutside={() => setSendPasswordResetModalOpen(false)}
          loading={sendPasswordResetEmailResult.loading}
          error={sendPasswordResetEmailResult.error}
          onSubmit={async () => {
            if (sendPasswordResetUserEmail) {
              const response = await sendPasswordResetEmail({
                variables: {
                  email: sendPasswordResetUserEmail,
                },
              });
              if (response.data) {
                setSendPasswordResetModalOpen(false);
              }
            }
          }}
        />
      </View>

      {confirmLoginAsUserInfo && (
        <LoginAsUserModal
          user={confirmLoginAsUserInfo}
          open={confirmLoginAsUserInfo !== undefined}
          onCancel={() => setConfirmLoginAsUserInfo(undefined)}
        />
      )}
    </>
  );
};
