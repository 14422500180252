import { JSONSchema7 } from "json-schema";

export const BlogMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    blogId: {
      description: "Unique identifier of the blog post",
      type: "string",
    },
  },
  required: ["blogId"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
