import { config } from "../config";

export function getWebsocketGraphqlPath() {
  // check whether we have absolute path "http://example.com/graphql" etc
  const isHttpPath = config.grahpqlPath.substr(0, 7) === "http://";
  const isHttpsPath = config.grahpqlPath.substr(0, 8) === "https://";
  const isAbsolutePath = isHttpPath || isHttpsPath;

  // rewrite "http://example.com/graphql" > "ws://example.com/graphql" (or wss:// for https://)
  if (isAbsolutePath) {
    const [, url] = config.grahpqlPath.split("://");
    const protocol = isHttpsPath ? "wss://" : "ws://";

    return `${protocol}${url}`;
  }

  // return path for relative path "/graphql" > "http://example.com/graphql" etc
  return `${config.useSsl ? "wss:" : "ws:"}//${config.host}${
    config.port !== 80 && config.port !== 443 ? `:${config.port}` : ""
  }${config.grahpqlPath}`;
}
