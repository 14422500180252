import React, { useCallback, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Container } from "../../components/Container/Container";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { AdminPaymentByIdQueryResult, useAdminRejectPaymentMutation } from "../../schema";
import { getFieldErrors } from "../../services/getFieldErrors";

export interface RejectPaymentModalProps extends ModalProps {
  onCancel?: VoidFunction;
  payment: NonNullable<AdminPaymentByIdQueryResult["data"]>["admin"]["adminPayment"];
}

export interface RejectPaymentModalData extends FieldValues {
  statusComment: string;
}

export const RejectPaymentModal: React.FC<RejectPaymentModalProps> = ({ onCancel, payment, ...rest }) => {
  const formMethods = useForm<RejectPaymentModalData>();
  const { handleSubmit, setError } = formMethods;
  const [rejectPayment, rejectPaymentResult] = useAdminRejectPaymentMutation();

  // handle form validation errors
  useEffect(() => {
    const errors = getFieldErrors<RejectPaymentModalData>(rejectPaymentResult.error);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError("statusComment", { type: "validate", message: errors[name]?.message });
        }
      });
    }
  }, [setError, rejectPaymentResult.error]);

  // handle success logic and modal closing logic
  useEffect(() => {
    if (rejectPaymentResult.data?.rejectPayment && typeof onCancel === "function") {
      onCancel();
    }
  });

  // setup form fields
  const commentField: FormFieldOptions = {
    field: "text",
    type: "text",
    name: "statusComment",
    label: "Comment (optional)",
  };

  // handle attaching payment
  const handleRejectPayment = useCallback(
    async (data: RejectPaymentModalData) => {
      await rejectPayment({
        refetchQueries: ["AdminPaymentById"],
        awaitRefetchQueries: true,
        variables: {
          paymentId: payment.id,
          statusComment: data.statusComment,
        },
      });
    },
    [payment.id, rejectPayment],
  );

  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      onClick: handleSubmit(handleRejectPayment),
      loading: rejectPaymentResult.loading,
    },
  ];

  return (
    <Modal title="Confirm rejecting payment" actions={modalActions} {...rest}>
      <P center marginBottom={5}>
        Are you sure you wish to reject this payment?
      </P>
      <FormProvider {...formMethods}>
        <Form>
          <Container overflow key="userInput" expanded>
            <FormField field={commentField} />
          </Container>
        </Form>
      </FormProvider>
    </Modal>
  );
};
