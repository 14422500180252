import { gql } from "@apollo/client";
import { paramCase, sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateCategoryModal } from "../../modals/CreateCategoryModal/CreateCategoryModal";
import { DeleteCategoryModal } from "../../modals/DeleteCategoryModal/DeleteCategoryModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  CategoryTypeEnum,
  ConditionModeEnum,
  MatchModeEnum,
  useAdminDeleteCategoryMutation,
  useCategoriesQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query Categories($filter: AdminCategoriesFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      categories(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        categories {
          ...CategoryInfo
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteCategory($categoryIds: [ID!]!) {
    adminDeleteCategory(categoryIds: $categoryIds) {
      id
    }
  }
`;

interface CategoriesFilterData extends FilterBaseData {
  categoryId: string;
  title: string;
  type: CategoryTypeEnum[];
  description: string;
  urlName: string;
}

export const CategoriesListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<CategoriesFilterData>((params) => ({
    categoryId: params.categoryId ?? "",
    title: params.title ?? "",
    type: params.type ? (params.type.split(",") as CategoryTypeEnum[]) : [],
    description: params.description ?? "",
    urlName: params.urlName ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useCategoriesQuery({
    variables: {
      filter: {
        categoryId: params.categoryId.length > 0 ? params.categoryId : undefined,
        title: params.title.length > 0 ? params.title : undefined,
        type: params.type.length > 0 ? params.type : undefined,
        description: params.description.length > 0 ? params.description : undefined,
        urlName: params.urlName.length > 0 ? params.urlName : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false);
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
  const [confirmDeleteCategoryIds, setConfirmDeleteCategoryIds] = useState<string | string[]>([]);
  const [adminDeleteCategory, adminDeleteCategoryResult] = useAdminDeleteCategoryMutation({
    refetchQueries: ["Categories"],
    awaitRefetchQueries: true,
  });

  // pagination info
  const categories = useMemo(
    () => categoriesData?.admin.categories.categories ?? [],
    [categoriesData?.admin.categories.categories],
  );

  const total = categoriesData?.admin.categories.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "categoryId",
        label: "Category id",
        defaultValue: params.categoryId,
      },
      // TODO: find a solution to default value translations
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: params.title,
      },
      {
        field: "checkbox",
        name: "type",
        label: "Type",
        options: Object.keys(CategoryTypeEnum).map((categoryType) => {
          return { value: categoryType, label: sentenceCase(categoryType) };
        }),
        defaultValue: params.type,
      },
      {
        field: "text",
        type: "text",
        name: "description",
        label: "Description",
        defaultValue: params.description,
      },
      {
        field: "text",
        type: "text",
        name: "urlName",
        label: "Url name",
        defaultValue: params.urlName,
      },
    ],
    [params.categoryId, params.description, params.title, params.type, params.urlName],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Type",
      },
      {
        label: "Category title",
      },
      {
        label: "Description",
      },
      {
        label: "Labels",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      categories.map((category) => {
        return {
          id: category.id,
          cells: [
            {
              content: sentenceCase(category.type),
            },
            {
              content: category.title,
            },
            {
              content: category.description,
            },
            {
              content: category.labels.map((label) => label.title).join(", "),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_CATEGORIES,
                UserScopeEnum.ADMIN_CATEGORIES_INFO,
              ],
              onClick: (categoryId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "categories",
                    page: "category",
                    id: categoryId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_CATEGORIES,
                UserScopeEnum.ADMIN_CATEGORIES_UPDATE_INFO,
              ],
              onClick: (categoryId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "categories",
                    page: "category",
                    id: categoryId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Delete",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_CATEGORIES,
                UserScopeEnum.ADMIN_CATEGORIES_DELETE,
              ],
              onClick: (categoryIds) => {
                setConfirmDeleteCategoryIds(categoryIds);
                setIsDeleteCategoryModalOpen(true);
              },
            },
          ],
        };
      }),
    [categories, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      // TODO: implement disabling category
      {
        label: "Delete",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_CATEGORIES,
          UserScopeEnum.ADMIN_CATEGORIES_DELETE,
        ],
        onClick: (categoryIds) => {
          setConfirmDeleteCategoryIds(categoryIds);
          setIsDeleteCategoryModalOpen(true);
        },
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Reorder",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_CATEGORIES,
          UserScopeEnum.ADMIN_CATEGORIES_CREATE,
        ],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "categories", page: "order" }),
          }),
      },
      {
        label: "Create category",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_CATEGORIES,
          UserScopeEnum.ADMIN_CATEGORIES_CREATE,
        ],
        onClick: () => setIsCreateCategoryModalOpen(true),
      },
    ],
    [navigate],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: CategoriesFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "categories" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (categoriesError) {
    return <ErrorView title="Fetching categories failed" error={categoriesError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Categories"
          fields={filters}
          actions={viewActions}
          viewerscopes={viewer.scopes}
          loading={categoriesLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={categoriesLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_CATEGORIES,
            UserScopeEnum.ADMIN_CATEGORIES_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "categories",
                page: "category",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "categories" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />

        <DeleteCategoryModal
          open={isDeleteCategoryModalOpen}
          onCancel={() => setIsDeleteCategoryModalOpen(false)}
          onClickOutside={() => setIsDeleteCategoryModalOpen(false)}
          loading={adminDeleteCategoryResult.loading}
          error={adminDeleteCategoryResult.error}
          onSubmit={async () => {
            const response = await adminDeleteCategory({
              variables: {
                categoryIds: confirmDeleteCategoryIds,
              },
            });

            if (response.data) {
              setIsDeleteCategoryModalOpen(false);
            }
          }}
        />

        <CreateCategoryModal
          open={isCreateCategoryModalOpen}
          onClickOutside={() => setIsCreateCategoryModalOpen(false)}
        />
      </View>
    </>
  );
};
