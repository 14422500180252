import React from "react";
import { ColorPicker as ReactColorPicker } from "react-color-gradient-picker";
import "react-color-gradient-picker/dist/index.css";

export interface GradientPickerPros {
  onColorChange?(value: GradientProps): void;
  gradient: GradientProps;
}

export interface GradientProps {
  points: GradientPointsProps[];
  degree: number;
  type: "linear" | "radial";
  style: string;
}

export interface GradientPointsProps {
  // percentage where color starts from percentage (0 - 100)
  left: number;
  red: number;
  green: number;
  blue: number;
  alpha: number;
}

export const GradientPicker: React.FC<GradientPickerPros> = ({ onColorChange, gradient }) => {
  return (
    <ReactColorPicker
      isGradient
      onChange={(value: GradientProps) => {
        if (onColorChange) {
          onColorChange(value);
        }
      }}
      onChangeStart={(value: GradientProps) => {
        if (onColorChange) {
          onColorChange(value);
        }
      }}
      onChangeEnd={(value: GradientProps) => {
        if (onColorChange) {
          onColorChange(value);
        }
      }}
      gradient={{
        points: gradient.points.map((point) => {
          return { left: point.left, red: point.red, green: point.green, blue: point.blue, alpha: point.alpha };
        }),
        degree: gradient.degree,
        type: gradient.type,
      }}
    />
  );
};
