import { sentenceCase } from "change-case";
import React, { useState } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Column } from "../../components/Column/Column";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { CreateAdminFeedTopicModal } from "../../modals/CreateAdminFeedTopicModal/CreateAdminFeedTopicModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum, useAdminFeedTopicsQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

export const AdminFeedTopicListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);

  // setup state
  const [showCreateAdminFeedTopicModal, setShowCreateAdminFeedTopicModal] = useState(false);

  // fetch items
  const { data, loading, error } = useAdminFeedTopicsQuery();

  // get items
  const adminFeedTopics = useMemo(() => data?.admin.adminFeedTopics ?? [], [data?.admin.adminFeedTopics]);

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Icon",
        compact: true,
      },
      {
        label: "Name",
      },
      {
        label: "Is active",
        compact: true,
        nowrap: true,
      },
      {
        label: "Is recommendation",
        compact: true,
        nowrap: true,
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      adminFeedTopics.map((adminFeedTopic) => {
        return {
          id: adminFeedTopic.id,
          cells: [
            {
              content:
                adminFeedTopic.iconUrl.length > 0 ? (
                  <img src={adminFeedTopic.iconUrl} alt="Topic icon" style={{ width: "8rem" }} />
                ) : (
                  "n/a"
                ),
            },
            {
              content: sentenceCase(adminFeedTopic.name),
            },
            {
              content: adminFeedTopic.isActive ? "Yes" : "No",
            },
            {
              content: adminFeedTopic.isRecommendation ? "Yes" : "No",
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_FEED,
                UserScopeEnum.ADMIN_FEED_TOPIC_INFO,
              ],
              onClick: (adminFeedTopicId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "feed-topics",
                    page: "topics",
                    id: adminFeedTopicId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_FEED,
                UserScopeEnum.ADMIN_FEED_UPDATE_TOPIC,
              ],
              onClick: (adminFeedTopicId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "feed-topics",
                    page: "topics",
                    id: adminFeedTopicId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, adminFeedTopics],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create topic",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_CREATE_TOPIC],
        onClick: () => setShowCreateAdminFeedTopicModal(true),
      },
    ],
    [],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching admin feed topics failed" error={error} />;
  }

  // render view
  return (
    <>
      <View scrollable>
        <Column pad="half">
          <Title actions={viewActions}>Admin feed topics</Title>
        </Column>
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          bulkActions={bulkActions}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_FEED,
            UserScopeEnum.ADMIN_FEED_TOPIC_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "feed-topics",
                page: "topics",
                modifier: row.id,
              }),
            })
          }
        />
      </View>
      <CreateAdminFeedTopicModal
        open={showCreateAdminFeedTopicModal}
        onClickOutside={() => setShowCreateAdminFeedTopicModal(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Created a new admin feed topic",
            message: `Admin feed topic "${sentenceCase(data.createAdminFeedTopic.name)}" was created`,
            isOpen: true,
          });

          // navigate to user details view
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "feed-topics",
              page: "topics",
              id: data.createAdminFeedTopic.id,
            }),
          });
        }}
      />
    </>
  );
};
