/* eslint-disable */

export type PossibleTypesResultData = {
  possibleTypes: {
    AdminFeedMetadata: [
      "BasicMetadata",
      "BlogMetadataType",
      "ConsultationMetadata",
      "CourseMetadataType",
      "EmptyMetadata",
      "ExternalLinkMetadata",
      "FactMetadata",
      "ImageCarouselMetadata",
      "IntroductionMetadata",
      "JourneyMetadata",
      "PeriodCheckupMetadata",
      "PollMetadata",
      "QuoteMetadata",
      "RecommendedMetadata",
      "ReviewMetadata",
      "VideoMetadataType",
    ];
    FeedItemUnion: ["AdminFeedItem", "UserFeedItem"];
    UserFeedMetadata: [
      "BirthdayMetadata",
      "CoursesSummaryMetadata",
      "CyclesSummaryMetadata",
      "EmptyMetadata",
      "FeelingsSummaryMetadata",
      "FirstReviewMetadata",
      "JourneysSummaryMetadata",
      "LoggedBabyInfoMetadata",
      "LoggedPeriodMetadata",
      "LoggedSymptomsMetadata",
      "MindfulMinutesMilestoneMetadata",
      "QuestionAnsweredMetadata",
      "RemindDiaryMetadata",
      "RemindReviewMetadata",
      "SleepExpertVideoMetadata",
      "SubscriptionEndingMetadata",
      "SuggestJourneyMetadata",
      "UpdatedLifeStageGoalMetadata",
    ];
    UserInterface: ["AdminUser", "User", "Viewer"];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    AdminFeedMetadata: [
      "BasicMetadata",
      "BlogMetadataType",
      "ConsultationMetadata",
      "CourseMetadataType",
      "EmptyMetadata",
      "ExternalLinkMetadata",
      "FactMetadata",
      "ImageCarouselMetadata",
      "IntroductionMetadata",
      "JourneyMetadata",
      "PeriodCheckupMetadata",
      "PollMetadata",
      "QuoteMetadata",
      "RecommendedMetadata",
      "ReviewMetadata",
      "VideoMetadataType",
    ],
    FeedItemUnion: ["AdminFeedItem", "UserFeedItem"],
    UserFeedMetadata: [
      "BirthdayMetadata",
      "CoursesSummaryMetadata",
      "CyclesSummaryMetadata",
      "EmptyMetadata",
      "FeelingsSummaryMetadata",
      "FirstReviewMetadata",
      "JourneysSummaryMetadata",
      "LoggedBabyInfoMetadata",
      "LoggedPeriodMetadata",
      "LoggedSymptomsMetadata",
      "MindfulMinutesMilestoneMetadata",
      "QuestionAnsweredMetadata",
      "RemindDiaryMetadata",
      "RemindReviewMetadata",
      "SleepExpertVideoMetadata",
      "SubscriptionEndingMetadata",
      "SuggestJourneyMetadata",
      "UpdatedLifeStageGoalMetadata",
    ],
    UserInterface: ["AdminUser", "User", "Viewer"],
  },
};
export default result;
