import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Column } from "../../components/Column/Column";
import { GridBox } from "../../components/GridBox/GridBox";
import { NameValuePair } from "../../components/NameValuePair/NameValuePair";
import { Row } from "../../components/Row/Row";
import { useAdminFeedPollResultsByIdQuery } from "../../schema";
import { getChartPalette } from "../../services/getChartPalette";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminFeedPollDetailsProps } from "./AdminFeedPollDetailsView";

export const AdminFeedPollDetailsResults: React.FC<AdminFeedPollDetailsProps> = ({ adminFeedPoll }) => {
  const { data, loading, error } = useAdminFeedPollResultsByIdQuery({
    variables: {
      adminFeedPollId: adminFeedPoll.id,
    },
  });

  const adminFeedPollResults = data?.admin.adminFeedPollResults;

  if (error) {
    return <ErrorView title="Loading poll results failed" error={error} />;
  }

  if (loading || !adminFeedPollResults) {
    return <LoadingView />;
  }

  Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const chartOptions: ChartOptions = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Poll results",
      },
    },
  };

  const chartLabels: string[] = adminFeedPollResults.answerOptions.map(
    (answerOption) => answerOption.adminFeedPollOptionAnswer,
  );

  const palette = getChartPalette(adminFeedPollResults.answerOptions.length);

  const chartData: ChartData<"bar"> = {
    labels: chartLabels,
    datasets: [
      {
        label: "Percentage",
        data: adminFeedPollResults.answerOptions.map((answerOption) => answerOption.percentage),
        backgroundColor: palette,
      },
    ],
  };

  return (
    <Row>
      <Column>
        <NameValuePair vertical name="Total answers">
          {adminFeedPollResults.pollTotalCount}
        </NameValuePair>
      </Column>
      <GridBox />
      <Column flex="1">
        <Bar options={chartOptions} data={chartData} />
      </Column>
    </Row>
  );
};
