import { ApolloError } from "@apollo/client";
import classNames from "classnames";
import React, { useRef } from "react";
import { MdClose } from "react-icons/md";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { isAuthorizationError } from "../../services/isAuthorizationErrors";
import { ErrorView } from "../../views/ErrorView/ErrorView";
import { NotAuthorizedView } from "../../views/NotAuthorizedView/NotAuthorizedView";
import { Column } from "../Column/Column";
import { Expanded } from "../Expanded/Expanded";
import { FlexProps, Flex } from "../Flex/Flex";
import { Loading } from "../Loading/Loading";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./Modal.module.scss";

export interface ModalAction {
  label: string;
  buttonKind?: keyof typeof ModalButtonKind;
  danger?: boolean;
  callToAction?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
}

export interface ModalProps extends FlexProps {
  title?: string;
  open?: boolean;
  fullscreen?: boolean;
  error?: Error | ApolloError | string;
  actions?: ModalAction[];
  onClickOutside?: VoidFunction;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  open,
  fullscreen,
  error,
  actions,
  className,
  children,
  onClickOutside,
  ...rest
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // combine server and validation errors
  const isNetworkError = typeof error === "object" && "networkError" in error && error.networkError !== null;

  useOnClickOutside(modalRef, () => {
    if (!onClickOutside) {
      return;
    }

    onClickOutside();
  });

  // handle authorization errors
  if (isAuthorizationError(error)) {
    return <NotAuthorizedView />;
  }

  return (
    <Column
      column
      center
      expanded
      className={classNames(
        styles.backdrop,
        {
          [styles["backdrop--open"]]: open,
        },
        className,
      )}
      {...rest}
    >
      <Flex
        column
        ref={modalRef}
        className={classNames(styles.modal, {
          [styles["modal--fullscreen"]]: fullscreen,
        })}
      >
        {isNetworkError ? (
          <ErrorView title="Oops! Something went wrong." error={error} />
        ) : (
          <>
            {title !== undefined && <ModalHeader title={title} onClose={onClickOutside} />}
            <ModalBody>{children}</ModalBody>
            {actions && (
              <ModalActions>
                {actions.map(({ label, buttonKind, ...rest }, index) => (
                  <ModalButton key={index} kind={buttonKind} {...rest}>
                    {label}
                  </ModalButton>
                ))}
              </ModalActions>
            )}
          </>
        )}
      </Flex>
    </Column>
  );
};

interface ModalBodyProps extends FlexProps {
  withoutPadding?: boolean;
}

const ModalBody: React.FC<ModalBodyProps> = ({ withoutPadding, className, children, ...rest }) => (
  <Column
    expanded
    className={classNames(
      styles.body,
      {
        [styles["body--without-padding"]]: withoutPadding,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </Column>
);

interface ModalActionsProps extends FlexProps {
  loading?: boolean;
}

const ModalActions: React.FC<ModalActionsProps> = ({ loading, className, children, ...rest }) => (
  <Row className={classNames(styles["modal-actions"], className)} {...rest}>
    {!loading ? (
      <>
        {React.Children.map(children, (child, index) => {
          // add a divider between every child
          return (
            <>
              {index > 0 && <div className={styles["divider"]} />}
              {child}
            </>
          );
        })}
      </>
    ) : (
      <>
        <Column expanded padVertical="half" center className={styles["loading-wrap"]}>
          <Loading />
        </Column>
      </>
    )}
  </Row>
);

export enum ModalButtonKind {
  PRIMARY = "primary",
  TRANSPARENT = "transparent",
}

interface ModalButtonProps extends FlexProps {
  kind?: keyof typeof ModalButtonKind;
  danger?: boolean;
  callToAction?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  kind = "TRANSPARENT",
  danger,
  callToAction,
  className,
  children,
  loading,
  disabled,
  ...rest
}) => (
  <Column
    center
    expanded
    textAlign="center"
    className={classNames(
      styles["modal-button"],
      styles[ModalButtonKind[kind]],
      { [styles["modal-button--disabled"]]: disabled },
      { [styles["modal-button--danger"]]: danger, [styles["modal-button--call-to-action"]]: callToAction },
      className,
    )}
    {...rest}
  >
    {loading ? <Loading /> : children}
  </Column>
);

interface ModalHeaderProps extends FlexProps {
  title: string;
  onClose?: VoidFunction;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, className, onClose, ...rest }) => (
  <Row className={classNames(styles.header, className)} crossAxisAlignment="center" {...rest}>
    <P>{title}</P>
    <Expanded />
    <MdClose className={styles["close-icon"]} title="Close" onClick={onClose} />
  </Row>
);
