import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { useAdminManualResolveGiftCardMutation, AdminManualResolveGiftCardMutation } from "../../schema";

type ManualResolveGiftCardData = {
  recipientId: string;
  markAsUsed: "true" | "false";
  comment: string;
};

export interface GiftCardProps extends ModalProps {
  giftCardId: string;
  onCompleted: (data: AdminManualResolveGiftCardMutation) => void;
}

export const ManualResolveGiftCardModal: React.FC<GiftCardProps> = ({
  giftCardId,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [adminResolveGiftCardInfo, adminResolveGiftCardInfoResult] = useAdminManualResolveGiftCardMutation({
    refetchQueries: ["AdminGiftCardById"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "markAsUsed",
        label: "Resolution outcome",
        options: [
          { value: "true", label: "Mark as Used" },
          { value: "false", label: "Mark as Refunded" },
        ],
        rules: { required: "Please choose whether gift card was used or refunded" },
        defaultValue: "true",
      },
      {
        field: "text",
        type: "text",
        name: "recipientId",
        label: "Recipient ID",
        defaultValue: "",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Reason: ",
        rules: {
          required: "Please describe why was the gift card was used or refunded",
        },
      },
    ],
    [],
  );

  const onSubmit: SubmitHandler<ManualResolveGiftCardData> = async (data) => {
    await adminResolveGiftCardInfo({
      variables: {
        giftCardId: giftCardId,
        markAsUsed: data.markAsUsed === "true",
        recipientId: data.recipientId.length > 0 ? data.recipientId : null,
        comment: data.comment,
      },
      refetchQueries: ["AdminGiftCardById"],
      awaitRefetchQueries: true,
    });
  };

  return (
    <Modal
      title="Change Gift Card Recipient"
      error={adminResolveGiftCardInfoResult.error}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <GeneratedForm
        loading={adminResolveGiftCardInfoResult.loading}
        error={adminResolveGiftCardInfoResult.error}
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
