import { sentenceCase } from "change-case";
import { Markdown } from "../components/Markdown/Markdown";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { ArticleInfoFragment } from "../schema";

export function getArticleNameValueList(article: ArticleInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique ID",
      value: article.id,
    },
    {
      name: "Title",
      value: article.title,
    },
    {
      name: "Locale",
      value: article.locale ? sentenceCase(article.locale) : "n/a",
    },
    {
      name: "Description",
      value: <Markdown>{article.description}</Markdown>,
    },
    {
      name: "Content",
      value: <Markdown>{article.body}</Markdown>,
    },
    {
      name: "Background",
      value: <img src={article.backgroundUrl} alt="Background" style={{ width: "50rem" }} />,
    },

    {
      name: "Sources",
      value:
        Array.isArray(article.metaData.sources) && article.metaData.sources.length > 0
          ? article.metaData.sources.map((source) => <>{source}</>)
          : "n/a",
    },
    {
      name: "Labels",
      value: article.labels ? article.labels.map((label) => label.title).join(", ") : "n/a",
    },
    {
      name: "Related articles",
      value:
        article.relatedArticles.length > 0
          ? article.relatedArticles.map((relatedArticle) => relatedArticle.title).join(", ")
          : "n/a",
    },
    {
      name: "Article duration(min)",
      value: article.metaData.readDurationMinutes ? article.metaData.readDurationMinutes : "n/a",
    },
    {
      name: "Order weight",
      value: article.orderWeight,
    },
    {
      name: "Age restricted (18+)",
      value: article.isAdultOnly ? "Yes" : "No",
    },
    {
      name: "SEO Background Alt text",
      value: article.metaData.smallBackgroundAltText ?? "n/a",
    },
    {
      name: "SEO Focus-Keyword Slug",
      value: article.metaData.focusKeyword ?? "n/a",
    },
    {
      name: "SEO description meta text",
      value: article.metaData.descriptionMetaText ?? "n/a",
    },
  ];
}
