import { sentenceCase } from "change-case";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { LabelTypeEnum, LocaleEnum, useAdminCreateLabelMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";

type CreateLabelData = {
  createLabelTitle: string;
  createLabelLocale: LocaleEnum;
  createLabelType: LabelTypeEnum;
  createLabelIcon: string;
  createLabelBackgroundImage: string;
  createLabelOneSignalTriggerName: string;
  createLabelOrderWeight: string;
};

export const CreateLabelModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const [adminCreateLabel, adminCreateLabelResult] = useAdminCreateLabelMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "createLabelType",
        label: "Type",
        options: Object.keys(LabelTypeEnum).map((labelType) => {
          return { value: labelType, label: sentenceCase(labelType) };
        }),
        rules: { required: "Please provide type" },
      },
      {
        field: "text",
        name: "createLabelTitle",
        label: "Title",
        rules: { required: "Please provide title" },
      },
      {
        field: "radio",
        name: "createLabelLocale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: LocaleEnum.EN,
        rules: { required: "Please choose locale to show given content in" },
      },
      {
        field: "upload",
        type: "text",
        name: "createLabelIcon",
        label: "Icon",
      },
      {
        field: "upload",
        type: "text",
        name: "createLabelBackgroundImage",
        label: "Background image",
        rules: { required: "Please provide background image" },
      },
      {
        field: "text",
        type: "text",
        name: "createLabelOneSignalTriggerName",
        label: "Onesignal trigger name (optional)",
      },
      {
        field: "text",
        type: "text",
        name: "createLabelOrderWeight",
        label: "Order weight (optional, larger shown later)",
      },
    ],
    [],
  );

  const onCreateLabelSubmit = useCallback(
    async (data: CreateLabelData) => {
      const icon = getFileUploadById("createLabelIcon");
      const backgroundImage = getFileUploadById("createLabelBackgroundImage");

      const response = await adminCreateLabel({
        variables: {
          createLabelType: data.createLabelType,
          createLabelIcon: icon,
          createLabelBackgroundImage: backgroundImage,
          createLabelOneSignalTriggerName: data.createLabelOneSignalTriggerName,
          createLabelTitle: data.createLabelTitle,
          createLabelLocale: data.createLabelLocale,
          createLabelOrderWeight: parseInt(data.createLabelOrderWeight),
        },
        refetchQueries: ["Labels"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "labels",
            page: "label",
            id: response.data.adminCreateLabel.id,
          }),
        });
      }
    },
    [adminCreateLabel, navigate],
  );

  return (
    <Modal title="Create new label" error={adminCreateLabelResult.error} {...rest}>
      <GeneratedForm
        loading={adminCreateLabelResult.loading}
        error={adminCreateLabelResult.error}
        onSubmit={onCreateLabelSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
