import { gql } from "@apollo/client";
import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";

export interface DeleteCategoryModalProps extends ModalProps {
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  loading: boolean;
}

gql`
  mutation AdminDeleteCategory($categoryIds: [ID!]!) {
    adminDeleteCategory(categoryIds: $categoryIds) {
      id
    }
  }
`;

export const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({ onSubmit, onCancel, loading, ...rest }) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      onClick: onSubmit,
      loading: loading,
    },
  ];

  return (
    <Modal title="Confirm deleting categories" actions={modalActions} {...rest}>
      <P center>Are you sure you wish to delete these categories</P>
    </Modal>
  );
};
