import { sentenceCase } from "change-case";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { LabelInfoFragment } from "../schema";

export function getLabelNameValueList(label: LabelInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: label.id,
    },
    {
      name: "Type",
      value: label.type ? sentenceCase(label.type) : "n/a",
    },
    {
      name: "Locale",
      value: label.locale ? sentenceCase(label.locale) : "n/a",
    },
    {
      name: "Icon",
      value: label.iconUrl ? <img src={label.iconUrl} alt="Icon" style={{ alignSelf: "start" }} /> : "n/a",
    },
    {
      name: "Background",
      value: <img src={label.backgroundImageUrl} alt="Background" style={{ width: "50rem" }} />,
    },
    {
      name: "Order weight",
      value: label.orderWeight,
    },
    {
      name: "Title",
      value: label.title,
    },
    {
      name: "Onesignal trigger",
      value: label.oneSignalTriggerName,
    },
    {
      name: "Age restricted (18+)",
      value: label.isAdultOnly ? "Yes" : "No",
    },
  ];
}
