import { gql } from "@apollo/client";
import { paramCase, sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateLabelModal } from "../../modals/CreateLabelModal/CreateLabelModal";
import { DeleteLabelModal } from "../../modals/DeleteLabelModal/DeleteLabelModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  MatchModeEnum,
  useLabelsQuery,
  UserScopeEnum,
  useAdminDeleteLabelMutation,
  LabelTypeEnum,
  LocaleEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query Labels($filter: AdminLabelsFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      labels(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        labels {
          ...LabelInfo
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteLabel($labelIds: [ID!]!) {
    adminDeleteLabel(labelIds: $labelIds) {
      id
    }
  }
`;

interface LabelsFilterData extends FilterBaseData {
  labelId: string;
  title: string;
  type: LabelTypeEnum[];
  locale: LocaleEnum[];
}

export const LabelsListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<LabelsFilterData>((params) => ({
    labelId: params.labelId ?? "",
    title: params.title ?? "",
    type: params.type ? (params.type.split(",") as LabelTypeEnum[]) : [],
    locale: params.locale ? (params.locale.split(",") as LocaleEnum[]) : [],
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const {
    data: labelsData,
    loading: labelsLoading,
    error: labelsError,
  } = useLabelsQuery({
    variables: {
      filter: {
        labelId: params.labelId.length > 0 ? params.labelId : undefined,
        title: params.title.length > 0 ? params.title : undefined,
        type: params.type.length > 0 ? params.type : undefined,
        locale: params.locale.length > 0 ? params.locale : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const [isCreateLabelModalOpen, setIsCreateLabelModalOpen] = useState(false);
  const [isDeleteLabelModalOpen, setIsDeleteLabelModalOpen] = useState(false);
  const [confirmDeletelabelIds, setConfirmDeletelabelIds] = useState<string | string[]>([]);
  const [adminDeleteLabel, adminDeleteLabelResult] = useAdminDeleteLabelMutation({
    refetchQueries: ["Labels"],
    awaitRefetchQueries: true,
  });

  // pagination info
  const labels = useMemo(() => labelsData?.admin.labels.labels ?? [], [labelsData?.admin.labels.labels]);

  const total = labelsData?.admin.labels.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "labelId",
        label: "Label id",
        defaultValue: params.labelId,
      },
      // TODO: find a solution to default value translations
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: params.title,
      },
      {
        field: "checkbox",
        name: "locale",
        label: "Locale",
        options: Object.keys(LocaleEnum).map((localeType) => {
          return { value: localeType, label: sentenceCase(localeType) };
        }),
        defaultValue: params.locale,
      },
      {
        field: "checkbox",
        name: "type",
        label: "Type",
        options: Object.keys(LabelTypeEnum).map((labelType) => {
          return { value: labelType, label: sentenceCase(labelType) };
        }),
        defaultValue: params.type,
      },
    ],
    [params.labelId, params.title, params.locale, params.type],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Icon",
      },
      {
        label: "Type",
      },
      {
        label: "Title",
      },
      {
        label: "Locale",
      },
      {
        label: "Order weight",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      labels.map((label) => {
        return {
          id: label.id,
          cells: [
            {
              content:
                (label.iconUrl?.length ?? 0) > 0 ? (
                  <img src={label.iconUrl!} alt="Topic icon" style={{ width: "8rem" }} />
                ) : (
                  "n/a"
                ),
            },
            {
              content: sentenceCase(label.type),
            },
            {
              content: label.title,
            },
            {
              content: label.locale,
            },
            {
              content: label.orderWeight,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LABELS, UserScopeEnum.ADMIN_LABELS_INFO],
              onClick: (labelId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "labels",
                    page: "label",
                    id: labelId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_LABELS,
                UserScopeEnum.ADMIN_LABELS_UPDATE_INFO,
              ],
              onClick: (labelId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "labels",
                    page: "label",
                    id: labelId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Delete",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_LABELS,
                UserScopeEnum.ADMIN_LABELS_DELETE,
              ],
              onClick: (labelIds) => {
                setConfirmDeletelabelIds(labelIds);
                setIsDeleteLabelModalOpen(true);
              },
            },
          ],
        };
      }),
    [labels, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LABELS, UserScopeEnum.ADMIN_LABELS_DELETE],
        onClick: (labelIds) => {
          setConfirmDeletelabelIds(labelIds);
          setIsDeleteLabelModalOpen(true);
        },
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create Label",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LABELS, UserScopeEnum.ADMIN_LABELS_CREATE],
        onClick: () => setIsCreateLabelModalOpen(true),
      },
    ],
    [],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: LabelsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "labels" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (labelsError) {
    return <ErrorView title="Fetching labels failed" error={labelsError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Labels"
          fields={filters}
          actions={viewActions}
          viewerscopes={viewer.scopes}
          loading={labelsLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={labelsLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LABELS, UserScopeEnum.ADMIN_LABELS_INFO]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "labels",
                page: "label",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "labels" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />

        <DeleteLabelModal
          open={isDeleteLabelModalOpen}
          onCancel={() => setIsDeleteLabelModalOpen(false)}
          onClickOutside={() => setIsDeleteLabelModalOpen(false)}
          loading={adminDeleteLabelResult.loading}
          error={adminDeleteLabelResult.error}
          onSubmit={async () => {
            const response = await adminDeleteLabel({
              variables: {
                labelIds: confirmDeletelabelIds,
              },
            });

            if (response.data) {
              setIsDeleteLabelModalOpen(false);
            }
          }}
        />

        <CreateLabelModal open={isCreateLabelModalOpen} onClickOutside={() => setIsCreateLabelModalOpen(false)} />
      </View>
    </>
  );
};
