import React, { useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { UploadImageMutation, useUploadImageMutation } from "../../schema";
import { getFileUploadById } from "../../services/getFileUploadById";

export interface UploadImageModalProps extends ModalProps {
  onCompleted: (data: UploadImageMutation) => void;
}

export const UploadImageModal: React.FC<UploadImageModalProps> = ({ onCompleted, ...rest }) => {
  const [uploadImage, uploadImageResult] = useUploadImageMutation({
    refetchQueries: ["Files"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "upload",
        type: "text",
        name: "image",
        label: "Image",
      },
      {
        field: "text",
        type: "text",
        name: "comment",
        label: "Comment",
      },
    ],
    [],
  );

  return (
    <Modal title="Upload image" error="" {...rest}>
      <GeneratedForm
        loading={uploadImageResult.loading}
        error={uploadImageResult.error}
        onSubmit={(data) =>
          uploadImage({
            variables: {
              image: getFileUploadById("image"),
              comment: data.comment,
            },
          })
        }
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
