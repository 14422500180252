import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminFeedTopicDetailsProps } from "./AdminFeedTopicDetailsView";

export const AdminFeedTopicDetailsHeader: React.FC<AdminFeedTopicDetailsProps> = ({ adminFeedTopic, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();

  // detect edit mode
  const isEditMode = modifier === "edit";

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "feed-topics",
                  page: "topics",
                  id: adminFeedTopic.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN, UserScopeEnum.ADMIN_FEED_UPDATE_TOPIC],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "feed-topics",
                  page: "topics",
                  id: adminFeedTopic.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, adminFeedTopic.id],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  return (
    <Header actions={actions} navigation={navigation} {...rest}>
      <Title>Admin feed topic "{adminFeedTopic.name}"</Title>
    </Header>
  );
};
