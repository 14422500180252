import { sentenceCase } from "change-case";
import { SelectFieldOption } from "../components/SelectField/SelectField";

export function getEnumFormOptions(obj: Record<string, string>): SelectFieldOption[] {
  return Object.values(obj).map((value) => ({
    value,
    label: sentenceCase(value),
  }));
}

const fromArray: (arr: string[], override?: Array<{ value: string; label: string }>) => SelectFieldOption[] = (
  arr,
  override,
) => {
  return arr.map((item) => {
    const replacer = override?.find((replaceValue) => replaceValue.value === item);
    if (replacer) {
      return { value: item, label: sentenceCase(replacer.label) };
    }
    return { value: item, label: sentenceCase(item) };
  });
};

const formOptions = {
  fromArray,
  fromEnum: getEnumFormOptions,
};

export default formOptions;
