import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, useUpdateAdminFeedTopicMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { AdminFeedTopicDetailsProps } from "./AdminFeedTopicDetailsView";

interface EditAdminFeedTopicFormData {
  name: string;
  isActive: "true" | "false";
  isRecommendation: "true" | "false";
  comment: string;
}

export const AdminFeedTopicDetailsEdit: React.FC<AdminFeedTopicDetailsProps> = ({ adminFeedTopic, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateAdminInfo, updateAdminInfoResult] = useUpdateAdminFeedTopicMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateAdminFeedTopic }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Admin feed topic has been updated",
        message: `Updated admin feed topic "${updateAdminFeedTopic.name}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "feed-topics",
          page: "topics",
          id: adminFeedTopic.id,
        }),
      });
    },
  });

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Topic name",
        rules: { required: "Please provide topic name" },
        defaultValue: adminFeedTopic.name,
      },
      {
        field: "upload",
        type: "text",
        name: "icon",
        label: "Topic icon (only choose to change, use square transparent PNG with size of 256x256 or greater)",
      },
      {
        field: "radio",
        name: "isActive",
        label: "Is active",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether created topic is initially active" },
        defaultValue: adminFeedTopic.isActive ? "true" : "false",
      },
      {
        field: "radio",
        name: "isRecommendation",
        label: "Is recommendations topic (for ads)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether created topic for recommendations (ads)" },
        defaultValue: adminFeedTopic.isRecommendation ? "true" : "false",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated admin feed topic info",
      },
    ],
    [adminFeedTopic.isActive, adminFeedTopic.isRecommendation, adminFeedTopic.name],
  );

  const onSubmit: SubmitHandler<EditAdminFeedTopicFormData> = async (data) =>
    updateAdminInfo({
      variables: {
        ...adminFeedTopic,
        ...data,
        adminFeedTopicId: adminFeedTopic.id,
        icon: getFileUploadById("icon"),
        isActive: data.isActive === "true",
        isRecommendation: data.isRecommendation === "true",
      },
    });

  return (
    <WithCollaboration referenceId={adminFeedTopic.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
      <GeneratedForm
        title="Admin feed topic"
        error={updateAdminInfoResult.error}
        loading={updateAdminInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
