import { sentenceCase } from "change-case";
import { sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { FlexProps } from "../../components/Flex/Flex";
import { Form } from "../../components/Form/Form";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { ViewerInfo } from "../../routes";
import { UserGoalEnum, useUpdateCategoryGoalOrdersMutation, useCategoriesQuery } from "../../schema";
import { LoadingView } from "../LoadingView/LoadingView";

export interface CategoryOrderProps extends FlexProps {
  viewer: ViewerInfo;
}

// edit user details form
export const CategoryOrderView: React.FC<CategoryOrderProps> = () => {
  const [userGoal, setUserGoal] = useState<UserGoalEnum>();

  const [updateCategoryOrder, updateCategoryOrderResult] = useUpdateCategoryGoalOrdersMutation();

  const { data: categoriesData, loading: categoriesLoading } = useCategoriesQuery();

  const [orderedCategories, setOrderedCategories] = useState<DataTableRow[]>([]);
  const [categories, setCategories] = useState(categoriesData?.admin.categories.categories ?? []);

  const formMethods = useForm();

  // pagination info
  useEffect(() => {
    // get sort order for user goal
    setCategories(categoriesData?.admin.categories.categories ?? []);
  }, [categoriesData?.admin.categories.categories]);

  // sort by goal and set fields
  useEffect(() => {
    const list = sortBy(
      [...categories],
      (cat) => cat.goalOrderWeights.find((weight) => weight.goal === userGoal)?.order,
    );

    setOrderedCategories(
      userGoal
        ? list.map((category, index) => {
            return {
              id: category.id,
              cells: [
                {
                  content: category.title,
                },
                {
                  content: category.description,
                },
                {
                  content: index + 1,
                },
              ],
            };
          })
        : [],
    );
  }, [categories, userGoal]);

  const categoryTrackHeaders = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Category title",
      },
      {
        label: "Description",
      },
      {
        label: "index",
      },
    ],
    [],
  );

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "reorder",
        name: `${userGoal}_order`,
        label: `Drag and Drop to reorder ${sentenceCase(userGoal ?? "")}`,
        rows: orderedCategories,
        headers: categoryTrackHeaders,
        onChange: (rows) => setOrderedCategories(rows),
      },
    ],
    [orderedCategories, setOrderedCategories, categoryTrackHeaders, userGoal],
  );

  // update category order info for user goal
  const onSubmit = useCallback(async () => {
    await updateCategoryOrder({
      variables: {
        userGoal: userGoal ?? UserGoalEnum.MONITOR_MY_PREGNANCY,
        categoryIds: orderedCategories.map((row) => row.id),
      },
      refetchQueries: ["Categories"],
      awaitRefetchQueries: true,
    });
  }, [orderedCategories, updateCategoryOrder, userGoal]);

  if (categoriesLoading) {
    return <LoadingView />;
  }

  return (
    <View pad scrollable>
      <Title>Choose User Goal</Title>
      <FormProvider {...formMethods}>
        <Form compact>
          <GeneratedForm.Fields
            fields={[
              {
                field: "radio",
                name: "userGoal",
                label: "User Goal",
                options: Object.keys(UserGoalEnum).map((goal) => {
                  return { value: goal as UserGoalEnum, label: sentenceCase(goal) };
                }),
                defaultValue: userGoal,
                rules: { required: "Please choose whether the lesson is free" },
                onChange: (option) => setUserGoal(option.value as UserGoalEnum),
              },
            ]}
          />
        </Form>
      </FormProvider>

      <GeneratedForm
        error={updateCategoryOrderResult.error}
        loading={updateCategoryOrderResult.loading || categoriesLoading}
        submitText="Update"
        compact
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </View>
  );
};
