import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useAdminCreateAuthorMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";

type CreateAuthorData = {
  createAuthorName: string;
  createAuthorDescription: string;
  createAuthorTitle: string;
  createAuthorAvatar: string;
  createAuthorBackground: string;
  createAuthorHomepageUrl: string;
  createAuthorFacebookUrl: string;
  createAuthorInstagramUrl: string;
  createAuthorTwitterUrl: string;
};

// TODO: implement creating author modal
export const CreateAuthorModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const [adminCreateAuthor, adminCreateAuthorResult] = useAdminCreateAuthorMutation();

  const createAuthorFields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "createAuthorName",
        label: "Author name",
        rules: { required: "Please provide author name" },
      },
      {
        field: "text",
        type: "text",
        name: "createAuthorTitle",
        label: "Author title",
        rules: { required: "Please provide author title" },
      },
      {
        field: "markdown",
        name: "createAuthorDescription",
        label: "Author description",
        rules: { required: "Please provide author description" },
      },
      {
        field: "upload",
        type: "text",
        name: "createAuthorAvatar",
        label: "Author avatar",
        rules: { required: "Please provide author avatar" },
      },
      {
        field: "upload",
        type: "text",
        name: "createAuthorBackground",
        label: "Author backgorund",
        rules: { required: "Please provide author background image" },
      },
      {
        field: "text",
        type: "text",
        name: "createAuthorHomepageUrl",
        label: "Author homepage (optional)",
        defaultValue: "",
      },
      {
        field: "text",
        type: "text",
        name: "createAuthorFacebookUrl",
        label: "Author facebook page (optional)",
        defaultValue: "",
      },
      {
        field: "text",
        type: "text",
        name: "createAuthorInstagramUrl",
        label: "Author instagram page (optional)",
        defaultValue: "",
      },
      {
        field: "text",
        type: "text",
        name: "createAuthorTwitterUrl",
        label: "Author twitter page (optional)",
        defaultValue: "",
      },
    ],
    [],
  );

  const onCreateAuthorSubmit = useCallback(
    async (data: CreateAuthorData) => {
      const avatar = getFileUploadById("createAuthorAvatar");
      const background = getFileUploadById("createAuthorBackground");

      // create author
      const response = await adminCreateAuthor({
        variables: {
          createAuthorName: data.createAuthorName,
          createAuthorDescription: data.createAuthorDescription,
          createAuthorTitle: data.createAuthorTitle,
          createAuthorAvatar: avatar,
          createAuthorBackground: background,
          createAuthorHomepageUrl: data.createAuthorHomepageUrl,
          createAuthorFacebookUrl: data.createAuthorFacebookUrl,
          createAuthorInstagramUrl: data.createAuthorInstagramUrl,
          createAuthorTwitterUrl: data.createAuthorTwitterUrl,
        },
        // update list of authors after creating new one
        refetchQueries: ["Authors"],
        awaitRefetchQueries: true,
      });

      // redirect to details (non-edit mode) when successfully created
      if (response.data) {
        navigate(
          buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "authors",
            page: "author",
            id: response.data.adminCreateAuthor.id,
          }),
        );
      }
    },
    [adminCreateAuthor, navigate],
  );

  return (
    <Modal error={adminCreateAuthorResult.error} title="Create new author" {...rest}>
      <GeneratedForm
        loading={adminCreateAuthorResult.loading}
        error={adminCreateAuthorResult.error}
        onSubmit={onCreateAuthorSubmit}
      >
        <GeneratedForm.Fields fields={createAuthorFields} />
      </GeneratedForm>
    </Modal>
  );
};
