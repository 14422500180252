import React, { useCallback, useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { useAdminSendUserPushNotificationMutation, UserByIdQuery } from "../../schema";

export type UserDetailsInfo = NonNullable<UserByIdQuery["admin"]["user"]>;

export interface SendUserPushNotificationModalProps extends ModalProps {
  user: UserDetailsInfo;
}

interface SendUserPushNotificationData {
  title: string;
  body: string;
}

export const SendUserPushNotificationModal: React.FC<SendUserPushNotificationModalProps> = ({ user, ...rest }) => {
  // login as user
  const [adminSendUserPushNotification, adminSendUserPushNotificationResult] =
    useAdminSendUserPushNotificationMutation();

  // send push notification fields
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: "",
        rules: { required: "Please provide notification title" },
      },
      {
        field: "text",
        type: "text",
        name: "body",
        label: "Message body",
        defaultValue: "",
        rules: { required: "Please provide notification body" },
      },
    ],
    [],
  );

  // closes modal using onClickOutside if provided
  const { onClickOutside } = rest;
  const closeModal = useCallback(() => (onClickOutside ? onClickOutside() : undefined), [onClickOutside]);

  // handles form submit
  const onSendUserPushNotificationSubmit = useCallback(
    async (data: SendUserPushNotificationData) => {
      const response = await adminSendUserPushNotification({
        variables: {
          userId: user.id,
          title: data.title,
          body: data.body,
        },
      });

      if (response.data) {
        closeModal();
      }
    },
    [adminSendUserPushNotification, closeModal, user.id],
  );

  // show message if user has no associated devices to send notifications to
  if (user.firebaseMessagingTokens.length === 0) {
    return (
      <Modal
        title="Send user push notification"
        actions={[
          {
            label: "OK",
            onClick: closeModal,
          },
        ]}
        {...rest}
      >
        <P center>
          Unfortunately <strong>{user.name}</strong> does not have any associated devices.
        </P>
      </Modal>
    );
  }

  return (
    <Modal title="Send push notification" error={adminSendUserPushNotificationResult.error} {...rest}>
      <P center>
        You are sending a push notification to <strong>{user.name}</strong>.
      </P>
      <P center small padTop="half">
        User has{" "}
        {user.firebaseMessagingTokens.length === 1
          ? "one associated device (may not be active)"
          : `${user.firebaseMessagingTokens.length} associated devices (may not all be current / active)`}
        .
      </P>

      <GeneratedForm
        marginTop
        loading={adminSendUserPushNotificationResult.loading}
        error={adminSendUserPushNotificationResult.error}
        submitText="Send"
        onSubmit={onSendUserPushNotificationSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
