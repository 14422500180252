import React, { useCallback, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Container } from "../../components/Container/Container";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import {
  AdminPaymentByIdQueryResult,
  useAdminAttachPaymentMutation,
  useAdminUserByIdOrEmailLazyQuery,
} from "../../schema";
import { getFieldErrors } from "../../services/getFieldErrors";

export interface AttachPaymentModalProps extends ModalProps {
  payment: NonNullable<AdminPaymentByIdQueryResult["data"]>["admin"]["adminPayment"];
  onCancel?: VoidFunction;
}

export interface AttachPaymentModalData extends FieldValues {
  user: string;
}

export const AttachPaymentModal: React.FC<AttachPaymentModalProps> = ({ payment, ...rest }) => {
  const [isAttachEnabled, setIsAttachEnabled] = useState(false);
  const [isAttachmentSuccessful, setIsAttachmentSuccessful] = useState(false);
  const formMethods = useForm<AttachPaymentModalData>();
  const { handleSubmit, setError } = formMethods;
  const [adminUserByIdOrEmail, { data: userData, error: userError, loading: isAdminUserLoading }] =
    useAdminUserByIdOrEmailLazyQuery();
  const [attachPayment, attachPaymentResult] = useAdminAttachPaymentMutation();

  // handle form validation errors
  useEffect(() => {
    const errors = getFieldErrors<AttachPaymentModalData>(userError);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError("user", { type: "validate", message: errors[name]?.message });
        }
      });
    }
  }, [userError, setError]);

  // handle attach button logic
  useEffect(() => {
    if (userData === undefined || userData.admin.user.hasSubscription) {
      setIsAttachEnabled(false);

      return;
    }

    setIsAttachEnabled(true);
  }, [userData]);

  // handle modal success logic
  useEffect(() => {
    // handle attach payment error
    if (attachPaymentResult.error) {
      setError("user", { type: "validate", message: attachPaymentResult.error.message });
    }

    // handle attaching subscription failed
    if (!attachPaymentResult.data?.attachPayment) {
      setIsAttachmentSuccessful(false);

      return;
    }

    setIsAttachmentSuccessful(true);
  }, [attachPaymentResult, attachPaymentResult.data?.attachPayment, attachPaymentResult.error, setError]);

  // setup form fields
  const searchUserField: FormFieldOptions = {
    field: "text",
    type: "text",
    name: "user",
    label: "Enter user email or id",
    rules: {
      required: "User email or id is required",
    },
  };

  // handle finding user
  const onSubmit = useCallback(
    async (data) => {
      await adminUserByIdOrEmail({
        fetchPolicy: "no-cache",
        variables: {
          userId: data.user,
          email: data.user,
        },
      });
    },
    [adminUserByIdOrEmail],
  );

  // handle attaching payment
  const handleAttachPayment = useCallback(async () => {
    const user = userData?.admin.user;

    if (!user || !isAttachEnabled) {
      return;
    }

    await attachPayment({
      refetchQueries: ["AdminPaymentById", "AdminUserByIdOrEmail"],
      awaitRefetchQueries: true,
      variables: {
        paymentSessionId: payment.paymentSessionId,
        userId: user.id,
      },
    });
  }, [attachPayment, isAttachEnabled, payment.paymentSessionId, userData?.admin.user]);

  // setup model actions
  const modalActions: ModalAction[] | undefined = !isAttachmentSuccessful
    ? [
        {
          label: "Find user",
          loading: isAdminUserLoading,
          onClick: handleSubmit(onSubmit),
        },
        {
          label: "Attach",
          disabled: !isAttachEnabled,
          buttonKind: "PRIMARY",
          loading: attachPaymentResult.loading,
          onClick: handleAttachPayment,
        },
      ]
    : undefined;

  // get find user result
  const getUserDescription = () => {
    const user = userData?.admin.user;

    return user ? (
      <>
        <small>Id: {user.id}</small>
        <small>Email: {user.email}</small>
        <small>Has subscription: {user.hasSubscription ? "Yes" : "No"}</small>
      </>
    ) : (
      ""
    );
  };

  return (
    <Modal title="Attach Beginning user" style={{ overflowY: "auto" }} actions={modalActions} {...rest}>
      {!isAttachmentSuccessful ? (
        <FormProvider {...formMethods}>
          <Form>
            <Container overflow key="userInput" expanded>
              <FormField field={searchUserField} />
              {getUserDescription()}
            </Container>
          </Form>
        </FormProvider>
      ) : (
        <p>Payment successfully attached</p>
      )}
    </Modal>
  );
};
