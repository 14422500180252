import { gql } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { Modal } from "../../components/Modal/Modal";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { P } from "../../components/Paragraph/Paragraph";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DeleteArticleModal } from "../../modals/DeleteArticleModal/DeleteArticleModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  CollaborationTypeEnum,
  ArticleByIdQueryResult,
  LocaleEnum,
  useAdminDeleteArticleMutation,
  useAdminUpdateArticleInfoMutation,
  useAuthorsQuery,
  useArticleByIdQuery,
  useLabelsQuery,
  UserScopeEnum,
  LabelTypeEnum,
  useArticlesQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getArticleNameValueList } from "../../services/getArticleNameValueList";
import { getAuthorNameValueList } from "../../services/getAuthorNameValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query ArticleById($articleId: ID!) {
    admin {
      article(articleId: $articleId) {
        ...ArticleInfo
        relatedArticles {
          id
        }
        author {
          ...AuthorInfo
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteArticle($articleIds: [ID!]!) {
    adminDeleteArticle(articleIds: $articleIds) {
      id
    }
  }
`;

gql`
  mutation adminUpdateArticleInfo(
    $authorId: ID!
    $articleId: ID!
    $title: String!
    $locale: LocaleEnum!
    $description: String!
    $body: String!
    $background: Upload
    $largeBackground: Upload
    $orderWeight: Int!
    $readDurationMinutes: Int
    $sources: [String!]
    $comment: String!
    $labelIds: [ID!]!
    $relatedArticleIds: [ID!]!
    $isAdultOnly: Boolean!
    $smallBackgroundAltText: String
    $focusKeyword: String
    $descriptionMetaText: String
  ) {
    adminUpdateArticleInfo(
      authorId: $authorId
      articleId: $articleId
      title: $title
      locale: $locale
      description: $description
      body: $body
      background: $background
      largeBackground: $largeBackground
      orderWeight: $orderWeight
      readDurationMinutes: $readDurationMinutes
      sources: $sources
      comment: $comment
      labelIds: $labelIds
      relatedArticleIds: $relatedArticleIds
      isAdultOnly: $isAdultOnly
      smallBackgroundAltText: $smallBackgroundAltText
      focusKeyword: $focusKeyword
      descriptionMetaText: $descriptionMetaText
    ) {
      ...ArticleInfo
    }
  }
`;

type ArticleByIdQueryArticleInfo = NonNullable<ArticleByIdQueryResult["data"]>["admin"]["article"];

export interface ArticleDetailsViewProps {
  viewer: ViewerInfo;
}

export const ArticleDetailsView: React.FC<ArticleDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: articleData,
    loading: articleLoading,
    error: articleError,
  } = useArticleByIdQuery({
    variables: {
      articleId: params.id ?? "",
    },
  });

  const article = articleData?.admin.article;

  if (articleError) {
    return <ErrorView title="Loading article info failed" error={articleError} />;
  }

  if (articleLoading || !article) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <ArticleDetailsHeader article={article} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<ArticleDetailsInfo article={article} viewer={viewer} />} />
        <Route path="edit" element={<ArticleDetailsEdit article={article} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface ArticleProps extends FlexProps {
  article: ArticleByIdQueryArticleInfo;
  viewer: ViewerInfo;
}

export const ArticleDetailsHeader: React.FC<ArticleProps> = ({ article, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  const [isDeleteArticleModalOpen, setIsDeleteArticleModalOpen] = useState(false);
  const [adminDeleteArticle, adminDeleteArticleResult] = useAdminDeleteArticleMutation();
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_ARTICLES, UserScopeEnum.ADMIN_ARTICLES_DELETE],
        onClick: () => {
          setIsDeleteArticleModalOpen(true);
        },
      },
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "articles",
                  page: "article",
                  id: article.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_ARTICLES,
              UserScopeEnum.ADMIN_ARTICLES_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "articles",
                  page: "article",
                  id: article.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [article.id, isEditMode, navigate],
  );

  const { author } = article;

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      {
        label: "Author",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_AUTHORS, UserScopeEnum.ADMIN_AUTHORS_INFO],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "authors",
              page: "author",
              modifier: author.id,
            }),
          }),
      },
    ],
    [author.id, navigate],
  );

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Article details</Title>
      </Header>

      <DeleteArticleModal
        open={isDeleteArticleModalOpen}
        onCancel={() => setIsDeleteArticleModalOpen(false)}
        onClickOutside={() => setIsDeleteArticleModalOpen(false)}
        loading={adminDeleteArticleResult.loading}
        error={adminDeleteArticleResult.error}
        onSubmit={async () => {
          const response = await adminDeleteArticle({
            variables: {
              articleIds: article.id,
            },
            refetchQueries: ["Articles"],
            awaitRefetchQueries: true,
          });

          if (response.data) {
            setIsDeleteArticleModalOpen(false);

            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "articles",
              }),
            });
          }
        }}
      />

      <Modal
        title="Confirm disable article"
        actions={[
          {
            label: "Cancel",
            onClick: () => setShowConfirmDisableModal(false),
          },
        ]}
        open={showConfirmDisableModal}
        onClickOutside={() => setShowConfirmDisableModal(false)}
      >
        <P center>
          Are you sure you want to disable article for <strong>{article.title}</strong>?
        </P>
        <P center padTop="half">
          The article can be re-activated in the future.
        </P>
      </Modal>
    </>
  );
};

export const ArticleDetailsInfo: React.FC<ArticleProps> = ({ article, viewer }) => {
  const navigate = useNavigate();
  const { author } = article;

  const articleDetails = getArticleNameValueList(article);

  return (
    <WithCollaboration referenceId={article.id} type={CollaborationTypeEnum.ARTICLE} viewer={viewer}>
      <Title>Article</Title>
      <NameValueList items={articleDetails} />

      <Title
        marginTop
        actions={[
          {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "authors",
                  page: "author",
                  id: author.id,
                }),
              }),
          },
        ]}
      >
        Author
      </Title>
      <NameValueList items={getAuthorNameValueList(author)} />
    </WithCollaboration>
  );
};

type EditArticleData = {
  title: string;
  description: string;
  body: string;
  background: string;
  locale: LocaleEnum;
  authorId: string;
  orderWeight: string;
  readDurationMinutes: string;
  sources: string;
  comment: string;
  labelIds: string[];
  relatedArticleIds: string[];
  isAdultOnly: string;
};

export const ArticleDetailsEdit: React.FC<ArticleProps> = ({ article, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateArticleInfo, adminUpdateArticleInfoResult] = useAdminUpdateArticleInfoMutation();
  const { data: authorsData } = useAuthorsQuery();
  const { data: labelsData } = useLabelsQuery({
    variables: {
      filter: {
        type: [LabelTypeEnum.BLOG],
      },
    },
  });

  const { data: articlesData } = useArticlesQuery();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "authorId",
        label: "Author name",
        options: authorsData
          ? authorsData.admin.authors.authors.map((author) => ({ value: author.id, label: author.name }))
          : [],
        defaultValue: article.author.id,
      },
      {
        field: "checkbox",
        name: "labelIds",
        label: "Labels",
        options: labelsData
          ? labelsData.admin.labels.labels.map((label) => ({ value: label.id, label: label.title }))
          : [],
        defaultValue: article.labels.map((label) => label.id),
      },
      {
        field: "checkbox",
        name: "relatedArticleIds",
        label: "Related articles",
        options: articlesData
          ? articlesData.admin.articles.articles
              .filter((relatedArticle) => relatedArticle.id !== article.id)
              .map((relatedArticle) => ({ value: relatedArticle.id, label: relatedArticle.title }))
          : [],
        defaultValue: article.relatedArticles.map((relatedArticle) => relatedArticle.id),
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: article.title,
      },
      {
        field: "radio",
        name: "locale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: article.locale,
      },
      {
        field: "textarea",
        name: "description",
        label: "Description",
        rules: { required: "Please provide text" },
        defaultValue: article.description,
      },
      {
        field: "markdown",
        name: "body",
        label: "Content",
        rules: { required: "Please provide text" },
        defaultValue: article.body,
      },
      {
        field: "upload",
        type: "text",
        name: "backgroundImage",
        label: "Background image (leave blank to not change)",
      },
      {
        field: "upload",
        type: "text",
        name: "largeBackgroundImage",
        label: "Large background image (leave blank to not change)",
      },
      {
        field: "text",
        type: "text",
        name: "readDurationMinutes",
        label: "Read duration (minutes)",
        defaultValue: article.metaData.readDurationMinutes?.toString(),
      },
      {
        field: "textarea",
        type: "text",
        name: "sources",
        label: "Sources (one per line)",
        defaultValue:
          article.metaData.sources && article.metaData.sources.length > 0 ? article.metaData.sources?.join("\n") : "",
      },
      {
        field: "text",
        type: "text",
        name: "orderWeight",
        label: "Order weight (optional, larger shown later)",
        defaultValue: article.orderWeight.toString(),
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Age restriction (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: article.isAdultOnly ? "true" : "false",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated article info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
      {
        field: "text",
        type: "text",
        name: "smallBackgroundAltText",
        label: "SEO Background Alt text",
        defaultValue: article.metaData.smallBackgroundAltText ?? undefined,
      },
      {
        field: "text",
        type: "text",
        name: "focusKeyword",
        label: "SEO Focus-Keyowrd Slug",
        defaultValue: article.metaData.focusKeyword ?? undefined,
      },
      {
        field: "text",
        type: "text",
        name: "descriptionMetaText",
        label: "SEO description meta text",
        defaultValue: article.metaData.descriptionMetaText ?? undefined,
      },
    ],
    [
      authorsData,
      articlesData,
      article.id,
      article.author.id,
      article.description,
      article.body,
      article.labels,
      article.relatedArticles,
      article.locale,
      article.orderWeight,
      article.title,
      article.metaData.sources,
      article.metaData.readDurationMinutes,
      article.metaData.smallBackgroundAltText,
      article.metaData.focusKeyword,
      article.metaData.descriptionMetaText,
      article.isAdultOnly,
      labelsData,
    ],
  );

  const onSubmit = useCallback(
    async (data: EditArticleData) => {
      const background = getFileUploadById("backgroundImage");
      const largeBackground = getFileUploadById("largeBackground");

      const response = await adminUpdateArticleInfo({
        variables: {
          ...data,
          readDurationMinutes: parseInt(data.readDurationMinutes),
          sources: data.sources.length > 0 ? data.sources.split("\n") : null,
          authorId: data.authorId.toString(),
          articleId: article.id,
          orderWeight: parseInt(data.orderWeight),
          background,
          largeBackground,
          labelIds: data.labelIds,
          isAdultOnly: data.isAdultOnly === "true",
        },
        refetchQueries: ["CollaborationById", "ArticleById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "articles",
            page: "article",
            id: article.id,
          }),
        });
      }
    },
    [adminUpdateArticleInfo, article.id, navigate],
  );

  return (
    <WithCollaboration referenceId={article.id} type={CollaborationTypeEnum.ARTICLE} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Article"
        error={adminUpdateArticleInfoResult.error}
        loading={adminUpdateArticleInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
