import { JSONSchema7 } from "json-schema";
import { FormFieldOptions } from "../components/GeneratedForm/GeneratedForm";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { BasicMetadataSchema } from "../generated/BasicMetadataSchema";
import { BlogMetadataSchema } from "../generated/BlogMetadataSchema";
import { ConsultationMetadataSchema } from "../generated/ConsultationMetadataSchema";
import { CourseMetadataSchema } from "../generated/CourseMetadataSchema";
import { EmptyMetadataSchema } from "../generated/EmptyMetadataSchema";
import { ExternalLinkMetadataSchema } from "../generated/ExternalLinkMetadataSchema";
import { FactMetadataSchema } from "../generated/FactMetadataSchema";
import { ImageCarouselMetadataSchema } from "../generated/ImageCarouselMetadataSchema";
import { IntroductionMetadataSchema } from "../generated/IntroductionMetadataSchema";
import { JourneyMetadataSchema } from "../generated/JourneyMetadataSchema";
import { PeriodCheckupMetadataSchema } from "../generated/PeriodCheckupMetadataSchema";
import { PollMetadataSchema } from "../generated/PollMetadataSchema";
import { QuoteMetadataSchema } from "../generated/QuoteMetadataSchema";
import { RecommendedMetadataSchema } from "../generated/RecommendedMetadataSchema";
import { ReviewMetadataSchema } from "../generated/ReviewMetadataSchema";
import { VideoMetadataSchema } from "../generated/VideoMetadataSchema";
import { AdminFeedItemTypeEnum } from "../schema";
import { validateNumeric } from "../validators/validateNumeric";

export const metadataSchemaMap: Record<AdminFeedItemTypeEnum, JSONSchema7> = {
  BASIC: BasicMetadataSchema,
  BLOG: BlogMetadataSchema,
  CONSULTATION: ConsultationMetadataSchema,
  COURSE: CourseMetadataSchema,
  JOURNEY: JourneyMetadataSchema,
  EXPLAIN_MORE: EmptyMetadataSchema,
  EXTERNAL_LINK: ExternalLinkMetadataSchema,
  FACT: FactMetadataSchema,
  IMAGE_CAROUSEL: ImageCarouselMetadataSchema,
  INTRODUCTION: IntroductionMetadataSchema,
  PERIOD_CHECKUP: PeriodCheckupMetadataSchema,
  POLL: PollMetadataSchema,
  QUOTE: QuoteMetadataSchema,
  REVIEW: ReviewMetadataSchema,
  SALE: EmptyMetadataSchema,
  SUBSCRIBE_NEWSLETTER: EmptyMetadataSchema,
  SURVEY: EmptyMetadataSchema,
  VIDEO: VideoMetadataSchema,
  RECOMMENDED: RecommendedMetadataSchema,
};

export const getMetadataNameValuePairs = (
  type: AdminFeedItemTypeEnum,
  defaultValues: Record<string, unknown> = {},
): NameValueListItem[] => {
  const schema = metadataSchemaMap[type];

  if (!schema || !schema.properties) {
    return [];
  }

  return Object.entries(schema.properties).map(([propertyName, property]) => {
    if (typeof property === "boolean") {
      return null;
    }

    return {
      name: property.description,
      value: defaultValues[propertyName] ?? "",
    };
  }) as NameValueListItem[];
};

export const getMetadataFormFields = (
  type: AdminFeedItemTypeEnum,
  defaultValues: Record<string, unknown> = {},
): FormFieldOptions[] => {
  const schema = metadataSchemaMap[type];

  if (!schema || !schema.properties) {
    return [];
  }

  return Object.entries(schema.properties).map(([propertyName, property], index) => {
    if (typeof property === "boolean") {
      return null;
    }

    const name = `metadata.${index}.${propertyName}`;

    if (property.type === "number") {
      return {
        field: "text",
        type: "text",
        name,
        label: property.description ?? propertyName,
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: schema.required?.includes(propertyName),
          }),
        },
        defaultValue: defaultValues[propertyName] ?? "0",
      };
    }

    // handle array
    if (property.type === "array") {
      //@ts-ignore
      const values = defaultValues[propertyName] ?? [];

      //@ts-ignore
      const fields = values.map((value) => ({ value }));

      return {
        field: "array",
        type: "text",
        name,
        label: property.description ?? propertyName,
        rules: schema.required?.includes(propertyName) ? { required: "Field must not be empty" } : undefined,
        defaultValue: fields,
      };
    }

    return {
      field: "text",
      type: "text",
      name,
      label: property.description ?? propertyName,
      defaultValue: defaultValues[propertyName] ?? "",
      rules: schema.required?.includes(propertyName) ? { required: "Field must not be empty" } : undefined,
    };
  }) as FormFieldOptions[];
};
