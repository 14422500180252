import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { LabelTypeEnum, LocaleEnum, useAdminCreateCourseMutation, useAuthorsQuery, useLabelsQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";

type CreateCourseData = {
  createCourseTitle: string;
  createCourseDescription: string;
  createCourseBackground: string;
  // createCourseLargeBackground: string;
  createCourseTrailerVimeoId: string;
  createCourseOrderWeight: string;
  createCourseLocale: LocaleEnum;
  createCourseAuthorId: string;
  createCourseLabelIds: string[];
  createCourseIsAdultOnly: string;
};

export const CreateCourseModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const [adminCreateCourse, adminCreateCourseResult] = useAdminCreateCourseMutation();
  const { data: authorsData } = useAuthorsQuery();
  const { data: labelsData } = useLabelsQuery({
    variables: {
      filter: {
        type: [LabelTypeEnum.COURSE],
      },
    },
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "createCourseAuthorId",
        label: "Author",
        options: authorsData
          ? authorsData.admin.authors.authors.map((author) => ({ value: author.id, label: author.name }))
          : [],
        rules: { required: "Please provide author" },
      },
      {
        field: "checkbox",
        name: "createCourseLabelIds",
        label: "Labels",
        options: labelsData
          ? labelsData.admin.labels.labels.map((label) => ({ value: label.id, label: label.title }))
          : [],
        rules: { required: "Please choose at least one label" },
      },
      {
        field: "text",
        type: "text",
        name: "createCourseTitle",
        label: "Title",
        rules: { required: "Please provide title" },
      },
      {
        field: "radio",
        name: "createCourseLocale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: LocaleEnum.EN,
        rules: { required: "Please provide locale" },
      },
      {
        field: "markdown",
        name: "createCourseDescription",
        label: "Description",
        rules: { required: "Please provide description" },
      },
      {
        field: "upload",
        type: "text",
        name: "createCourseBackground",
        label: "Small background image",
        rules: { required: "Please provide background image" },
      },
      {
        field: "text",
        type: "text",
        name: "createCourseTrailerVimeoId",
        label: "Trailer vimeo id",
        rules: { required: "Please provide trailer id" },
      },
      {
        field: "text",
        type: "text",
        name: "createCourseOrderWeight",
        label: "Order weight (optional, larger shown later)",
      },
      {
        field: "radio",
        name: "createCourseIsAdultOnly",
        label: "Adult only (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
      },
    ],
    [authorsData, labelsData],
  );

  const onCreateCourseSubmit = useCallback(
    async (data: CreateCourseData) => {
      const backgroundImage = getFileUploadById("createCourseBackground");
      const response = await adminCreateCourse({
        variables: {
          createCourseTitle: data.createCourseTitle,
          createCourseAuthorId: data.createCourseAuthorId.toString(),
          createCourseLabelIds: data.createCourseLabelIds,
          createCourseLocale: data.createCourseLocale,
          createCourseDescription: data.createCourseDescription,
          createCourseBackground: backgroundImage,
          createCourseTrailerVimeoId: data.createCourseTrailerVimeoId,
          createCourseOrderWeight: parseInt(data.createCourseOrderWeight),
          createCourseIsAdultOnly: data.createCourseIsAdultOnly === "true",
        },
        refetchQueries: ["Courses"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "courses",
            page: "course",
            id: response.data.adminCreateCourse.id,
          }),
        });
      }
    },
    [adminCreateCourse, navigate],
  );

  return (
    <Modal title="Create new course" error={adminCreateCourseResult.error} {...rest}>
      <GeneratedForm
        loading={adminCreateCourseResult.loading}
        error={adminCreateCourseResult.error}
        onSubmit={onCreateCourseSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
