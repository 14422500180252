import { gql } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateTrackModal } from "../../modals/CreateTrackModal/CreateTrackModal";
import { DeleteTrackModal } from "../../modals/DeleteTrackModal/DeleteTrackModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  MatchModeEnum,
  useAdminDeleteTrackMutation,
  UserScopeEnum,
  useTracksQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatDuration } from "../../services/formatDuration";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { limitTextLength } from "../../services/limitTextLength";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query Tracks($filter: AdminTracksFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      tracks(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        tracks {
          ...TrackInfo
          category {
            ...CategoryInfo
          }
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteTrack($trackIds: [ID!]!) {
    adminDeleteTrack(trackIds: $trackIds) {
      id
    }
  }
`;

interface TrackFilterData extends FilterBaseData {
  trackId: string;
  title: string;
  description: string;
  wowzaTrackName: string;
  trailerUrl: string;
  trackDurationSeconds: string;
  averageRating: string;
  reviewCount: string;
  isFree: boolean | null;
}

export const TrackListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const [isCreateTrackModalOpen, setIsCreateTrackModalOpen] = useState(false);
  const [isDeleteTrackModalOpen, setIsDeleteTrackModalOpen] = useState(false);
  const [confirmDeleteTrackIds, setConfirmDeleteTrackIds] = useState<string | string[]>([]);
  const [adminDeleteTrack, adminDeleteTrackResult] = useAdminDeleteTrackMutation({
    refetchQueries: ["Tracks"],
    awaitRefetchQueries: true,
  });

  const params = useUrlParams<TrackFilterData>((params) => ({
    trackId: params.trackId ?? "",
    title: params.title ?? "",
    description: params.description ?? "",
    wowzaTrackName: params.wowzaTrackName ?? "",
    trailerUrl: params.trailerUrl ?? "",
    trackDurationSeconds: params.trackDurationSeconds ?? "",
    averageRating: params.averageRating ?? "",
    reviewCount: params.reviewCount ?? "",
    isFree: params.isFree ? (params.isFree === "true" ? true : false) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const {
    data: tracksData,
    loading: tracksLoading,
    error: tracksError,
  } = useTracksQuery({
    variables: {
      filter: {
        trackId: params.trackId.length > 0 ? params.trackId : undefined,
        title: params.title.length > 0 ? params.title : undefined,
        description: params.description.length > 0 ? params.description : undefined,
        wowzaTrackName: params.wowzaTrackName.length > 0 ? params.wowzaTrackName : undefined,
        trailerUrl: params.trailerUrl.length > 0 ? params.trailerUrl : undefined,
        trackDurationSeconds: params.trackDurationSeconds.length > 0 ? params.trackDurationSeconds : undefined,
        averageRating: params.averageRating.length > 0 ? params.averageRating : undefined,
        reviewCount: params.reviewCount.length > 0 ? params.reviewCount : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  // pagination info
  const tracks = useMemo(() => tracksData?.admin.tracks.tracks ?? [], [tracksData?.admin.tracks.tracks]);
  const total = tracksData?.admin.tracks.total ?? 0;
  const pageCount = getPageCount(total);

  const filters = useMemo<FormFieldOptions[]>(
    () => [
      // TODO: filter by range search for average rating, review count, track duration & intro duration
      {
        field: "text",
        type: "text",
        name: "trackId",
        label: "Track id",
        defaultValue: params.trackId,
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Track title",
        defaultValue: params.title,
      },
      {
        field: "text",
        type: "text",
        name: "description",
        label: "Description",
        defaultValue: limitTextLength(params.description),
      },
      {
        field: "text",
        type: "text",
        name: "wowzaTrackName",
        label: "Wowza track name",
        defaultValue: params.wowzaTrackName,
      },
    ],
    [params.description, params.title, params.trackId, params.wowzaTrackName],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Title",
      },
      {
        label: "Description",
      },
      {
        label: "Category",
      },
      {
        label: "Track duration",
        center: true,
      },
      {
        label: "Average rating",
        center: true,
      },
      {
        label: "Review count",
        center: true,
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      tracks.map((track) => {
        return {
          id: track.id,
          cells: [
            {
              content: track.title,
            },
            {
              content: track.description,
            },
            {
              content: track.category?.title ?? "n/a",
              linkUrl: track.category
                ? buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "categories",
                    page: "category",
                    id: track.category.id,
                  })
                : undefined,
            },
            {
              content: formatDuration(track.trackDurationSeconds * 1000),
              center: true,
            },
            {
              content: track.averageRating.toFixed(1),
              center: true,
            },
            {
              content: track.reviewCount,
              center: true,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_INFO],
              onClick: (trackId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "tracks",
                    page: "track",
                    id: trackId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_TRACKS,
                UserScopeEnum.ADMIN_TRACKS_UPDATE_INFO,
              ],
              onClick: (trackId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "tracks",
                    page: "track",
                    id: trackId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Delete",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_TRACKS,
                UserScopeEnum.ADMIN_TRACKS_DELETE,
              ],
              onClick: (trackIds) => {
                setConfirmDeleteTrackIds(trackIds);
                setIsDeleteTrackModalOpen(true);
              },
            },
          ],
        };
      }),
    [navigate, tracks],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_DELETE],
        onClick: (trackIds) => {
          setConfirmDeleteTrackIds(trackIds);
          setIsDeleteTrackModalOpen(true);
        },
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create track",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_CREATE],
        onClick: () => setIsCreateTrackModalOpen(true),
      },
    ],
    [],
  );

  const onFilterSubmit = useCallback(
    (data: TrackFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "tracks" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  if (tracksError) {
    return <ErrorView title="Fetching tracks failed" error={tracksError} />;
  }

  return (
    <View scrollable>
      <Filter
        title="Tracks"
        fields={filters}
        actions={viewActions}
        viewerscopes={viewer.scopes}
        loading={tracksLoading}
        matchMode={params.matchMode}
        conditionMode={params.conditionMode}
        onSubmit={onFilterSubmit}
      />
      <DataTable
        headers={headers}
        rows={rows}
        loading={tracksLoading}
        stats={{ resultCount: total, pageCount }}
        bulkActions={bulkActions}
        viewerscopes={viewer.scopes}
        openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_INFO]}
        onOpen={(row) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "tracks",
              page: "track",
              modifier: row.id,
            }),
          })
        }
      />
      <Pagination
        sticky
        pageCount={pageCount}
        currentPage={params.page}
        onPageChange={(page) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "tracks" }),
            search: getUrlSearchParamsString({ ...params, page }),
          })
        }
      />
      <DeleteTrackModal
        open={isDeleteTrackModalOpen}
        onCancel={() => setIsDeleteTrackModalOpen(false)}
        onClickOutside={() => setIsDeleteTrackModalOpen(false)}
        loading={adminDeleteTrackResult.loading}
        error={adminDeleteTrackResult.error}
        onSubmit={async () => {
          const response = await adminDeleteTrack({
            variables: {
              trackIds: confirmDeleteTrackIds,
            },
          });

          if (response.data) {
            setIsDeleteTrackModalOpen(false);
          }
        }}
      />
      <CreateTrackModal open={isCreateTrackModalOpen} onClickOutside={() => setIsCreateTrackModalOpen(false)} />
    </View>
  );
};
