import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { RejectAdminFeedItemMutation, useRejectAdminFeedItemMutation } from "../../schema";

type AdminFeedItemRejectData = {
  comment: string;
};

export interface AdminFeedItemRejectModalProps extends ModalProps {
  adminFeedItemId: string;
  onCompleted: (data: RejectAdminFeedItemMutation) => void;
}

export const AdminFeedItemRejectModal: React.FC<AdminFeedItemRejectModalProps> = ({
  adminFeedItemId,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [adminFeedItemReject, adminFeedItemRejectResult] = useRejectAdminFeedItemMutation({
    refetchQueries: ["CollaborationById", "AdminFeedItemById"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        name: "comment",
        label: "Comment",
      },
    ],
    [],
  );

  const onSubmit: SubmitHandler<AdminFeedItemRejectData> = async (data) => {
    await adminFeedItemReject({
      variables: {
        adminFeedItemId: adminFeedItemId,
        comment: data.comment,
      },
    });
  };

  return (
    <Modal title="Reject feed item" {...rest} onClickOutside={onClickOutside}>
      <P center>Are you sure you wish to Reject this Feed item?</P>
      <P center small style={{ color: "red" }}>
        Rejected items cannot be modified and will never be shown to users
      </P>
      <GeneratedForm
        loading={adminFeedItemRejectResult.loading}
        error={adminFeedItemRejectResult.error}
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
