import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";

export interface DeleteLabelModalProps extends ModalProps {
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  loading: boolean;
}

export const DeleteLabelModal: React.FC<DeleteLabelModalProps> = ({ onSubmit, onCancel, loading, ...rest }) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      loading: loading,
      onClick: onSubmit,
    },
  ];
  return (
    <Modal actions={modalActions} title="Confirm deleting FAQs" {...rest}>
      <P center>Are you sure you wish to delete these labels</P>
    </Modal>
  );
};
