import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { ADMIN_VIEW_PATH, AdminViewParams } from "../../routes";
import {
  CollaborationTypeEnum,
  LocaleEnum,
  UserRoleEnum,
  UserStatusEnum,
  useAdminUpdateUserInfoMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { validateEmail } from "../../validators/validateEmail";
import { UserProps } from "./UserDetailsView";

interface EditUserData {
  email: string;
  name: string;
  status: UserStatusEnum;
  isTestUser: string;
  locale: LocaleEnum;
  roles: UserRoleEnum[];
  dueDate: Date | null;
  babyBirthdayDate: Date | null;
  birthdayDate: Date | null;
  comment: string;
}

// edit user details form
export const UserDetailsEdit: React.FC<UserProps> = ({ user, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateUserInfo, adminUpdateUserInfoResult] = useAdminUpdateUserInfoMutation();

  // TODO: add the new fields that have been added to the user lately
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "email",
        label: "Email",
        defaultValue: user.email,
        rules: {
          required: "Please provide email address",
          validate: validateEmail,
        },
      },
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Name",
        defaultValue: user.name,
        rules: {
          required: "Please provide name",
        },
      },
      {
        field: "radio",
        name: "status",
        label: "Status",
        options: [
          { value: UserStatusEnum.ACTIVE, label: "Active" },
          { value: UserStatusEnum.DEACTIVATED, label: "Deactivated" },
          { value: UserStatusEnum.DISABLED, label: "Disabled" },
        ],
        defaultValue: user.status,
        rules: {
          required: "Please choose status",
        },
      },
      {
        field: "radio",
        name: "isTestUser",
        label: "Is test user?",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: user.isTestUser ? "true" : "false",
        rules: {
          required: "Please choose status",
        },
      },
      {
        field: "checkbox",
        name: "roles",
        label: "Roles",
        options: [
          { value: UserRoleEnum.USER, label: "User" },
          { value: UserRoleEnum.SUPPORT_MEMBER, label: "Support member" },
          { value: UserRoleEnum.SUPPORT_LEAD, label: "Support lead" },
          { value: UserRoleEnum.ADMIN, label: "Admin" },
        ],
        defaultValue: user.roles,
        rules: {
          required: "Please choose at least one role",
        },
      },
      {
        field: "radio",
        name: "locale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: user.locale,
        rules: {
          required: "Please choose user language",
        },
      },
      {
        field: "date",
        name: "dueDate",
        label: "Due date",
        defaultValue: typeof user.dueDate === "string" ? new Date(user.dueDate) : null,
        clearable: true,
      },
      {
        field: "date",
        name: "birthdayDate",
        label: "User birthday date",
        defaultValue: typeof user.birthdayDate === "string" ? new Date(user.birthdayDate) : null,
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated user info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
    ],
    [user.email, user.name, user.status, user.isTestUser, user.roles, user.locale, user.dueDate, user.birthdayDate],
  );

  // TODO: update user info
  const onSubmit = useCallback(
    async (data: EditUserData) => {
      // update user info
      const response = await adminUpdateUserInfo({
        variables: {
          ...data,
          userId: user.id,
          isTestUser: data.isTestUser === "true",
          birthdayDate: data.birthdayDate ? formatApiDate(data.birthdayDate) : null,
          dueDate: data.dueDate ? formatApiDate(data.dueDate) : null,
        },
        refetchQueries: ["CollaborationById"],
        awaitRefetchQueries: true,
      });

      // redirect to details (non-edit mode) when successfully updated
      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "users",
            page: "user",
            id: user.id,
          }),
        });
      }
    },
    [adminUpdateUserInfo, navigate, user.id],
  );

  return (
    <WithCollaboration referenceId={user.id} type={CollaborationTypeEnum.USER} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Profile"
        error={adminUpdateUserInfoResult.error}
        loading={adminUpdateUserInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
