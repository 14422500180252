import classNames from "classnames";
import React from "react";
import { FlexProps, FlexBase, FlexTag } from "../Flex/Flex";
import styles from "./Table.module.scss";

export type TableProps = FlexProps<"table">;

class TableFlex extends FlexBase<"table"> {
  tag: FlexTag = "table";
}

export const Table: React.FC<TableProps> = ({ className, children, ...rest }) => (
  <TableFlex className={classNames(styles.table, className)} {...rest}>
    {children}
  </TableFlex>
);

export type TrProps = FlexProps<"tr"> & {
  highlight?: boolean;
};

class TrFlex extends FlexBase<"tr"> {
  tag: FlexTag = "tr";
}

export const Tr: React.FC<TrProps> = ({ highlight, className, children, ...rest }) => (
  <TrFlex className={classNames(styles.tr, { [styles["tr--highlight"]]: highlight }, className)} {...rest}>
    {children}
  </TrFlex>
);

export type ThProps = FlexProps<"th">;

class ThFlex extends FlexBase<"th"> {
  tag: FlexTag = "th";
}

export const Th: React.FC<ThProps> = ({ compact, center, className, children, ...rest }) => (
  <ThFlex
    className={classNames(
      styles.th,
      {
        [styles["th--compact"]]: compact,
        [styles["th--center"]]: center,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </ThFlex>
);

export type TdProps = FlexProps<"td">;

class TdFlex extends FlexBase<"td"> {
  tag: FlexTag = "td";
}

export const Td: React.FC<TdProps> = ({ center, className, children, ...rest }) => (
  <TdFlex
    className={classNames(
      styles.td,
      {
        [styles["th--center"]]: center,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </TdFlex>
);
