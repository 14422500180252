import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminUpdateGiftCardInfoMutation, useAdminUpdateGiftCardInfoMutation } from "../../schema";

type UpdateGiftCardRecipientData = {
  toEmail: string;
  comment: string;
};

export interface GiftCardProps extends ModalProps {
  giftCardId: string;
  toEmail: string;
  onCompleted: (data: AdminUpdateGiftCardInfoMutation) => void;
}

export const ChangeGiftCardRecipientModal: React.FC<GiftCardProps> = ({
  giftCardId,
  toEmail,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [adminUpdateGiftCardInfo, adminUpdateGiftCardInfoResult] = useAdminUpdateGiftCardInfoMutation({
    refetchQueries: ["AdminGiftCardById"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "toEmail",
        label: "Recipient email",
        defaultValue: toEmail,
        rules: { required: "Please provide new email" },
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Changed recipient email due to",
        rules: {
          required: "Please describe why was the email changed",
        },
      },
    ],
    [toEmail],
  );

  const onSubmit: SubmitHandler<UpdateGiftCardRecipientData> = async (data) => {
    await adminUpdateGiftCardInfo({
      variables: {
        giftCardId: giftCardId,
        toEmail: data.toEmail,
        comment: data.comment,
      },
      refetchQueries: ["AdminGiftCardById"],
      awaitRefetchQueries: true,
    });
  };

  return (
    <Modal
      title="Change Gift Card Recipient"
      error={adminUpdateGiftCardInfoResult.error}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <GeneratedForm
        loading={adminUpdateGiftCardInfoResult.loading}
        error={adminUpdateGiftCardInfoResult.error}
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
