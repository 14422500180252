export function getTimezoneOffsetMinutes(timeZone: string) {
  try {
    const utcDate = new Date(new Date().toLocaleString("en-US", { timeZone: "UTC" }));
    const clientDate = new Date(new Date().toLocaleString("en-US", { timeZone }));

    const timeDifference = clientDate.getTime() - utcDate.getTime();
    const timezoneOffsetMinutes = timeDifference / (1000 * 60);

    return timezoneOffsetMinutes;
  } catch (_e) {
    return 0;
  }
}
