import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useAdminCreateAppReviewMutation, useCategoriesQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { validateNumeric } from "../../validators/validateNumeric";

interface CreateAppReviewData {
  categoryId: string;
  review: string;
  rating: string;
  authorName: string;
  authorTitle: string;
  authorAvatar: string;
}

// TODO: implement creating author modal
export const CreateAppReviewModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const { data: categoriesData } = useCategoriesQuery();

  const [adminCreateAppReview, adminCreateAppReviewResult] = useAdminCreateAppReviewMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "categoryId",
        label: "Category",
        options: categoriesData
          ? categoriesData.admin.categories.categories.map((category) => ({
              value: category.id,
              label: category.title,
            }))
          : [],
        rules: { required: "Please provide category" },
      },
      {
        field: "markdown",
        name: "review",
        label: "Review",
        rules: { required: "Please provide review" },
      },
      {
        field: "text",
        type: "text",
        name: "rating",
        label: "Rating",
        rules: {
          required: "Please provide rating",
          validate: validateNumeric({ min: 1, max: 5 }),
        },
      },
      {
        field: "text",
        type: "text",
        name: "authorName",
        label: "Author name",
        rules: { required: "Please provide author name" },
      },
      {
        field: "text",
        type: "text",
        name: "authorTitle",
        label: "Author title",
        rules: { required: "Please provide author title" },
      },
      {
        field: "upload",
        type: "text",
        name: "authorAvatar",
        label: "Author avatar",
        rules: { required: "Please provide author avatar" },
      },
    ],
    [categoriesData],
  );

  const onCreateAppReviewSubmit = useCallback(
    async (data: CreateAppReviewData) => {
      const authorAvatar = getFileUploadById("authorAvatar");
      // create App review
      const response = await adminCreateAppReview({
        variables: {
          categoryId: data.categoryId,
          review: data.review,
          rating: parseFloat(data.rating),
          authorName: data.authorName,
          authorTitle: data.authorTitle,
          authorAvatar,
        },
        // update list of App reviews after creating new one
        refetchQueries: ["appReviews"],
        awaitRefetchQueries: true,
      });

      // redirect to details (non-edit mode) when successfully created
      if (response.data) {
        navigate(
          buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "app-reviews",
            page: "app-review",
            id: response.data.adminCreateAppReview.id,
          }),
        );
      }
    },
    [adminCreateAppReview, navigate],
  );

  return (
    <Modal {...rest} title="Create app review" error={adminCreateAppReviewResult.error}>
      <GeneratedForm
        loading={adminCreateAppReviewResult.loading}
        error={adminCreateAppReviewResult.error}
        onSubmit={onCreateAppReviewSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
