import { Markdown } from "../components/Markdown/Markdown";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { Row } from "../components/Row/Row";
import { AppReviewInfoFragment } from "../schema";

export function getAppReviewNameValueList(appReview: AppReviewInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: appReview.id,
    },
    {
      name: "Review",
      value: <Markdown>{appReview.review}</Markdown>,
    },
    {
      name: "Rating",
      value: appReview.rating?.toFixed(1),
    },
    {
      name: "Locale",
      value: appReview.locale,
    },
    {
      name: "Author name",
      value: appReview.authorName,
    },
    {
      name: "Author job",
      value: appReview.authorTitle,
    },
    {
      name: "Author avatar",
      value: (
        <Row>
          <img src={appReview.authorAvatar} alt="Author avatar" style={{ maxWidth: "50rem" }} />
        </Row>
      ),
    },
  ];
}
