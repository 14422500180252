import { gql } from "@apollo/client";
import { paramCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateLessonModal } from "../../modals/CreateLessonModal/CreateLessonModal";
import { DeleteLessonModal } from "../../modals/DeleteLessonModal/DeleteLessonModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  LocaleEnum,
  MatchModeEnum,
  useAdminDeleteLessonMutation,
  useLessonsQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { limitTextLength } from "../../services/limitTextLength";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query Lessons($filter: AdminLessonsFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      lessons(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        lessons {
          ...LessonInfo
          course {
            ...CourseInfo
          }
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteLesson($lessonIds: [ID!]!) {
    adminDeleteLesson(lessonIds: $lessonIds) {
      id
    }
  }
`;

interface LessonsFilterData extends FilterBaseData {
  lessonId: string;
  title: string;
  description: string;
  courseName: string;
  locale: LocaleEnum[];
}

export const LessonsListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<LessonsFilterData>((params) => ({
    lessonId: params.lessonId ?? "",
    title: params.title ?? "",
    courseName: params.courseName ?? "",
    description: params.description ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    locale: params.locale ? (params.locale.split(",") as LocaleEnum[]) : [],
  }));

  const {
    data: lessonsData,
    loading: lessonsLoading,
    error: lessonsError,
  } = useLessonsQuery({
    variables: {
      filter: {
        courseName: params.courseName.length > 0 ? params.courseName : undefined,
        lessonId: params.lessonId.length > 0 ? params.lessonId : undefined,
        title: params.title.length > 0 ? params.title : undefined,
        description: params.description.length > 0 ? params.description : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const [isCreateLessonModalOpen, setIsCreateLessonModalOpen] = useState(false);
  const [isDeleteLessonModalOpen, setIsDeleteLessonModalOpen] = useState(false);
  const [confirmDeleteLessonIds, setConfirmDeleteLessonIds] = useState<string | string[]>([]);
  const [adminDeleteLesson, adminDeleteLessonResult] = useAdminDeleteLessonMutation({
    refetchQueries: ["Lessons"],
    awaitRefetchQueries: true,
  });

  // pagination info
  const lessons = useMemo(() => lessonsData?.admin.lessons.lessons ?? [], [lessonsData?.admin.lessons.lessons]);

  const total = lessonsData?.admin.lessons.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "lessonId",
        label: "Lesson id",
        defaultValue: params.lessonId,
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: params.title,
      },
      {
        field: "text",
        type: "text",
        name: "description",
        label: "Description",
        defaultValue: params.description,
      },
      {
        field: "text",
        type: "text",
        name: "courseName",
        label: "Masterclass name",
        defaultValue: params.courseName,
      },
    ],
    [params.courseName, params.lessonId, params.description, params.title],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Title",
      },
      {
        label: "Description",
      },
      {
        label: "Course",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      lessons.map((lesson) => {
        const { course } = lesson;
        return {
          id: lesson.id,
          cells: [
            {
              content: lesson.title,
            },
            {
              content: limitTextLength(lesson.description ?? ""),
            },
            {
              content: course.title,
              linkUrl: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "courses",
                page: "course",
                id: course.id,
              }),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_LESSONS,
                UserScopeEnum.ADMIN_LESSONS_INFO,
              ],
              onClick: (lessonId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "lessons",
                    page: "lesson",
                    id: lessonId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_LESSONS,
                UserScopeEnum.ADMIN_LESSONS_UPDATE_INFO,
              ],
              onClick: (lessonId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "lessons",
                    page: "lesson",
                    id: lessonId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Delete",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_LESSONS,
                UserScopeEnum.ADMIN_LESSONS_DELETE,
              ],
              onClick: (lessonIds) => {
                setConfirmDeleteLessonIds(lessonIds);
                setIsDeleteLessonModalOpen(true);
              },
            },
          ],
        };
      }),
    [lessons, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LESSONS, UserScopeEnum.ADMIN_LESSONS_DELETE],
        onClick: (lessonIds) => {
          setConfirmDeleteLessonIds(lessonIds);
          setIsDeleteLessonModalOpen(true);
        },
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create lesson",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LESSONS, UserScopeEnum.ADMIN_LESSONS_CREATE],
        onClick: () => setIsCreateLessonModalOpen(true),
      },
    ],
    [],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: LessonsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "lessons" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (lessonsError) {
    return <ErrorView title="Fetching lessons failed" error={lessonsError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Lessons"
          fields={filters}
          actions={viewActions}
          viewerscopes={viewer.scopes}
          loading={lessonsLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={lessonsLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_LESSONS,
            UserScopeEnum.ADMIN_LESSONS_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "lessons",
                page: "lesson",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "lessons" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />

        <DeleteLessonModal
          open={isDeleteLessonModalOpen}
          onCancel={() => setIsDeleteLessonModalOpen(false)}
          onClickOutside={() => setIsDeleteLessonModalOpen(false)}
          loading={adminDeleteLessonResult.loading}
          error={adminDeleteLessonResult.error}
          onSubmit={async () => {
            const response = await adminDeleteLesson({
              variables: {
                lessonIds: confirmDeleteLessonIds,
              },
            });

            if (response.data) {
              setIsDeleteLessonModalOpen(false);

              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "lessons",
                }),
              });
            }
          }}
        />

        <CreateLessonModal open={isCreateLessonModalOpen} onClickOutside={() => setIsCreateLessonModalOpen(false)} />
      </View>
    </>
  );
};
