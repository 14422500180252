import { gql } from "@apollo/client";
import { sentenceCase } from "change-case";
import { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DeleteLabelModal } from "../../modals/DeleteLabelModal/DeleteLabelModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  CollaborationTypeEnum,
  LabelByIdQueryResult,
  LabelTypeEnum,
  LocaleEnum,
  useAdminDeleteLabelMutation,
  useAdminUpdateLabelInfoMutation,
  useLabelByIdQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getLabelNameValueList } from "../../services/getLabelNamedValueList";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query LabelById($labelId: ID!) {
    admin {
      label(labelId: $labelId) {
        ...LabelInfo
      }
    }
  }
`;

gql`
  mutation AdminDeleteLabel($labelIds: [ID!]!) {
    adminDeleteLabel(labelIds: $labelIds) {
      id
    }
  }
`;

gql`
  mutation AdminUpdateLabelInfo(
    $labelId: ID!
    $title: String!
    $locale: LocaleEnum!
    $icon: Upload
    $backgroundImage: Upload
    $type: LabelTypeEnum!
    $orderWeight: Int!
    $isAdultOnly: Boolean!
    $comment: String!
    $oneSignalTriggerName: String
  ) {
    adminUpdateLabelInfo(
      labelId: $labelId
      title: $title
      locale: $locale
      type: $type
      icon: $icon
      backgroundImage: $backgroundImage
      comment: $comment
      orderWeight: $orderWeight
      isAdultOnly: $isAdultOnly
      oneSignalTriggerName: $oneSignalTriggerName
    ) {
      ...LabelInfo
    }
  }
`;

type LabelByIdQueryLabelInfo = NonNullable<LabelByIdQueryResult["data"]>["admin"]["label"];

export interface LabelDetailsViewProps {
  viewer: ViewerInfo;
}

export const LabelDetailsView: React.FC<LabelDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: labelData,
    loading: labelLoading,
    error: labelError,
  } = useLabelByIdQuery({
    variables: {
      labelId: params.id ?? "",
    },
  });

  // get label info
  const label = labelData?.admin.label;

  // handle error
  if (labelError) {
    return <ErrorView title="Loading label info failed" error={labelError} />;
  }

  // handle loading
  if (labelLoading || !label) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <LabelDetailsHeader label={label} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<LabelDetailsInfo label={label} viewer={viewer} />} />
        <Route path="edit" element={<LabelDetailsEdit label={label} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface LabelProps extends FlexProps {
  label: LabelByIdQueryLabelInfo;
  viewer: ViewerInfo;
}

export const LabelDetailsHeader: React.FC<LabelProps> = ({ label, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const [isDeleteLabelModalOpen, setIsDeleteLabelModalOpen] = useState(false);
  const [adminDeleteLabel, adminDeleteLabelResult] = useAdminDeleteLabelMutation();
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LABELS, UserScopeEnum.ADMIN_LABELS_DELETE],
        onClick: () => {
          setIsDeleteLabelModalOpen(true);
        },
      },
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "labels",
                  page: "label",
                  id: label.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_LABELS,
              UserScopeEnum.ADMIN_LABELS_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "labels",
                  page: "label",
                  id: label.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, label.id, navigate],
  );

  return (
    <>
      <Header actions={actions} {...rest}>
        <Title>Label details</Title>
      </Header>

      <DeleteLabelModal
        open={isDeleteLabelModalOpen}
        onCancel={() => setIsDeleteLabelModalOpen(false)}
        onClickOutside={() => setIsDeleteLabelModalOpen(false)}
        loading={adminDeleteLabelResult.loading}
        error={adminDeleteLabelResult.error}
        onSubmit={async () => {
          const response = await adminDeleteLabel({
            variables: {
              labelIds: label.id,
            },
          });

          if (response.data) {
            setIsDeleteLabelModalOpen(false);

            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "labels",
              }),
            });
          }
        }}
      />
    </>
  );
};

export const LabelDetailsInfo: React.FC<LabelProps> = ({ label, viewer }) => {
  const labelDetails = getLabelNameValueList(label);

  return (
    <WithCollaboration referenceId={label.id} type={CollaborationTypeEnum.LABEL} viewer={viewer}>
      <Title>Label</Title>
      <NameValueList items={labelDetails} />
    </WithCollaboration>
  );
};

type labelData = {
  title: string;
  locale: LocaleEnum;
  type: LabelTypeEnum;
  icon: string;
  backgroundImage: string;
  orderWeight: string;
  comment: string;
  oneSignalTriggerName: string;
  isAdultOnly: "true" | "false";
};

export const LabelDetailsEdit: React.FC<LabelProps> = ({ label, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateLabelInfo, adminUpdateLabelInfoResult] = useAdminUpdateLabelInfoMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        name: "title",
        label: "Title",
        defaultValue: label.title,
      },
      {
        field: "radio",
        name: "locale",
        label: "Locale",
        options: [
          { value: LocaleEnum.EN, label: "English" },
          { value: LocaleEnum.ET, label: "Estonian" },
        ],
        defaultValue: label.locale,
      },
      {
        field: "radio",
        name: "type",
        label: "Type",
        options: Object.keys(LabelTypeEnum).map((labelType) => {
          return { value: labelType, label: sentenceCase(labelType) };
        }),
        defaultValue: label.type,
        rules: {
          required: "Please provide type",
        },
      },
      {
        field: "upload",
        type: "text",
        name: "icon",
        label: "Icon (leave blank to not change)",
      },
      {
        field: "upload",
        type: "text",
        name: "backgroundImage",
        label: "Small background image (leave blank to not change)",
      },
      {
        field: "text",
        type: "text",
        name: "orderWeight",
        label: "Order weight (optional, larger shown later)",
        defaultValue: label.orderWeight.toString(),
      },
      {
        field: "text",
        type: "text",
        name: "oneSignalTriggerName",
        label: "OneSignal trigger name (optional)",
        defaultValue: label.oneSignalTriggerName ?? "N/A",
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Adult only (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: label.isAdultOnly ? "true" : "false",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated label info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
    ],
    [label.orderWeight, label.isAdultOnly, label.title, label.locale, label.type, label.oneSignalTriggerName],
  );

  const onSubmit = useCallback(
    async (data: labelData) => {
      const icon = getFileUploadById("icon");
      const backgroundImage = getFileUploadById("backgroundImage");

      // update label data
      const response = await adminUpdateLabelInfo({
        variables: {
          ...data,
          orderWeight: parseInt(data.orderWeight),
          icon,
          backgroundImage,
          labelId: label.id,
          isAdultOnly: data.isAdultOnly === "true",
          oneSignalTriggerName: data.oneSignalTriggerName,
        },
        refetchQueries: ["CollaborationById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "labels",
            page: "label",
            id: label.id,
          }),
        });
      }
    },
    [adminUpdateLabelInfo, label.id, navigate],
  );

  return (
    <WithCollaboration referenceId={label.id} type={CollaborationTypeEnum.LABEL} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Label"
        error={adminUpdateLabelInfoResult.error}
        loading={adminUpdateLabelInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
