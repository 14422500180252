import { sentenceCase } from "change-case";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, useAdminFeedPollsQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { formatDate } from "../../services/formatDate";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface AdminFeedPollsFilterData extends FilterBaseData {
  adminFeedPollId: string;
  question: string;
  fromDate: Date | null;
  toDate: Date | null;
}

export const AdminFeedPollListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  const params = useUrlParams<AdminFeedPollsFilterData>((params) => ({
    adminFeedPollId: params.adminFeedPollId ?? "",
    question: params.question ?? "",
    fromDate: params.fromDate ? new Date(params.fromDate) : null,
    toDate: params.toDate ? new Date(params.toDate) : null,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const { data, loading, error } = useAdminFeedPollsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        adminFeedPollId: params.adminFeedPollId.length > 0 ? params.adminFeedPollId : undefined,
        question: params.question.length > 0 ? params.question : undefined,
        fromDate: params.fromDate ? formatApiDate(params.fromDate) : undefined,
        toDate: params.toDate ? formatApiDate(params.toDate) : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const adminFeedPolls = useMemo(
    () => data?.admin.adminFeedPolls.adminFeedPolls ?? [],
    [data?.admin.adminFeedPolls.adminFeedPolls],
  );

  const total = data?.admin.adminFeedPolls.total ?? 0;
  const pageCount = getPageCount(total);

  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "adminFeedPollId",
        label: "Poll ID",
        defaultValue: params.adminFeedPollId,
      },
      {
        field: "date",
        name: "fromDate",
        label: "From date",
        defaultValue: params.fromDate,
      },
      {
        field: "date",
        name: "toDate",
        label: "To date",
        defaultValue: params.toDate,
      },
      {
        field: "text",
        type: "text",
        name: "question",
        label: "Question",
        defaultValue: params.question,
      },
    ],
    [params.adminFeedPollId, params.question, params.fromDate, params.toDate],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Question",
      },
      {
        label: "Audience",
      },
      {
        label: "Start date",
      },
      {
        label: "End date",
      },
      {
        label: "Start day",
      },
      {
        label: "End day",
      },
      {
        label: "Is pinned",
      },
      {
        label: "Status",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      adminFeedPolls.map((adminFeedPoll) => ({
        id: adminFeedPoll.id,
        cells: [
          {
            content: adminFeedPoll.question,
          },
          {
            content: adminFeedPoll.adminFeedItem.audience.map((audience) => sentenceCase(audience)).join(", "),
          },
          {
            content: formatDate(adminFeedPoll.adminFeedItem.startDate),
          },
          {
            content: formatDate(adminFeedPoll.adminFeedItem.endDate) ?? "n/a",
          },
          {
            content: adminFeedPoll.adminFeedItem.startDay ?? "n/a",
          },
          {
            content: adminFeedPoll.adminFeedItem.endDay ?? "n/a",
          },
          {
            content: adminFeedPoll.adminFeedItem.isPinned ? "Yes" : "No",
          },
          {
            content: adminFeedPoll.adminFeedItem.status,
          },
        ],
        actions: [
          {
            label: "View details",
            onClick: (adminFeedPollId) =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "feed-polls",
                  page: "polls",
                  id: adminFeedPollId,
                }),
              }),
          },
          {
            label: "Edit",
            onClick: (adminFeedPollId) =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "feed-polls",
                  page: "polls",
                  id: adminFeedPollId,
                  modifier: "edit",
                }),
              }),
          },
        ],
      })),
    [navigate, adminFeedPolls],
  );

  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create poll",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED_CREATE_POLL],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "feed-polls",
              page: "polls",
              modifier: "new",
            }),
          }),
      },
    ],
    [navigate],
  );

  const onFilterSubmit = useCallback(
    (data: AdminFeedPollsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-polls", page: "polls" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  if (error) {
    return <ErrorView title="Fetching admin feed polls failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Admin feed polls"
          fields={filters}
          actions={viewActions}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_FEED_POLL_LIST,
            UserScopeEnum.ADMIN_FEED_POLL_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "feed-polls",
                page: "polls",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination sticky pageCount={pageCount} currentPage={params.page} />
      </View>
    </>
  );
};
