import React, { useCallback, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Container } from "../../components/Container/Container";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { AdminPaymentByIdQueryResult, useAdminResolvePaymentMutation } from "../../schema";
import { getFieldErrors } from "../../services/getFieldErrors";

export interface ResolvePaymentModalProps extends ModalProps {
  onCancel?: VoidFunction;
  payment: NonNullable<AdminPaymentByIdQueryResult["data"]>["admin"]["adminPayment"];
}

export interface ResolvePaymentModalData extends FieldValues {
  statusComment: string;
}

export const ResolvePaymentModal: React.FC<ResolvePaymentModalProps> = ({ onCancel, payment, ...rest }) => {
  const formMethods = useForm<ResolvePaymentModalData>();
  const { handleSubmit, setError } = formMethods;
  const [resolvePayment, resolvePaymentResult] = useAdminResolvePaymentMutation();

  // handle form validation errors
  useEffect(() => {
    const errors = getFieldErrors<ResolvePaymentModalData>(resolvePaymentResult.error);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError("statusComment", { type: "validate", message: errors[name]?.message });
        }
      });
    }
  }, [setError, resolvePaymentResult.error]);

  // handle success logic and modal closing logic
  useEffect(() => {
    if (resolvePaymentResult.data?.resolvePayment && typeof onCancel === "function") {
      onCancel();
    }
  });

  // setup form fields
  const commentField: FormFieldOptions = {
    field: "text",
    type: "text",
    name: "statusComment",
    label: "Comment (optional)",
  };

  // handle attaching payment
  const handleRejectPayment = useCallback(
    async (data: ResolvePaymentModalData) => {
      await resolvePayment({
        refetchQueries: ["AdminPaymentById"],
        awaitRefetchQueries: true,
        variables: {
          paymentId: payment.id,
          statusComment: data.statusComment,
        },
      });
    },
    [payment.id, resolvePayment],
  );

  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      onClick: handleSubmit(handleRejectPayment),
      loading: resolvePaymentResult.loading,
    },
  ];

  return (
    <Modal title="Confirm resolving payment" actions={modalActions} {...rest}>
      <P center marginBottom={5}>
        Are you sure you wish to resolve this payment?
      </P>
      <FormProvider {...formMethods}>
        <Form>
          <Container overflow key="userInput" expanded>
            <FormField field={commentField} />
          </Container>
        </Form>
      </FormProvider>
    </Modal>
  );
};
