import { useRef, useState, useEffect } from "react";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Column } from "../../components/Column/Column";
import { Container } from "../../components/Container/Container";
import { Flex } from "../../components/Flex/Flex";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { UploadFieldOptions } from "../../components/GeneratedForm/GeneratedForm.types";
import { ModalProps } from "../../components/Modal/Modal";
import { TextButton } from "../../components/TextButton/TextButton";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  AdminFeedItemAudienceTypeEnum,
  AdminFeedTopic,
  CreateAdminFeedPollMutation,
  useAdminFeedTopicsQuery,
  useCreateAdminFeedPollMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { generateRandomString } from "../../services/generateRandomString";
import formOptions from "../../services/getEnumFormOptions";
import { getFieldErrors } from "../../services/getFieldErrors";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { validateFileSize } from "../../validators/validateFileSize";
import { validateNumeric } from "../../validators/validateNumeric";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

interface CreateAdminFeedPollData extends FieldValues {
  question: string;
  audience: AdminFeedItemAudienceTypeEnum[];
  isActive: "true" | "false";
  startDate: string | null;
  endDate: string | null;
  startDay: string;
  endDay: string;
  likeCount: string;
  shareCount: string;
  version: string;
  topicIds: string[];
  pollImage: String;
}

interface OptionFields {
  answer: FormFieldOptions;
  image: UploadFieldOptions;
}

export interface CreateAdminFeedPollModalProps extends ModalProps {
  onCompleted: (data: CreateAdminFeedPollMutation) => void;
}

const initialOptionsFormFields: OptionFields[] = [];

export const AdminFeedPollCreateView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [optionIds, setOptionIds] = useState<string[]>([]);
  const [optionfields, setOptionFields] = useState<OptionFields[]>(initialOptionsFormFields);
  const formRef = useRef<HTMLFormElement>(null);

  const formMethods = useForm<CreateAdminFeedPollData>();

  const { setError } = formMethods;

  const { data, loading, error } = useAdminFeedTopicsQuery();

  const [createAdminFeedPoll, createAdminFeedPollResult] = useCreateAdminFeedPollMutation({
    refetchQueries: ["AdminFeedPolls"],
    awaitRefetchQueries: true,
    onError: (_error) => {
      setToast({
        type: ToastType.ERROR,
        title: "Creating a new admin feed poll failed",
        message: `Admin feed poll was not created`,
        isOpen: true,
      });
    },
    onCompleted: (data) => {
      // show success toast message
      setToast({
        type: ToastType.SUCCESS,
        title: "Created a new admin feed poll",
        message: `Admin feed poll was created`,
        isOpen: true,
      });

      // navigate to item details view
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "feed-polls",
          page: "polls",
          id: data.createAdminFeedPoll.id,
        }),
      });
    },
  });

  useEffect(() => {
    const errors = getFieldErrors<CreateAdminFeedPollData>(createAdminFeedPollResult.error);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError(name, { type: "validate", message: errors[name]?.message });
        }
      });
    }
  }, [createAdminFeedPollResult.error, setError]);

  const onAddNewOption = () => {
    setOptionFields(() => {
      const optionId = generateRandomString(5);

      // keep track of dynamic poll option ids
      setOptionIds((currentOptionIds) => [...currentOptionIds, optionId]);

      const optionIndex = optionIds.length;

      return [
        ...optionfields,
        {
          answer: {
            field: "translation",
            name: optionId,
            label: `Option ${optionIndex + 1}`,
            rules: {
              required: "Please provide poll option",
            },
            // defaultValue: { EN: "Poll option", ET: "Küsimuse vastusevariant" },
          } as FormFieldOptions,
          image: {
            field: "upload",
            name: `${optionId}_image`,
            label: `Option ${optionIndex + 1} Icon`,
          } as UploadFieldOptions,
        },
      ];
    });
  };

  const onRemoveOption = (optionId: string) => {
    setOptionFields(() => {
      // remove given field ID
      setOptionIds((currentOptionIds) => [...currentOptionIds].filter((option) => option !== optionId));

      // remove field and remap field labels
      return [
        ...optionfields
          .filter((field) => field.answer.name !== optionId || field.image.name !== `${optionId}_image`)
          .map((field, index) => {
            field.image.label = `Option ${index + 1} Icon`;
            field.answer.label = `Option ${index + 1}`;
            return field;
          }),
      ];
    });
  };

  const onSubmit: SubmitHandler<CreateAdminFeedPollData> = async (data) => {
    const background = getFileUploadById("pollImage");
    // collect poll answer options to an array
    const options = optionIds.map((id) => ({
      answer: getTranslationInput(id, data),
      icon: getFileUploadById(`${id}_image`),
    }));

    const { startDay, endDay, shareCount, likeCount } = data;

    createAdminFeedPoll({
      variables: {
        ...data,
        startDay: startDay ? parseInt(startDay) : null,
        endDay: endDay ? parseInt(endDay) : null,
        endDate: data.endDate ? data.endDate : null,
        shareCount: parseInt(shareCount),
        likeCount: parseInt(likeCount),
        pollImage: background,
        question: getTranslationInput("question", data),
        isPinned: data.isPinned === "true",
        options,
      },
    });
  };

  if (loading) {
    return <LoadingView />;
  }

  // handle errors
  if (error || !data?.admin.adminFeedTopics || data?.admin.adminFeedTopics.length === 0) {
    return (
      <ErrorView
        title="Fetching admin feed topics failed"
        error={error || "No topics were found for Admin feed items"}
      />
    );
  }

  return (
    <View pad="half" scrollable>
      <Title>Create new Poll item</Title>
      <FormProvider {...formMethods}>
        <Form ref={formRef} onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Flex overflow row={false} expanded={false}>
            <h3>Poll feed item data</h3>
            {getFormFields(data?.admin?.adminFeedTopics).map((field) => (
              <Container overflow key={field.name} expanded>
                <FormField field={field} />
              </Container>
            ))}

            <h3>Options</h3>

            {optionfields.map((field, index) => (
              <Container overflow expanded key={field.answer.name}>
                <FormField field={field.answer} />
                <FormField field={field.image} />
                <BlockButton inline secondary small onClick={() => onRemoveOption(field.answer.name)}>
                  Remove option {index + 1}
                </BlockButton>
              </Container>
            ))}

            <TextButton onClick={onAddNewOption}>Add new option</TextButton>
          </Flex>

          <Column marginTop="half" crossAxisAlignment="flex-end">
            <BlockButton inline tertiary loading={false} type="submit">
              Create new item
            </BlockButton>
          </Column>
        </Form>
      </FormProvider>
    </View>
  );
};

const getFormFields = (adminFeedTopics: AdminFeedTopic[]) => {
  return [
    {
      field: "translation",
      name: "question",
      label: "Question",
      rules: {
        required: "Please provide question",
      },
      defaultValue: { EN: "Poll title", ET: "Küsimus" },
    },
    {
      field: "checkbox",
      name: "audience",
      label: "Audience who will see this item",
      options: formOptions.fromEnum(AdminFeedItemAudienceTypeEnum),
      rules: { required: "Please choose at least one audience type" },
    },
    {
      field: "radio",
      name: "isPinned",
      label: "Is pinned to the top",
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
      rules: { required: "Please choose whether the item is pinned to the top" },
      defaultValue: "false",
    },
    {
      field: "date",
      name: "startDate",
      label: "Show item from given date",
      defaultValue: new Date(),
    },
    {
      field: "date",
      name: "endDate",
      label: "Show item until given date (leave empty to not use)",
      clearable: true,
    },
    {
      field: "text",
      type: "text",
      name: "startDay",
      label: "Show item starting from given days after registration (0 for first day, empty to not use)",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive start day number",
        }),
      },
    },
    {
      field: "text",
      type: "text",
      name: "endDay",
      label: "Show item starting until given days after registration (leave empty to not use)",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive end day number",
        }),
      },
    },
    {
      field: "text",
      type: "number",
      name: "likeCount",
      label: "Initial like count",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive like count",
        }),
      },
      defaultValue: "0",
    },
    {
      field: "text",
      type: "number",
      name: "shareCount",
      label: "Initial share count",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive share count",
        }),
      },
      defaultValue: "0",
    },
    {
      field: "upload",
      type: "text",
      name: "pollImage",
      label: "Poll image",
      rules: {
        validate: {
          fileValidation: validateFileSize("pollImage"),
        },
      },
    },
    {
      field: "checkbox",
      name: "topicIds",
      label: "Admin feed item topics",
      options: adminFeedTopics.map((topic) => ({ value: topic.id, label: topic.name })),
      rules: { required: "Please choose at least one topic" },
    },
  ] as FormFieldOptions[];
};
