import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useAdminCreateLessonMutation, useCoursesQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { validateNumeric } from "../../validators/validateNumeric";

type CreateLessonData = {
  createLessonCourseId: string;
  createLessonTitle: string;
  createLessonDescription: string;
  createLessonBackground: string;
  createLessonOrderWeight: string;
  createLessonDurationSeconds: string;
  createLessonVideoVimeoId: string;
  createLessonIsFree: "true" | "false";
};

export const CreateLessonModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const [adminCreateLesson, adminCreateLessonResult] = useAdminCreateLessonMutation();
  const { data: coursesData } = useCoursesQuery();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "createLessonCourseId",
        label: "Course",
        options: coursesData
          ? coursesData.admin.courses.courses.map((course) => ({ value: course.id, label: course.title }))
          : [],
        rules: { required: "Please choose course to add the lesson to" },
      },
      {
        field: "text",
        type: "text",
        name: "createLessonTitle",
        label: "Title",
        rules: { required: "Please provide title" },
      },
      {
        field: "markdown",
        name: "createLessonDescription",
        label: "Description",
      },
      {
        field: "upload",
        type: "text",
        name: "createLessonBackground",
        label: "Background image",
        rules: { required: "Please provide background image" },
      },
      {
        field: "text",
        name: "createLessonDurationSeconds",
        label: "Video duration in seconds",
        rules: {
          required: "Please provide lesson video duration in seconds",
          validate: validateNumeric({ min: 1 }),
        },
      },
      {
        field: "text",
        type: "text",
        name: "createLessonVideoVimeoId",
        label: "Lesson video vimeo id",
        rules: { required: "Please provide a vimeo id of the video" },
      },
      {
        field: "radio",
        name: "createLessonIsFree",
        label: "Is lesson free",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether the lesson is free" },
      },
      {
        field: "text",
        type: "text",
        name: "createLessonOrderWeight",
        label: "Order weight (optional, larger shown later)",
      },
    ],
    [coursesData],
  );

  const onCreateLessonSubmit = useCallback(
    async (data: CreateLessonData) => {
      const backgroundImage = getFileUploadById("createLessonBackground");
      const response = await adminCreateLesson({
        variables: {
          createLessonTitle: data.createLessonTitle,
          createLessonCourseId: data.createLessonCourseId.toString(),
          createLessonDescription: data.createLessonDescription,
          createLessonBackground: backgroundImage,
          createLessonDurationSeconds: parseInt(data.createLessonDurationSeconds),
          createLessonVideoVimeoId: data.createLessonVideoVimeoId,
          createLessonOrderWeight: parseInt(data.createLessonOrderWeight),
          createLessonIsFree: data.createLessonIsFree === "true",
        },
        refetchQueries: ["Lessons"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "lessons",
            page: "lesson",
            id: response.data.adminCreateLesson.id,
          }),
        });
      }
    },
    [adminCreateLesson, navigate],
  );

  return (
    <Modal title="Create new lesson" error={adminCreateLessonResult.error} {...rest}>
      <GeneratedForm
        loading={adminCreateLessonResult.loading}
        error={adminCreateLessonResult.error}
        onSubmit={onCreateLessonSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
