import { JSONSchema7 } from "json-schema";

export const EmptyMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    _: {
      description: "Empty",
      type: "boolean",
    },
  },
  required: ["_"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
