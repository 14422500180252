import { DateTime } from "luxon";
export function getEstimatedGiftCardExpiryDate(purhaseDate: string, giftcardType: String) {
  switch (giftcardType) {
    case "GIFT-YEARLY":
      return DateTime.fromISO(purhaseDate).plus({ years: 1 }).toHTTP();

    case "GIFT-MONTHLY":
      return DateTime.fromISO(purhaseDate).plus({ months: 1 }).toHTTP();
    default:
      return "n/a";
  }
}
