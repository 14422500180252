import React, { useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { CreateAdminFeedTopicMutation, useCreateAdminFeedTopicMutation } from "../../schema";
import { getFileUploadById } from "../../services/getFileUploadById";

type CreateAdminFeedTopicData = {
  name: string;
  isActive: "true" | "false";
  isRecommendation: "true" | "false";
};

export interface CreateAdminFeedTopicModalProps extends ModalProps {
  onCompleted: (data: CreateAdminFeedTopicMutation) => void;
}

export const CreateAdminFeedTopicModal: React.FC<CreateAdminFeedTopicModalProps> = ({ onCompleted, ...rest }) => {
  const [createAdminFeedTopic, createAdminFeedTopicResult] = useCreateAdminFeedTopicMutation({
    refetchQueries: ["AdminFeedTopics"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Topic name",
        rules: { required: "Please provide topic name" },
      },
      {
        field: "upload",
        type: "text",
        name: "icon",
        label: "Topic icon (use square transparent PNG with size of 256x256 or greater)",
        rules: { required: "Please provide topic icon image" },
      },
      {
        field: "radio",
        name: "isActive",
        label: "Is active",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether created topic is initially active" },
        defaultValue: "true",
      },
      {
        field: "radio",
        name: "isRecommendation",
        label: "Is recommendations topic (for ads)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether created topic for recommendations (ads)" },
        defaultValue: "false",
      },
    ],
    [],
  );

  return (
    <Modal title="Create new admin feed topic" error={createAdminFeedTopicResult.error} {...rest}>
      <GeneratedForm
        loading={createAdminFeedTopicResult.loading}
        error={createAdminFeedTopicResult.error}
        onSubmit={(data: CreateAdminFeedTopicData) =>
          createAdminFeedTopic({
            variables: {
              ...data,
              icon: getFileUploadById("icon"),
              isActive: data.isActive === "true",
              isRecommendation: data.isRecommendation === "true",
            },
          })
        }
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
