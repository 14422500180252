import React, { useState } from "react";
import { FieldError } from "react-hook-form";
import { Flex } from "../Flex/Flex";
import { GradientPicker, GradientProps } from "../GradientPicker/GradientPicker";
import styles from "./GradientPickerField.module.scss";

export interface GradientPickerFieldProps {
  name: string;
  label?: string;
  error?: FieldError;
  defaultValue: GradientProps;
  onColorChange?(value: GradientProps): void;
}

export const GradientPickerField: React.FC<GradientPickerFieldProps> = ({
  name,
  label,
  defaultValue,
  onColorChange,
  ...rest
}) => {
  const [gradient, setGradient] = useState(defaultValue);

  const handleValueChange = (value: GradientProps) => {
    setGradient(value);

    if (onColorChange) {
      onColorChange(value);
    }
  };

  return (
    <Flex column>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <GradientPicker gradient={gradient} onColorChange={handleValueChange} {...rest} />
    </Flex>
  );
};
