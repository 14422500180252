import React, { useMemo, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { AttachPaymentModal } from "../../modals/AttachPaymentModal/AttachPaymentModal";
import { DetachPaymentModal } from "../../modals/DetachPaymentModal/DetachPaymentModal";
import { RejectPaymentModal } from "../../modals/RejectPaymentModal/RejectPaymentModal";
import { ResolvePaymentModal } from "../../modals/ResolvePaymentModal/ResolvePaymentModal";
import { AdminViewParams } from "../../routes";
import { AdminPaymentByIdQueryResult, PaymentStatusEnum, useAdminPaymentByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminPaymentDetailsInfo } from "./AdminPaymentDetailsInfo";

export interface AdminPaymentDetailsProps extends AdminViewProps {
  adminPayment: NonNullable<AdminPaymentByIdQueryResult["data"]>["admin"]["adminPayment"];
}

export const AdminPaymentDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();
  const [isResolvePaymentModalOpen, setIsResolvePaymentModalOpen] = useState(false);
  const [isRejectPaymentModalOpen, setIsRejectPaymentModalOpen] = useState(false);
  const [isAttachPaymentModalOpen, setIsAttachPaymentModalOpen] = useState(false);
  const [isDetachPaymentModalOpen, setIsDetachPaymentModalOpen] = useState(false);

  // fetch info
  const { data, loading, error } = useAdminPaymentByIdQuery({
    variables: {
      adminPaymentId: id ?? "",
    },
  });

  const adminPayment = data?.admin.adminPayment;

  const actions: Action[] = useMemo(() => {
    if (!adminPayment) {
      return [];
    }

    return [
      (adminPayment.status === PaymentStatusEnum.COMPLETED || adminPayment.status === PaymentStatusEnum.ISSUE) &&
      !adminPayment.isUsed
        ? {
            label: "Attach",
            onClick: () => setIsAttachPaymentModalOpen(true),
          }
        : null,
      adminPayment.attachedUserId
        ? {
            label: "Detach",
            onClick: () => setIsDetachPaymentModalOpen(true),
          }
        : null,
      adminPayment.status === PaymentStatusEnum.STARTED || adminPayment.status === PaymentStatusEnum.ISSUE
        ? {
            label: "Resolve",
            onClick: () => setIsResolvePaymentModalOpen(true),
          }
        : null,
      adminPayment.status === PaymentStatusEnum.STARTED || adminPayment.status === PaymentStatusEnum.ISSUE
        ? {
            label: "Reject",
            onClick: () => setIsRejectPaymentModalOpen(true),
          }
        : null,
    ];
  }, [adminPayment]);

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  // handle loading
  if (loading || !adminPayment) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <Header actions={actions} navigation={[]}>
        <Title>Payment session "{adminPayment.paymentSessionId ?? ""}"</Title>
      </Header>

      <ResolvePaymentModal
        open={isResolvePaymentModalOpen}
        payment={adminPayment}
        onClickOutside={() => setIsResolvePaymentModalOpen(false)}
        onCancel={() => setIsResolvePaymentModalOpen(false)}
      />

      <RejectPaymentModal
        open={isRejectPaymentModalOpen}
        payment={adminPayment}
        onClickOutside={() => setIsRejectPaymentModalOpen(false)}
        onCancel={() => setIsRejectPaymentModalOpen(false)}
      />

      <AttachPaymentModal
        payment={adminPayment}
        open={isAttachPaymentModalOpen}
        onClickOutside={() => setIsAttachPaymentModalOpen(false)}
      />
      <DetachPaymentModal
        payment={adminPayment}
        open={isDetachPaymentModalOpen}
        onClickOutside={() => setIsDetachPaymentModalOpen(false)}
      />

      <GridBox />
      <Routes>
        <Route index element={<AdminPaymentDetailsInfo adminPayment={adminPayment} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
