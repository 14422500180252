import { gql } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Avatar } from "../../components/Avatar/Avatar";
import { Column } from "../../components/Column/Column";
import { FlexProps } from "../../components/Flex/Flex";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { LoginAsUserModal } from "../../components/LoginAsUserModal/LoginAsUserModal";
import { Modal } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { Row } from "../../components/Row/Row";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { SendResetPasswordEmailModal } from "../../modals/SendResetPasswordEmailModal/SendResetPasswordEmailModal";
import { SendUserPushNotificationModal } from "../../modals/SendUserPushNotificationModal/SendUserPushNotificationModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  useAdminUpdateUserStatusMutation,
  UserByIdQueryResult,
  UserScopeEnum,
  UserStatusEnum,
  useSendPasswordResetEmailMutation,
  useUserByIdQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { UserDetailsEdit } from "./UserDetailsEdit";
import { UserDetailsInfo } from "./UserDetailsInfo";

// fetch admin user by id
gql`
  query UserById($userId: ID!) {
    admin {
      user(userId: $userId) {
        ...AdminUserInfo
        firebaseMessagingTokens {
          id
          token
          createdDate
        }
      }
    }
  }
`;

// update user status
gql`
  mutation AdminUpdateUserStatus($userIds: [ID!]!, $status: UserStatusEnum!) {
    adminUpdateUserStatus(userIds: $userIds, status: $status) {
      ...AdminUserInfo
    }
  }
`;

// mark as test user
gql`
  mutation AdminMarkUsersAsTestUsers($userIds: [ID!]!, $isTestUser: Boolean!) {
    adminMarkUsersAsTestUsers(userIds: $userIds, isTestUser: $isTestUser) {
      ...AdminUserInfo
    }
  }
`;

// login as user
gql`
  mutation AdminLoginAsUser($userId: ID!) {
    adminLoginAsUser(userId: $userId) {
      ...AdminUserInfo
    }
  }
`;

// update user avatar
gql`
  mutation AdminUpdateUserAvatar($userId: ID!, $avatar: Upload!) {
    adminUpdateUserAvatar(userId: $userId, avatar: $avatar) {
      ...AdminUserInfo
    }
  }
`;

// UserById graphql query user response
type UserByIdQueryUserInfo = NonNullable<UserByIdQueryResult["data"]>["admin"]["user"];

export interface UserDetailsViewProps {
  viewer: ViewerInfo;
}

export const UserDetailsView: React.FC<UserDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  // load user info
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useUserByIdQuery({
    variables: {
      userId: params.id ?? "",
    },
  });

  // get user info
  const user = userData?.admin.user;

  // handle error
  if (userError) {
    return <ErrorView title="Loading user info failed" error={userError} />;
  }

  // handle loading
  if (userLoading || !user) {
    return <LoadingView />;
  }

  // render user details, the view is split into subview components
  return (
    <View pad="half">
      <UserDetailsHeader user={user} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<UserDetailsInfo user={user} viewer={viewer} />} />
        <Route path="edit" element={<UserDetailsEdit user={user} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

// props passed to subviews
export interface UserProps extends FlexProps {
  user: UserByIdQueryUserInfo;
  viewer: ViewerInfo;
}

// header that contains user main info and action buttons, always shown
export const UserDetailsHeader: React.FC<UserProps> = ({ user, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const setToast = useSetRecoilState(toastState);
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  const [showConfirmLoginAsModal, setShowConfirmLoginAsModal] = useState(false);
  const [showSendUserNotificationModal, setShowSendUserNotificationModal] = useState(false);
  const [showConfirmSendPasswordResetModal, setShowConfirmSendPasswordResetModal] = useState(false);

  // detect edit mode
  const isEditMode = modifier === "edit";

  // update user status
  const [adminUpdateUserStatus, adminUpdateUserStatusResult] = useAdminUpdateUserStatusMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
  });
  const [sendPasswordResetEmail, sendPasswordResetEmailResult] = useSendPasswordResetEmailMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onError: (error) => {
      setShowConfirmSendPasswordResetModal(false);
      console.log({ error });
      setToast({
        type: ToastType.ERROR,
        message: `We were unable to send the given password reset email to the user, check errors`,
        isOpen: true,
      });
    },
    onCompleted: (_data) => {
      setShowConfirmSendPasswordResetModal(false);
      // show success toast message
      setToast({
        type: ToastType.SUCCESS,
        message: `Password reset email was sent to ${user.email}`,
        isOpen: true,
      });
    },
  });

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      user.status === UserStatusEnum.ACTIVE
        ? {
            label: "Reset password",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_USERS,
              UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
              UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
            ],
            loading: sendPasswordResetEmailResult.loading,
            onClick: () => {
              setShowConfirmSendPasswordResetModal(true);
            },
          }
        : null,
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "users",
                  page: "user",
                  id: user.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_USERS,
              UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "users",
                  page: "user",
                  id: user.id,
                  modifier: "edit",
                }),
              }),
          },
      // TODO: this might make sense again once we build a web interface where the user can log in
      // user.roles.length === 1 && user.roles[0] === UserRoleEnum.USER
      //   ? {
      //       label: "Login as",
      //       authorizedScopes: [
      //         UserScopeEnum.SUPERADMIN,
      //         UserScopeEnum.ADMIN_USERS,
      //         UserScopeEnum.ADMIN_USERS_LOGIN_AS_USER,
      //       ],
      //       onClick: () => setShowConfirmLoginAsModal(true),
      //     }
      //   : null,
      user.status !== UserStatusEnum.ACTIVE
        ? {
            label: "Activate",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_USERS,
              UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
              UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
            ],
            loading: adminUpdateUserStatusResult.loading,
            onClick: () =>
              adminUpdateUserStatus({
                variables: {
                  userIds: [user.id],
                  status: UserStatusEnum.ACTIVE,
                },
              }),
          }
        : null,
      user.status !== UserStatusEnum.DISABLED
        ? {
            label: "Disable",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_USERS,
              UserScopeEnum.ADMIN_USERS_UPDATE_INFO,
              UserScopeEnum.ADMIN_USERS_UPDATE_STATUS,
            ],
            onClick: () => setShowConfirmDisableModal(true),
          }
        : null,
    ],
    [
      adminUpdateUserStatus,
      adminUpdateUserStatusResult.loading,
      isEditMode,
      navigate,
      user.id,
      user.status,
      setShowConfirmSendPasswordResetModal,
      sendPasswordResetEmailResult,
    ],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      {
        label: "Reviews",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_REVIEWS, UserScopeEnum.ADMIN_REVIEWS_LIST],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "track-reviews" }),
            search: getUrlSearchParamsString({
              authorEmail: user.email,
            }),
          }),
      },
    ],
    [navigate, user.email],
  );

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Row>
          <Avatar image={user.avatarUrl} />
          <Column padLeft={2} mainAxisAlignment="center">
            <Title>{user.name}</Title>
            <P small>{user.email}</P>
          </Column>
        </Row>
      </Header>

      <Modal
        title="Confirm disable user"
        actions={[
          {
            label: "Cancel",
            onClick: () => setShowConfirmDisableModal(false),
          },
          {
            label: "Disable user",
            danger: true,
            loading: adminUpdateUserStatusResult.loading,
            onClick: async () => {
              const result = await adminUpdateUserStatus({
                variables: {
                  userIds: [user.id],
                  status: UserStatusEnum.DISABLED,
                },
              });

              if (result.data) {
                setShowConfirmDisableModal(false);
              }
            },
          },
        ]}
        open={showConfirmDisableModal}
        onClickOutside={() => setShowConfirmDisableModal(false)}
      >
        <P center>
          Are you sure you want to disable user <strong>{user.name}</strong>?
        </P>
        <P center padTop="half">
          The disabled user is logged out and is unable to log back in.
        </P>
        <P center padTop="half">
          The user can be re-activated in the future.
        </P>
      </Modal>

      <SendUserPushNotificationModal
        user={user}
        open={showSendUserNotificationModal}
        onClickOutside={() => setShowSendUserNotificationModal(false)}
      />

      <LoginAsUserModal user={user} open={showConfirmLoginAsModal} onCancel={() => setShowConfirmLoginAsModal(false)} />

      <SendResetPasswordEmailModal
        email={user.email}
        open={showConfirmSendPasswordResetModal}
        onCancel={() => setShowConfirmSendPasswordResetModal(false)}
        onClickOutside={() => setShowConfirmSendPasswordResetModal(false)}
        loading={sendPasswordResetEmailResult.loading}
        error={sendPasswordResetEmailResult.error}
        onSubmit={async () => {
          await sendPasswordResetEmail({
            variables: {
              email: user.email,
            },
          });
        }}
      />
    </>
  );
};
