import { JSONSchema7 } from "json-schema";

export const FactMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    factImageUrl: {
      description: "Quote image URL",
      type: "string",
    },
    factText: {
      description: "Fact text to show",
      type: "string",
    },
  },
  required: ["factImageUrl", "factText"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
