import { sentenceCase } from "change-case";
import React, { useCallback } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  UserScopeEnum,
  useAdminFeedItemsQuery,
  ConditionModeEnum,
  MatchModeEnum,
  AdminFeedItemTypeEnum,
  AdminFeedItemAudienceTypeEnum,
  AdminFeedItemStatusEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { formatDate } from "../../services/formatDate";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface AdminFeedItemsFilterData extends FilterBaseData {
  adminFeedItemId: string;
  type: AdminFeedItemTypeEnum[];
  audience: AdminFeedItemAudienceTypeEnum[];
  startDate: Date | null;
  endDate: Date | null;
  startDay: number | null;
  endDay: number | null;
  isPinned: boolean | null;
}

export const AdminFeedItemListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  // parse filter url parameters
  const params = useUrlParams<AdminFeedItemsFilterData>((params) => ({
    adminFeedItemId: params.adminFeedItemId ?? "",
    type: params.type ? (params.type.split(",") as AdminFeedItemTypeEnum[]) : [],
    audience: params.audience ? (params.audience.split(",") as AdminFeedItemAudienceTypeEnum[]) : [],
    startDate: params.startDate ? new Date(params.startDate) : null,
    endDate: params.endDate ? new Date(params.endDate) : null,
    startDay: params.startDay ? parseInt(params.startDay, 10) : null,
    endDay: params.endDay ? parseInt(params.endDay, 10) : null,
    isPinned: params.isPinned !== undefined ? (params.isPinned === "true" ? true : false) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  // fetch items
  const { data, loading, error } = useAdminFeedItemsQuery({
    fetchPolicy: "network-only",
    variables: {
      filter: {
        adminFeedItemId: params.adminFeedItemId.length > 0 ? params.adminFeedItemId : undefined,
        type: params.type.length > 0 ? params.type : undefined,
        audience: params.audience.length > 0 ? params.audience : undefined,
        startDate: params.startDate ? formatApiDate(params.startDate) : undefined,
        endDate: params.endDate ? formatApiDate(params.endDate) : undefined,
        startDay: params.startDay?.toString() ?? undefined,
        endDay: params.endDay?.toString() ?? undefined,
        isPinned: params.isPinned ?? undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  // get items
  const adminFeedItems = useMemo(
    () => data?.admin.adminFeedItems.adminFeedItems ?? [],
    [data?.admin.adminFeedItems.adminFeedItems],
  );

  const total = data?.admin.adminFeedItems.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "adminFeedItemId",
        label: "Item id",
        defaultValue: params.adminFeedItemId,
      },
      {
        field: "checkbox",
        name: "type",
        label: "Type",
        options: getEnumFormOptions(AdminFeedItemTypeEnum),
        defaultValue: params.type,
      },
      {
        field: "checkbox",
        name: "audience",
        label: "Audience",
        options: getEnumFormOptions(AdminFeedItemAudienceTypeEnum),
        defaultValue: params.audience,
      },
      {
        field: "date",
        name: "startDate",
        label: "Show start date",
        defaultValue: params.startDate,
      },
      {
        field: "date",
        name: "endDate",
        label: "Show end date",
        defaultValue: params.endDate,
      },
      {
        field: "text",
        type: "text",
        name: "startDay",
        label: "Show start day",
        defaultValue: params.startDay !== null ? params.startDay.toString() : "",
      },
      {
        field: "text",
        type: "text",
        name: "endDay",
        label: "Show end day",
        defaultValue: params.endDay !== null ? params.endDay.toString() : "",
      },
      {
        field: "radio",
        name: "isPinned",
        label: "Is pinned",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.isPinned !== null ? (params.isPinned ? "true" : "false") : null,
      },
    ],
    [
      params.adminFeedItemId,
      params.type,
      params.audience,
      params.startDate,
      params.endDate,
      params.startDay,
      params.endDay,
      params.isPinned,
    ],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Type",
      },
      {
        label: "Audience",
      },
      {
        label: "Start date",
      },
      {
        label: "End date",
      },
      {
        label: "Start day",
      },
      {
        label: "End day",
      },
      {
        label: "Is pinned",
      },
      {
        label: "Is recommended",
      },
      {
        label: "Status",
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      adminFeedItems.map((adminFeedItem) => {
        return {
          id: adminFeedItem.id,
          cells: [
            {
              content: sentenceCase(adminFeedItem.type),
            },
            {
              content: adminFeedItem.audience.map((audience) => sentenceCase(audience)).join(", "),
            },
            {
              content: formatDate(adminFeedItem.startDate),
            },
            {
              content: formatDate(adminFeedItem.endDate),
            },
            {
              content: adminFeedItem.startDay ?? "n/a",
            },
            {
              content: adminFeedItem.endDay ?? "n/a",
            },
            {
              content: adminFeedItem.isPinned ? "Yes" : "No",
            },
            {
              content: adminFeedItem.isRecommended ? "Yes" : "No",
            },
            {
              content: adminFeedItem.status.toString(),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_FEED,
                UserScopeEnum.ADMIN_FEED_ITEM_INFO,
              ],
              onClick: (adminFeedItemId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "feed-items",
                    page: "items",
                    id: adminFeedItemId,
                  }),
                }),
            },
            adminFeedItem.status !== AdminFeedItemStatusEnum.APPROVED
              ? {
                  label: "Edit",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMIN_FEED,
                    UserScopeEnum.ADMIN_FEED_UPDATE_ITEM,
                  ],
                  onClick: (adminFeedItemId) =>
                    navigate({
                      pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                        menu: "feed-items",
                        page: "items",
                        id: adminFeedItemId,
                        modifier: "edit",
                      }),
                    }),
                }
              : null,
          ],
        };
      }),
    [navigate, adminFeedItems],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create item",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_CREATE_ITEM],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "feed-items",
              page: "items",
              modifier: "new",
            }),
          }),
      },
    ],
    [navigate],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: AdminFeedItemsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-items" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching admin feed items failed" error={error} />;
  }

  // render view
  return (
    <>
      <View scrollable>
        <Filter
          title="Admin feed items"
          fields={filters}
          actions={viewActions}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_FEED,
            UserScopeEnum.ADMIN_FEED_ITEM_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "feed-items",
                page: "items",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "feed-items" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
    </>
  );
};
