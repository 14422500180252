import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";

export interface SendResetPasswordEmailModalProps extends ModalProps {
  email: string;
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  loading: boolean;
}

export const SendResetPasswordEmailModal: React.FC<SendResetPasswordEmailModalProps> = ({
  email,
  onSubmit,
  onCancel,
  loading,
  ...rest
}) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      loading: loading,
      onClick: onSubmit,
    },
  ];
  return (
    <Modal actions={modalActions} title="Send reset password link" {...rest}>
      <P center>Send a reset password link to {email}?</P>
    </Modal>
  );
};
