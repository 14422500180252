import { gql } from "@apollo/client";
import React, { useCallback, useMemo } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  AppReview,
  AppReviewByIdQueryResult,
  CollaborationTypeEnum,
  useAdminUpdateAppReviewInfoMutation,
  useAppReviewByIdQuery,
  useCategoriesQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getAppReviewNameValueList } from "../../services/getAppReviewNameValueList";
import { getCategoryNameValueList } from "../../services/getCategoryNameValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { validateNumeric } from "../../validators/validateNumeric";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query AppReviewById($appReviewId: ID!) {
    admin {
      appReview(appReviewId: $appReviewId) {
        ...AppReviewInfo
        category {
          ...CategoryInfo
        }
      }
    }
  }
`;

gql`
  mutation AdminUpdateAppReviewInfo(
    $appReviewId: ID!
    $categoryId: ID!
    $review: String!
    $rating: Float!
    $authorName: String!
    $authorTitle: String!
    $authorAvatar: Upload
    $comment: String!
  ) {
    adminUpdateAppReviewInfo(
      appReviewId: $appReviewId
      categoryId: $categoryId
      review: $review
      rating: $rating
      authorName: $authorName
      authorTitle: $authorTitle
      authorAvatar: $authorAvatar
      comment: $comment
    ) {
      ...AppReviewInfo
      category {
        ...CategoryInfo
      }
    }
  }
`;

type AppReviewByIdQueryReviewInfo = NonNullable<AppReviewByIdQueryResult["data"]>["admin"]["appReview"];

export interface AppReviewDetailsViewProps {
  viewer: ViewerInfo;
}

export const AppReviewDetailsView: React.FC<AppReviewDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: appReviewData,
    loading: appReviewLoading,
    error: appReviewError,
  } = useAppReviewByIdQuery({
    variables: {
      appReviewId: params.id ?? "",
    },
  });

  const appReview = appReviewData?.admin.appReview;

  // handle error
  if (appReviewError) {
    return <ErrorView title="Loading app review info failed" error={appReviewError} />;
  }

  // handle loading
  if (appReviewLoading || !appReview) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <AppReviewDetailsHeader appReview={appReview} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<AppReviewDetailsInfo appReview={appReview} viewer={viewer} />} />
        <Route path="edit" element={<AppReviewDetailsEdit appReview={appReview} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface appReviewProps extends FlexProps {
  appReview: AppReviewByIdQueryReviewInfo;
  viewer: ViewerInfo;
}

export const AppReviewDetailsHeader: React.FC<appReviewProps> = ({ appReview, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const { category } = appReview;
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "app-reviews",
                  page: "app-review",
                  id: appReview.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_APP_REVIEWS,
              UserScopeEnum.ADMIN_APP_REVIEWS_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "app-reviews",
                  page: "app-review",
                  id: appReview.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [appReview.id, isEditMode, navigate],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      {
        label: "Category",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_CATEGORIES,
          UserScopeEnum.ADMIN_CATEGORIES_INFO,
        ],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "categories",
              page: "category",
              modifier: category?.id,
            }),
          }),
      },
    ],
    [category?.id, navigate],
  );

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>App review details</Title>
      </Header>
    </>
  );
};

export const AppReviewDetailsInfo: React.FC<appReviewProps> = ({ appReview, viewer }) => {
  const navigate = useNavigate();

  const { category } = appReview;

  const appReviewDetails = getAppReviewNameValueList(appReview as AppReview);
  const categoryDetails = getCategoryNameValueList(category!);

  return (
    <WithCollaboration referenceId={appReview.id} type={CollaborationTypeEnum.REVIEW} viewer={viewer}>
      <Title>App review</Title>
      <NameValueList items={appReviewDetails} />

      <Title
        marginTop
        actions={[
          {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "categories",
                  page: "category",
                  id: category?.id,
                }),
              }),
          },
        ]}
      >
        Category
      </Title>

      <NameValueList items={categoryDetails} />
    </WithCollaboration>
  );
};

interface appReviewData {
  categoryId: string;
  review: string;
  rating: string;
  authorName: string;
  authorTitle: string;
  authorAvatar: string;
  comment: string;
}

export const AppReviewDetailsEdit: React.FC<appReviewProps> = ({ appReview, viewer, ...rest }) => {
  const navigate = useNavigate();

  const { data: categoriesData } = useCategoriesQuery();

  const [adminUpdateAppReviewInfo, adminUpdateAppReviewInfoResult] = useAdminUpdateAppReviewInfoMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "categoryId",
        label: "Category",
        options: categoriesData
          ? categoriesData.admin.categories.categories.map((category) => ({
              value: category.id,
              label: category.title,
            }))
          : [],
        defaultValue: appReview.category?.id,
      },
      {
        field: "markdown",
        name: "review",
        label: "Review",
        defaultValue: appReview.review ?? "",
      },
      {
        field: "text",
        name: "rating",
        label: "Rating",
        defaultValue: appReview.rating.toFixed(1).toString(),
        rules: {
          required: "Please provide rating",
          validate: validateNumeric({ min: 1, max: 5 }),
        },
      },
      {
        field: "text",
        type: "text",
        name: "authorName",
        label: "Author name",
        defaultValue: appReview.authorName,
      },
      {
        field: "text",
        type: "text",
        name: "authorTitle",
        label: "Author title",
        defaultValue: appReview.authorTitle,
      },
      {
        field: "upload",
        type: "text",
        name: "authorAvatar",
        label: "Author image (leave blank to not change)",
      },
      {
        field: "text",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated app review info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
    ],
    [
      appReview.review,
      appReview.category?.id,
      appReview.rating,
      appReview.authorName,
      appReview.authorTitle,
      categoriesData,
    ],
  );

  const onSubmit = useCallback(
    async (data: appReviewData) => {
      const authorAvatar = getFileUploadById("authorAvatar");

      const response = await adminUpdateAppReviewInfo({
        variables: {
          ...data,
          appReviewId: appReview.id,
          categoryId: data.categoryId,
          review: data.review,
          rating: parseFloat(data.rating),
          authorName: data.authorName,
          authorTitle: data.authorTitle,
          authorAvatar: authorAvatar,
        },
        refetchQueries: ["CollaborationById", "appReviewById"],
        awaitRefetchQueries: true,
      });

      // redirect to details (non-edit mode) when successfully updated
      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "app-reviews",
            page: "app-review",
            id: appReview.id,
          }),
        });
      }
    },
    [adminUpdateAppReviewInfo, appReview.id, navigate],
  );

  return (
    <WithCollaboration referenceId={appReview.id} type={CollaborationTypeEnum.USER} viewer={viewer} {...rest}>
      <GeneratedForm
        title="App review info"
        error={adminUpdateAppReviewInfoResult.error}
        loading={adminUpdateAppReviewInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
