import { onError } from "@apollo/client/link/error";

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      console.warn(`[GraphQL error] ${graphQLError.message}`, graphQLError);
    }
  }

  if (networkError) {
    // status code and response may actually exist on ServerParseError and ServerError
    const statusCode = (networkError as any).statusCode as number | undefined;
    const response = (networkError as any).response as Response | undefined;

    if (networkError.name === "ServerParseError") {
      console.warn("The server seems to be down or unreachable", networkError);
    } else {
      console.warn(`[GraphQL network] ${networkError}`, response, statusCode);
    }
  }
});
