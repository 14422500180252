import { gql } from "@apollo/client";
import { paramCase, sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateArticleModal } from "../../modals/CreateArticleModal/CreateArticleModal";
import { DeleteArticleModal } from "../../modals/DeleteArticleModal/DeleteArticleModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  LocaleEnum,
  MatchModeEnum,
  useAdminDeleteArticleMutation,
  useArticlesQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { limitTextLength } from "../../services/limitTextLength";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query Articles($filter: AdminArticlesFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      articles(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        articles {
          ...ArticleInfo
          author {
            ...AuthorInfo
          }
          labels {
            ...LabelInfo
          }
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteArticle($articleIds: [ID!]!) {
    adminDeleteArticle(articleIds: $articleIds) {
      id
    }
  }
`;

interface ArticlesFilterData extends FilterBaseData {
  articleId: string;
  title: string;
  description: string;
  authorName: string;
  locale: LocaleEnum[];
}

export const ArticlesListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  const params = useUrlParams<ArticlesFilterData>((params) => ({
    articleId: params.articleId ?? "",
    title: params.title ?? "",
    authorName: params.authorName ?? "",
    description: params.description ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    locale: params.locale ? (params.locale.split(",") as LocaleEnum[]) : [],
  }));

  const {
    data: articlesData,
    loading: articlesLoading,
    error: articlesError,
  } = useArticlesQuery({
    variables: {
      filter: {
        authorName: params.authorName.length > 0 ? params.authorName : undefined,
        articleId: params.articleId.length > 0 ? params.articleId : undefined,
        title: params.title.length > 0 ? params.title : undefined,
        description: params.description.length > 0 ? params.description : undefined,
        locale: params.locale.length > 0 ? params.locale : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const [isCreateArticleModalOpen, setIsCreateArticleModalOpen] = useState(false);
  const [isDeleteArticleModalOpen, setIsDeleteArticleModalOpen] = useState(false);
  const [confirmDeleteArticleIds, setConfirmDeleteArticleIds] = useState<string | string[]>([]);
  const [adminDeleteArticle, adminDeleteArticleResult] = useAdminDeleteArticleMutation();

  // pagination info
  const articles = useMemo(() => articlesData?.admin.articles.articles ?? [], [articlesData?.admin.articles.articles]);

  const total = articlesData?.admin.articles.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "articleId",
        label: "Article id",
        defaultValue: params.articleId,
      },
      // TODO: find a solution to default value translations
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: params.title,
      },
      {
        field: "text",
        type: "text",
        name: "description",
        label: "Description",
        defaultValue: params.description,
      },
      {
        field: "text",
        type: "text",
        name: "authorName",
        label: "Author name",
        defaultValue: params.authorName,
      },
      {
        field: "checkbox",
        name: "locale",
        label: "Locale",
        options: Object.keys(LocaleEnum).map((localeType) => {
          return { value: localeType, label: sentenceCase(localeType) };
        }),
        defaultValue: params.locale,
      },
    ],
    [params.authorName, params.articleId, params.description, params.locale, params.title],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Title",
      },
      {
        label: "Text",
      },
      {
        label: "Author",
      },
      {
        label: "Locale",
      },
      {
        label: "Labels",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      articles.map((article) => {
        const { author } = article;
        return {
          id: article.id,
          cells: [
            {
              content: article.title,
            },
            {
              content: limitTextLength(article.description),
            },
            {
              content: author.name,
              linkUrl: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "authors",
                page: "author",
                id: author.id,
              }),
            },
            {
              content: article.locale,
            },

            {
              content: article.labels.map((label) => label.title).join(", "),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_ARTICLES,
                UserScopeEnum.ADMIN_ARTICLES_INFO,
              ],
              onClick: (articleId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "articles",
                    page: "article",
                    id: articleId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_ARTICLES,
                UserScopeEnum.ADMIN_ARTICLES_UPDATE_INFO,
              ],
              onClick: (articleId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "articles",
                    page: "article",
                    id: articleId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Delete",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_ARTICLES,
                UserScopeEnum.ADMIN_ARTICLES_DELETE,
              ],
              onClick: (articleIds) => {
                setConfirmDeleteArticleIds(articleIds);
                setIsDeleteArticleModalOpen(true);
              },
            },
          ],
        };
      }),
    [articles, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_ARTICLES, UserScopeEnum.ADMIN_ARTICLES_DELETE],
        onClick: (articleIds) => {
          setConfirmDeleteArticleIds(articleIds);
          setIsDeleteArticleModalOpen(true);
        },
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create article",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_ARTICLES, UserScopeEnum.ADMIN_ARTICLES_CREATE],
        onClick: () => setIsCreateArticleModalOpen(true),
      },
    ],
    [],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: ArticlesFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "articles" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (articlesError) {
    return <ErrorView title="Fetching articles failed" error={articlesError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Blog articles"
          fields={filters}
          actions={viewActions}
          loading={articlesLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={articlesLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_ARTICLES,
            UserScopeEnum.ADMIN_ARTICLES_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "articles",
                page: "article",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "articles" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />

        <DeleteArticleModal
          open={isDeleteArticleModalOpen}
          onCancel={() => setIsDeleteArticleModalOpen(false)}
          onClickOutside={() => setIsDeleteArticleModalOpen(false)}
          loading={adminDeleteArticleResult.loading}
          error={adminDeleteArticleResult.error}
          onSubmit={async () => {
            const response = await adminDeleteArticle({
              variables: {
                articleIds: confirmDeleteArticleIds,
              },
              refetchQueries: ["Articles"],
              awaitRefetchQueries: true,
            });

            if (response.data) {
              setIsDeleteArticleModalOpen(false);

              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "articles",
                }),
              });
            }
          }}
        />

        <CreateArticleModal open={isCreateArticleModalOpen} onClickOutside={() => setIsCreateArticleModalOpen(false)} />
      </View>
    </>
  );
};
