export const validateFileSize = (fieldName: string) => (_field: any) => {
  const fileInputElement = document.getElementById(fieldName) as HTMLInputElement | null;

  const files = fileInputElement?.files;

  if (!files || files.length === 0) {
    return true;
  }

  if (files[0].size > 5000000) {
    return `File size cannot be more than 5MB`;
  }

  return true;
};
