import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { CollaborationTypeEnum } from "../../schema";
import { getUserNameValueList } from "../../services/getUserNameValueList";
import { UserProps } from "./UserDetailsView";

// user details when not in edit mode
export const UserDetailsInfo: React.FC<UserProps> = ({ user, viewer }) => {
  const userDetails = getUserNameValueList(user);

  return (
    <WithCollaboration referenceId={user.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
      <Title>Profile{user.isTestUser ? " (Test Account)" : null}</Title>
      <NameValueList items={userDetails} />
    </WithCollaboration>
  );
};
