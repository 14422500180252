import { gql } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DeleteLessonModal } from "../../modals/DeleteLessonModal/DeleteLessonModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  CollaborationTypeEnum,
  LessonByIdQueryResult,
  useAdminDeleteLessonMutation,
  useAdminUpdateLessonInfoMutation,
  useCoursesQuery,
  useLessonByIdQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getCourseNameValueList } from "../../services/getCourseNamedValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getLessonNamedValueList } from "../../services/getLessonNamedValueList";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query LessonById($lessonId: ID!) {
    admin {
      lesson(lessonId: $lessonId) {
        ...LessonInfo
        course {
          ...CourseInfo
        }
      }
    }
  }
`;

gql`
  mutation AdminDeleteLesson($lessonIds: [ID!]!) {
    adminDeleteLesson(lessonIds: $lessonIds) {
      id
    }
  }
`;

gql`
  mutation AdminUpdateLessonInfo(
    $lessonId: ID!
    $courseId: ID!
    $title: String!
    $description: String
    $background: Upload
    $durationSeconds: Int!
    $videoVimeoId: String!
    $orderWeight: Int!
    $isFree: Boolean!
    $comment: String!
  ) {
    adminUpdateLessonInfo(
      lessonId: $lessonId
      courseId: $courseId
      title: $title
      description: $description
      background: $background
      durationSeconds: $durationSeconds
      videoVimeoId: $videoVimeoId
      orderWeight: $orderWeight
      isFree: $isFree
      comment: $comment
    ) {
      ...LessonInfo
    }
  }
`;

type LessonByIdQueryLessonInfo = NonNullable<LessonByIdQueryResult["data"]>["admin"]["lesson"];

export interface LessonDetailsViewProps {
  viewer: ViewerInfo;
}

export const LessonDetailsView: React.FC<LessonDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: lessonData,
    loading: lessonLoading,
    error: lessonError,
  } = useLessonByIdQuery({
    variables: {
      lessonId: params.id ?? "",
    },
  });

  const lesson = lessonData?.admin.lesson;

  if (lessonError) {
    return <ErrorView title="Loading lesson info failed" error={lessonError} />;
  }

  if (lessonLoading || !lesson) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <LessonDetailsHeader lesson={lesson} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<LessonDetailsInfo lesson={lesson} viewer={viewer} />} />
        <Route path="edit" element={<LessonDetailsEdit lesson={lesson} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface LessonProps extends FlexProps {
  lesson: LessonByIdQueryLessonInfo;
  viewer: ViewerInfo;
}

export const LessonDetailsHeader: React.FC<LessonProps> = ({ lesson, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const [isDeleteLessonModalOpen, setIsDeleteLessonModalOpen] = useState(false);
  const [adminDeleteLesson, adminDeleteLessonResult] = useAdminDeleteLessonMutation();
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      {
        label: "Delete",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_LESSONS, UserScopeEnum.ADMIN_LESSONS_DELETE],
        onClick: () => {
          setIsDeleteLessonModalOpen(true);
        },
      },
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "lessons",
                  page: "lesson",
                  id: lesson.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_LESSONS,
              UserScopeEnum.ADMIN_LESSONS_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "lessons",
                  page: "lesson",
                  id: lesson.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, lesson.id, navigate],
  );

  const { course } = lesson;

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      {
        label: "Course",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_COURSES, UserScopeEnum.ADMIN_COURSES_INFO],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "courses",
              page: "course",
              modifier: course.id,
            }),
          }),
      },
    ],
    [course.id, navigate],
  );

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Lesson details</Title>
      </Header>

      <DeleteLessonModal
        open={isDeleteLessonModalOpen}
        onCancel={() => setIsDeleteLessonModalOpen(false)}
        onClickOutside={() => setIsDeleteLessonModalOpen(false)}
        loading={adminDeleteLessonResult.loading}
        error={adminDeleteLessonResult.error}
        onSubmit={async () => {
          const response = await adminDeleteLesson({
            variables: {
              lessonIds: lesson.id,
            },
          });

          if (response.data) {
            setIsDeleteLessonModalOpen(false);

            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "lessons",
              }),
            });
          }
        }}
      />
    </>
  );
};

export const LessonDetailsInfo: React.FC<LessonProps> = ({ lesson, viewer }) => {
  const navigate = useNavigate();
  const { course } = lesson;

  return (
    <WithCollaboration referenceId={lesson.id} type={CollaborationTypeEnum.LESSON} viewer={viewer}>
      <Title>Lesson</Title>
      <NameValueList items={getLessonNamedValueList(lesson)} />

      <Title
        marginTop
        actions={[
          {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "courses",
                  page: "course",
                  id: course.id,
                }),
              }),
          },
        ]}
      >
        Course
      </Title>
      <NameValueList items={getCourseNameValueList(course)} />
    </WithCollaboration>
  );
};

type EditlessonData = {
  title: string;
  description: string;
  background: string;
  courseId: string;
  orderWeight: string;
  comment: string;
  durationSeconds: string;
  videoVimeoId: string;
  isFree: "true" | "false";
};

export const LessonDetailsEdit: React.FC<LessonProps> = ({ lesson, viewer, ...rest }) => {
  const navigate = useNavigate();

  const [adminUpdateLessonInfo, adminUpdateLessonInfoResult] = useAdminUpdateLessonInfoMutation();
  const { data: coursesData } = useCoursesQuery();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "courseId",
        label: "Course name",
        options: coursesData
          ? coursesData.admin.courses.courses.map((course) => ({ value: course.id, label: course.title }))
          : [],
        defaultValue: lesson.course.id,
      },
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: lesson.title,
      },

      {
        field: "markdown",
        name: "description",
        label: "Description",
        defaultValue: lesson.description ?? undefined,
      },
      {
        field: "upload",
        type: "text",
        name: "backgroundImage",
        label: "Background image (leave blank to not change)",
      },
      {
        field: "text",
        name: "durationSeconds",
        label: "Duration seconds",
        rules: {
          required: "Please provide duration seconds",
          min: 0,
        },
        defaultValue: lesson.durationSeconds.toString(),
      },
      {
        field: "text",
        type: "text",
        name: "videoVimeoId",
        label: "Lesson vimeo ID",
        rules: { required: "Please provide a vimeo id of the video" },
        defaultValue: lesson.videoVimeoId,
      },
      {
        field: "radio",
        name: "isFree",
        label: "Is Lesson free",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: lesson.isFree ? "true" : "false",
      },
      {
        field: "text",
        type: "text",
        name: "orderWeight",
        label: "Order weight (optional, larger shown later)",
        defaultValue: lesson.orderWeight.toString(),
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated lesson info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
    ],
    [
      coursesData,
      lesson.course.id,
      lesson.title,
      lesson.description,
      lesson.durationSeconds,
      lesson.videoVimeoId,
      lesson.isFree,
      lesson.orderWeight,
    ],
  );

  const onSubmit = useCallback(
    async (data: EditlessonData) => {
      const background = getFileUploadById("backgroundImage");

      const response = await adminUpdateLessonInfo({
        variables: {
          ...data,
          description: data.description.length > 0 ? data.description : null,
          durationSeconds: parseInt(data.durationSeconds),
          videoVimeoId: data.videoVimeoId,
          lessonId: lesson.id,
          courseId: data.courseId.toString(),
          background,
          isFree: data.isFree === "true",
          orderWeight: parseInt(data.orderWeight),
        },
        refetchQueries: ["CollaborationById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "lessons",
            page: "lesson",
            id: lesson.id,
          }),
        });
      }
    },
    [adminUpdateLessonInfo, lesson.id, navigate],
  );

  return (
    <WithCollaboration referenceId={lesson.id} type={CollaborationTypeEnum.LESSON} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Lesson"
        error={adminUpdateLessonInfoResult.error}
        loading={adminUpdateLessonInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
