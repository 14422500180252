import { sentenceCase } from "change-case";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { CategoryInfoFragment } from "../schema";
import { renderTranslationMap } from "./renderTranslationMap";

export function getCategoryNameValueList(category: CategoryInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: category.id,
    },
    {
      name: "Type",
      value: category.type ? sentenceCase(category.type) : "n/a",
    },
    {
      name: "Title",
      value: renderTranslationMap(category.titleTranslations),
    },
    {
      name: "Web Title",
      value: renderTranslationMap(category.altTitleTranslations),
    },
    {
      name: "Description",
      value: renderTranslationMap(category.descriptionTranslations),
    },
    {
      name: "Url name",
      value: category.urlName,
    },
    {
      name: "Labels",
      value: category.labels.length > 0 ? category.labels.map((label) => label.title).join(", ") : "n/a",
    },
    {
      name: "Order weight",
      value: category.orderWeight,
    },
    {
      name: "Age restricted (18+)",
      value: category.isAdultOnly ? "Yes" : "No",
    },
    {
      name: "Vimeo ID of the trailer video",
      value: category.trailerVimeoId ?? "n/a",
    },
    {
      name: "Youtube ID of the trailer video (web)",
      value: category.trailerYoutubeId ?? "n/a",
    },
    {
      name: "Small background",
      value: <img src={category.smallBackgroundImageUrl} alt="Small background" style={{ width: "50rem" }} />,
    },
    {
      name: "Large background",
      value: <img src={category.largeBackgroundImageUrl} alt="Large background" style={{ width: "50rem" }} />,
    },
    {
      name: "Trailer cover background",
      value: !category.trailerCoverBackgroundImageUrl ? (
        "n/a"
      ) : (
        <img src={category.trailerCoverBackgroundImageUrl} alt="Trailer cover background" style={{ width: "50rem" }} />
      ),
    },
  ];
}
