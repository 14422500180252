import { Markdown } from "../components/Markdown/Markdown";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { LessonInfoFragment } from "../schema";

export function getLessonNamedValueList(lesson: LessonInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique ID",
      value: lesson.id,
    },
    {
      name: "Title",
      value: lesson.title,
    },
    {
      name: "Description",
      value: <Markdown>{lesson.description ?? ""}</Markdown>,
    },
    {
      name: "background",
      value: <img src={lesson.backgroundUrl} alt="Small background" style={{ width: "50rem" }} />,
    },
    {
      name: "Lesson duration in seconds",
      value: lesson.durationSeconds,
    },
    {
      name: "Lesson Vimeo ID",
      value: lesson.videoVimeoId,
    },
    {
      name: "Is free",
      value: lesson.isFree ? "yes" : "no",
    },
    {
      name: "Order weight",
      value: lesson.orderWeight,
    },
  ];
}
