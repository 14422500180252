import { ApolloProvider } from "@apollo/client";
import React from "react";
import { RecoilRoot } from "recoil";
import { App } from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Toast } from "./components/Toast/Toast";
import { setupApolloClient } from "./services/setupApolloClient";
import { LoadingView } from "./views/LoadingView/LoadingView";
import "./theme/main.scss";

const apolloClient = setupApolloClient();

export const Root: React.FC = () => (
  // test with strict mode at some points but disable during normal developmet
  // <React.StrictMode>
  <RecoilRoot>
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <React.Suspense fallback={<LoadingView />}>
          <App />
          <Toast />
        </React.Suspense>
      </ApolloProvider>
    </ErrorBoundary>
  </RecoilRoot>
  // </React.StrictMode>
);
