import { JSONSchema7 } from "json-schema";

export const RecommendedMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    recommendedImageUrl: {
      description: "Feed item image url",
      type: "string",
    },
    recommendedPostTitle: {
      description: "Feed item title",
      type: "string",
    },
    recommendedPostText: {
      description: "Feed item text",
      type: "string",
    },
    recommendedLinkText: {
      description: 'Link text ("Visit the link" etc)',
      type: ["null", "string"],
    },
    recommendedLinkUrl: {
      description: "Link url to open when clicked",
      type: ["null", "string"],
    },
  },
  required: ["recommendedImageUrl", "recommendedPostText", "recommendedPostTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
