import { gql } from "@apollo/client";
import { sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { FlexProps } from "../../components/Flex/Flex";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { Modal } from "../../components/Modal/Modal";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { P } from "../../components/Paragraph/Paragraph";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DeleteCategoryModal } from "../../modals/DeleteCategoryModal/DeleteCategoryModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  CategoryByIdQueryResult,
  CategoryTypeEnum,
  CollaborationTypeEnum,
  LocaleEnum,
  useAdminDeleteCategoryMutation,
  useAdminUpdateCategoryInfoMutation,
  useCategoryByIdQuery,
  useLabelsQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatDuration } from "../../services/formatDuration";
import { getCategoryNameValueList } from "../../services/getCategoryNameValueList";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { getUrlName } from "../../services/getUrlName";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

gql`
  query CategoryById($categoryId: ID!) {
    admin {
      category(categoryId: $categoryId) {
        ...CategoryInfo
      }
    }
  }
`;

type CategoryByIdQueryCategoryInfo = NonNullable<CategoryByIdQueryResult["data"]>["admin"]["category"];
type PartialCreateCategoryData = Omit<EditCategoryData, "labelIds">;

export interface CategoryDetailsViewProps {
  viewer: ViewerInfo;
}

export const CategoryDetailsView: React.FC<CategoryDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useCategoryByIdQuery({
    variables: {
      categoryId: params.id ?? "",
    },
  });

  // get category info
  const category = categoryData?.admin.category;
  // handle error
  if (categoryError) {
    return <ErrorView title="Loading category info failed" error={categoryError} />;
  }

  // handle loading
  if (categoryLoading || !category) {
    return <LoadingView />;
  }

  // render category details, the view is split into subview components
  return (
    <View pad="half">
      <CategoryDetailsHeader category={category} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<CategoryDetailsInfo category={category} viewer={viewer} />} />
        <Route path="edit" element={<CategoryDetailsEdit category={category} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface CategoryProps extends FlexProps {
  category: CategoryByIdQueryCategoryInfo;
  viewer: ViewerInfo;
}

export const CategoryDetailsHeader: React.FC<CategoryProps> = ({ category, ...rest }) => {
  const navigate = useNavigate();
  const [showConfirmDisableModal, setShowConfirmDisableModal] = useState(false);
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
  const [adminDeleteCategory, adminDeleteCategoryResult] = useAdminDeleteCategoryMutation();
  const { modifier } = useAdminUrlInfo();
  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      {
        label: "Delete",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_CATEGORIES,
          UserScopeEnum.ADMIN_CATEGORIES_DELETE,
        ],
        onClick: () => {
          setIsDeleteCategoryModalOpen(true);
        },
      },
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "categories",
                  page: "category",
                  id: category.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMIN_CATEGORIES,
              UserScopeEnum.ADMIN_CATEGORIES_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "categories",
                  page: "category",
                  id: category.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [category.id, isEditMode, navigate],
  );

  return (
    <>
      <Header actions={actions} {...rest}>
        <Title>Category details</Title>
      </Header>

      <DeleteCategoryModal
        open={isDeleteCategoryModalOpen}
        onCancel={() => setIsDeleteCategoryModalOpen(false)}
        onClickOutside={() => setIsDeleteCategoryModalOpen(false)}
        loading={adminDeleteCategoryResult.loading}
        error={adminDeleteCategoryResult.error}
        onSubmit={async () => {
          const response = await adminDeleteCategory({
            variables: {
              categoryIds: category.id,
            },
          });

          if (response.data) {
            setIsDeleteCategoryModalOpen(false);

            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "categories",
              }),
            });
          }
        }}
      />

      <Modal
        title="Confirm disable category"
        actions={[
          {
            label: "Cancel",
            onClick: () => setShowConfirmDisableModal(false),
          },
        ]}
        open={showConfirmDisableModal}
        onClickOutside={() => setShowConfirmDisableModal(false)}
      >
        <P center>
          Are you sure you want to disable category for <strong>{category.title}</strong>?
        </P>
        <P center padTop="half">
          The category can be re-activated in the future.
        </P>
      </Modal>
    </>
  );
};

export const CategoryDetailsInfo: React.FC<CategoryProps> = ({ category, viewer }) => {
  const navigate = useNavigate();
  const categoryDetails = getCategoryNameValueList(category);

  // Category tracks data table headers
  const categoryTrackHeaders = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Title",
      },
      {
        label: "Description",
      },
      {
        label: "Track duration",
        center: true,
      },
      {
        label: "Track number",
        center: true,
      },
    ],
    [],
  );

  // Category tracks data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      category.tracks.map((track) => {
        return {
          id: track.id,
          cells: [
            {
              content: track.title,
            },
            {
              content: track.description,
            },
            {
              content: formatDuration(track.trackDurationSeconds * 1000),
              center: true,
            },
            {
              content: track.trackNumber,
              center: true,
            },
          ],
        };
      }),
    [category.tracks],
  );

  return (
    <WithCollaboration referenceId={category.id} type={CollaborationTypeEnum.CATEGORY} viewer={viewer}>
      <Title>Category</Title>
      <NameValueList items={categoryDetails} />
      <Title>Category Tracks</Title>
      <DataTable
        headers={categoryTrackHeaders}
        rows={rows}
        viewerscopes={viewer.scopes}
        openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_TRACKS, UserScopeEnum.ADMIN_TRACKS_INFO]}
        onOpen={(row) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "tracks",
              page: "track",
              modifier: row.id,
            }),
          })
        }
      />
    </WithCollaboration>
  );
};

type EditCategoryData = {
  title: string;
  type: CategoryTypeEnum;
  description: string;
  largeBackgroundImageUrl: string;
  urlName: string;
  labelIds: string[];
  orderWeight: string;
  trailerVimeoId: string;
  trailerYoutubeId: string;
  comment: string;
  isAdultOnly: string;
};

export const CategoryDetailsEdit: React.FC<CategoryProps> = ({ category, viewer, ...rest }) => {
  const navigate = useNavigate();
  const { data: labelsData } = useLabelsQuery();

  const [urlName, setUrlName] = useState(category.urlName);

  // Category tracks data table rows
  const [tracks, setTracks] = useState<DataTableRow[]>(() =>
    category.tracks.map((track) => {
      return {
        id: track.id,
        cells: [
          {
            content: track.title,
          },
          {
            content: track.description,
          },
          {
            content: formatDuration(track.trackDurationSeconds * 1000),
            center: true,
          },
          {
            content: track.trackNumber,
            center: true,
          },
        ],
      };
    }),
  );

  const [adminUpdateCategoryInfo, adminUpdateCategoryInfoResult] = useAdminUpdateCategoryInfoMutation();

  const categoryTrackHeaders = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Title",
      },
      {
        label: "Description",
      },
      {
        label: "Track duration",
        center: true,
      },
      {
        label: "Track number",
        center: true,
      },
    ],
    [],
  );

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "title",
        label: "Title",
        defaultValue: category.titleTranslations,
        onChange: (locale, value) => {
          if (locale === LocaleEnum.EN) {
            setUrlName(getUrlName(value));
          }
        },
      },
      {
        field: "translation",
        name: "altTitle",
        label: "Alternative Title",
        defaultValue: category.altTitleTranslations,
        onChange: (locale, value) => {
          if (locale === LocaleEnum.EN) {
            setUrlName(getUrlName(value));
          }
        },
      },
      {
        field: "translation",
        name: "description",
        label: "Description",
        defaultValue: category.descriptionTranslations,
      },
      {
        field: "radio",
        name: "type",
        label: "Type",
        options: Object.keys(CategoryTypeEnum).map((categoryType) => {
          return { value: categoryType, label: sentenceCase(categoryType) };
        }),
        defaultValue: category.type,
        rules: {
          required: "Please provide type",
        },
      },
      {
        field: "text",
        type: "text",
        name: "urlName",
        label: "Url name",
        onChange: (e) => setUrlName(getUrlName(e.target.value)),
        defaultValue: urlName,
      },
      {
        field: "text",
        type: "text",
        name: "orderWeight",
        label: "Category order weight",
        defaultValue: category.orderWeight.toString(),
      },
      {
        field: "radio",
        name: "isAdultOnly",
        label: "Age restriction (18+)",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: category.isAdultOnly ? "true" : "false",
      },
      {
        field: "text",
        type: "text",
        name: "trailerVimeoId",
        label: "Trailer vimeo video id",
        defaultValue: category.trailerVimeoId ?? "",
      },
      {
        field: "text",
        type: "text",
        name: "trailerYoutubeId",
        label: "Trailer youtube video id",
        defaultValue: category.trailerYoutubeId ?? "",
      },
      {
        field: "upload",
        type: "text",
        name: "smallBackgroundImage",
        label: "Small background image (leave blank to not change)",
      },
      {
        field: "upload",
        type: "text",
        name: "largeBackgroundImage",
        label: "Large background image (leave blank to not change)",
      },
      {
        field: "upload",
        type: "text",
        name: "trailerCoverImage",
        label: "Trailer cover image (leave blank to not change)",
      },
      {
        field: "checkbox",
        name: "labelIds",
        label: "Labels",
        options: labelsData
          ? labelsData.admin.labels.labels.map((label) => ({ value: label.id, label: label.title }))
          : [],
        defaultValue: category.labels.map((label) => label.id),
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        defaultValue: "Updated category info",
        rules: {
          required: "Please describe what and why was updated",
        },
      },
      {
        field: "reorder",
        name: "tracks",
        label: "Track order",
        rows: tracks,
        headers: categoryTrackHeaders,
        onChange: (order) => setTracks(order),
      },
    ],
    [
      category.titleTranslations,
      category.altTitleTranslations,
      category.descriptionTranslations,
      category.type,
      category.orderWeight,
      category.isAdultOnly,
      category.trailerVimeoId,
      category.trailerYoutubeId,
      category.labels,
      urlName,
      labelsData,
      tracks,
      categoryTrackHeaders,
    ],
  );

  const onSubmit = useCallback(
    async (data: EditCategoryData) => {
      const smallBackgroundImage = getFileUploadById("smallBackgroundImage");
      const largeBackgroundImage = getFileUploadById("largeBackgroundImage");
      const trailerCoverImage = getFileUploadById("trailerCoverImage");

      // update category data
      const response = await adminUpdateCategoryInfo({
        variables: {
          ...data,
          title: getTranslationInput("title", data as PartialCreateCategoryData),
          altTitle: getTranslationInput("altTitle", data as PartialCreateCategoryData),
          description: getTranslationInput("description", data as PartialCreateCategoryData),
          orderWeight: data.orderWeight ? parseInt(data.orderWeight) : 0,
          trailerVimeoId: data.trailerVimeoId,
          smallBackgroundImage,
          largeBackgroundImage,
          trailerCoverImage,
          categoryId: category.id,
          trackOrder: tracks.map((track) => track.id),
          isAdultOnly: data.isAdultOnly === "true",
          labelIds: data.labelIds,
        },
        refetchQueries: ["CollaborationById"],
        awaitRefetchQueries: true,
      });

      if (response.data) {
        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "categories",
            page: "category",
            id: category.id,
          }),
        });
      }
    },
    [adminUpdateCategoryInfo, category.id, tracks, navigate],
  );

  return (
    <WithCollaboration referenceId={category.id} type={CollaborationTypeEnum.CATEGORY} viewer={viewer} {...rest}>
      <GeneratedForm
        title="Category"
        error={adminUpdateCategoryInfoResult.error}
        loading={adminUpdateCategoryInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
