import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FlexProps } from "../../components/Flex/Flex";
import { GridBox } from "../../components/GridBox/GridBox";
import { Header } from "../../components/Header/Header";
import { IconButton } from "../../components/IconButton/IconButton";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { Removed } from "../../components/Removed/Removed";
import { Row } from "../../components/Row/Row";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { ChangeGiftCardRecipientModal } from "../../modals/ChangeGiftCardRecipientModal/ChangeGiftCardRecipientModal";
import { ManualResolveGiftCardModal } from "../../modals/ManualResolveGiftCardModal/ManualResolveGiftCardModal";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import {
  AdminGiftCardByIdQueryResult,
  AdminUpdateGiftCardInfoMutation,
  CollaborationTypeEnum,
  useAdminGiftCardByIdQuery,
  AdminManualResolveGiftCardMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEstimatedGiftCardExpiryDate } from "../../services/getEstimatedGiftCardExpiryDate";
import { ReactComponent as ActionsIcon } from "../../theme/icons/actions-icon.svg";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

type GiftCardByIdQueryGiftCardInfo = NonNullable<AdminGiftCardByIdQueryResult["data"]>["admin"]["giftCard"];

export interface GiftCardDetailsViewProps {
  viewer: ViewerInfo;
}

export const GiftCardDetailsView: React.FC<GiftCardDetailsViewProps> = ({ viewer }) => {
  const params = useParams<AdminViewParams>();

  const {
    data: giftCardData,
    loading: giftCardLoading,
    error: giftCardError,
  } = useAdminGiftCardByIdQuery({
    variables: {
      giftCardId: params.id ?? "",
    },
  });

  // get giftCard info
  const giftCard = giftCardData?.admin.giftCard;
  // handle error
  if (giftCardError) {
    return <ErrorView title="Loading Gift Card info failed" error={giftCardError} />;
  }

  // handle loading
  if (giftCardLoading || !giftCard) {
    return <LoadingView />;
  }

  // render giftCard details, the view is split into subview components
  return (
    <View pad="half">
      <GiftCardDetailsHeader giftCard={giftCard} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<GiftCardDetailsInfo giftCard={giftCard} viewer={viewer} />} />
      </Routes>
    </View>
  );
};

export interface GiftCardProps extends FlexProps {
  giftCard: GiftCardByIdQueryGiftCardInfo;
  viewer: ViewerInfo;
}

export const GiftCardDetailsHeader: React.FC<GiftCardProps> = ({ giftCard, ...rest }) => {
  const setToast = useSetRecoilState(toastState);
  const [showChangeGiftCardRecipientModal, setShowChangeGiftCardRecipientModal] = useState(false);
  const [showResolveGiftCardRecipientModal, setShowResolveGiftCardRecipientModal] = useState(false);

  const actions: Action[] = useMemo(
    () => [
      giftCard.activatedById == null && giftCard.activationDate == null && giftCard.deletedDate == null
        ? {
            label: "Change recipient",
            onClick: () => setShowChangeGiftCardRecipientModal(true),
          }
        : null,
      giftCard.activatedById == null && giftCard.activationDate == null && giftCard.deletedDate == null
        ? {
            label: "Resolve",
            onClick: () => setShowResolveGiftCardRecipientModal(true),
          }
        : null,
    ],
    [giftCard.activatedById, giftCard.activationDate, giftCard.deletedDate],
  );

  return (
    <>
      <Header actions={actions} {...rest}>
        <Title>GiftCard details</Title>
      </Header>

      <ChangeGiftCardRecipientModal
        giftCardId={giftCard.id}
        toEmail={giftCard.toEmail}
        open={showChangeGiftCardRecipientModal}
        onClickOutside={() => setShowChangeGiftCardRecipientModal(false)}
        onCompleted={(data: AdminUpdateGiftCardInfoMutation) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Recipient email has been changed",
            message: `New gift Card email: ${data.updateGiftCard.toEmail}`,
            isOpen: true,
          });

          // hide modal
          setShowChangeGiftCardRecipientModal(false);
        }}
      />
      {giftCard.activationDate == null ? (
        <ManualResolveGiftCardModal
          giftCardId={giftCard.id}
          open={showResolveGiftCardRecipientModal}
          onClickOutside={() => setShowResolveGiftCardRecipientModal(false)}
          onCompleted={(data: AdminManualResolveGiftCardMutation) => {
            if (data) {
              if (data.resolveGiftCard.deletedDate) {
                setToast({
                  type: ToastType.SUCCESS,
                  title: "Gift Card resolved",
                  message: `Gift Card was marked as refunded`,
                  isOpen: true,
                });
              } else {
                // show success toast message
                setToast({
                  type: ToastType.SUCCESS,
                  title: "Gift Card resolved",
                  message: `New gift Card recipient: ${data.resolveGiftCard.activatedById}`,
                  isOpen: true,
                });
              }
              return setShowResolveGiftCardRecipientModal(false);
            }
          }}
          onError={(_e) => {
            setToast({
              type: ToastType.ERROR,
              title: "Gift Card resolution",
              message: `Failed to resolve Gift Card`,
              isOpen: true,
            });
          }}
        />
      ) : undefined}
    </>
  );
};

export const GiftCardDetailsInfo: React.FC<GiftCardProps> = ({ giftCard, viewer }) => {
  // const giftCardDetails = getGiftCardNameValueList(giftCard);
  const navigate = useNavigate();

  const infoFields = [
    {
      name: "Unique id",
      value: giftCard.id,
    },
    {
      name: "Type",
      value: giftCard.metaData.identifier.toUpperCase(),
    },
    {
      name: "Value",
      value: `${giftCard.metaData.price / 100} ${giftCard.metaData.currency ?? "n/a"}`,
    },
    {
      name: "Code",
      value: giftCard.deletedDate ? (
        <>
          <Removed>{giftCard.code}</Removed>(refunded)
        </>
      ) : (
        giftCard.code
      ),
    },
    {
      name: "Purchaser user ID",
      value: giftCard.creatorId ? (
        <Row mainAxisAlignment="space-between">
          {giftCard.creatorId}
          <IconButton
            title="Details"
            padLeft="half"
            icon={<ActionsIcon />}
            onClick={() =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "users",
                  page: "user",
                  id: giftCard.creatorId ?? undefined,
                }),
              })
            }
          />
        </Row>
      ) : (
        "n/a"
      ),
    },
    {
      name: "Recipient email",
      value: giftCard.toEmail,
    },
    {
      name: "Recipient name",
      value: giftCard.toName,
    },
    {
      name: "Message sent",
      value: giftCard.message,
    },
    {
      name: "Sender's Email",
      value: giftCard.creator?.email ?? "n/a",
    },
    {
      name: "Sender's Name",
      value: giftCard.creator?.name ?? "n/a",
    },
    {
      name: "Activated by user ID",
      value: giftCard.activatedById ? (
        <Row mainAxisAlignment="space-between">
          {giftCard.activatedById}
          <IconButton
            title="Details"
            padLeft="half"
            icon={<ActionsIcon />}
            onClick={() =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "users",
                  page: "user",
                  id: giftCard.activatedById!,
                }),
              })
            }
          />
        </Row>
      ) : (
        "-"
      ),
    },
    {
      name: "activation Date",
      value: giftCard.activationDate
        ? `${DateTime.fromISO(giftCard.activationDate).toHTTP()} (expires ${getEstimatedGiftCardExpiryDate(
            giftCard.activationDate,
            giftCard.metaData.identifier.toUpperCase(),
          )})`
        : "-",
    },
    {
      name: "Creation date",
      value: DateTime.fromISO(giftCard.createdDate).toHTTP(),
    },
  ];

  return (
    <WithCollaboration referenceId={giftCard.id} type={CollaborationTypeEnum.GIFT_CARD} viewer={viewer}>
      <Title>GiftCard</Title>
      <NameValueList items={infoFields} />
    </WithCollaboration>
  );
};
