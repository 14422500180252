import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { TrackInfoFragment } from "../schema";
import { formatDuration } from "./formatDuration";
import { renderTranslationMap } from "./renderTranslationMap";

export function getTrackNameValueList(track: TrackInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: track.id,
    },
    {
      name: "Title",
      value: renderTranslationMap(track.titleTranslations),
    },
    {
      name: "Description",
      value: renderTranslationMap(track.descriptionTranslations),
    },
    {
      name: "Wowza track name",
      value: track.wowzaTrackName,
    },
    {
      name: "Trailer url",
      value: track.trailerUrl,
    },
    {
      name: "Track duration",
      value: track.trackDurationSeconds ? formatDuration(track.trackDurationSeconds * 1000) : "n/a",
    },
    {
      name: "Average rating",
      value: track.averageRating,
    },
    {
      name: "Review count",
      value: track.reviewCount,
    },
    {
      name: "Is free",
      value: track.isFree ? "yes" : "no",
    },
    {
      name: "Small background",
      value: <img src={track.smallBackgroundImageUrl} alt="Small background" style={{ width: "50rem" }} />,
    },
    {
      name: "Large background",
      value: <img src={track.largeBackgroundImageUrl} alt="Large background" style={{ width: "50rem" }} />,
    },
  ];
}
