import React from "react";
import { useRecoilState, atom } from "recoil";
import { changelog } from "../../changelog";
import { ReactComponent as CloseIcon } from "../../theme/icons/close-icon.svg";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { Expanded } from "../Expanded/Expanded";
import { GridBox } from "../GridBox/GridBox";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./Changelog.module.scss";

const { seen, unseen } = getChangelogItems();

export const showChangelogState = atom({
  key: "showChangelog",
  default: unseen.length > 0,
});

export const Changelog: React.FC = () => {
  // use global state to decide whether to show changelog
  const [showChangelog, setShowChangelog] = useRecoilState(showChangelogState);

  // render nothing if there are no unseen items
  if (!showChangelog) {
    return null;
  }

  // marks current items seen and closes changelog
  const closeChangelog = () => {
    markChangelogItemsSeen();
    setShowChangelog(false);
  };

  return (
    <Container crossAxisAlignment="center" className={styles.backdrop}>
      <Column shrinkable className={styles.changelog}>
        <Row className={styles.title} crossAxisAlignment="center">
          <P>Changelog</P>
          <Expanded />
          <Container onClick={closeChangelog} className={styles["close-icon-wrap"]}>
            <CloseIcon className={styles["close-icon"]} />
          </Container>
        </Row>
        <Column scrollable expanded className={styles.content}>
          {unseen.length > 0 && <P darker>Changes since last visit</P>}
          {unseen.map((item, index) => (
            <ChangelogItem key={index} index={unseen.length - index + seen.length} text={item} />
          ))}

          <GridBox size={2} />

          {seen.length > 0 && <P darker>Previous changes</P>}
          {seen.map((item, index) => (
            <ChangelogItem key={index} index={seen.length - index} text={item} />
          ))}
        </Column>
      </Column>
    </Container>
  );
};

export interface ChangelogItemProps {
  index: number;
  text: string;
}

export const ChangelogItem: React.FC<ChangelogItemProps> = ({ text, index }) => (
  <P small className={styles.item}>
    {index}. {text}
  </P>
);

function markChangelogItemsSeen() {
  localStorage.seenChangelogItems = JSON.stringify(changelog);
}

function getSeenChangelogItems(): string[] {
  const data = localStorage.seenChangelogItems;

  return data !== undefined ? JSON.parse(data) : [];
}

function getChangelogItems() {
  const seen = getSeenChangelogItems();
  const unseen = changelog.filter((item) => !seen.includes(item));

  return {
    seen,
    unseen,
  };
}
