import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { AdminFeedItemByIdQueryResult, useAdminFeedItemByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminFeedItemDetailsEdit } from "./AdminFeedItemDetailsEdit";
import { AdminFeedItemDetailsHeader } from "./AdminFeedItemDetailsHeader";
import { AdminFeedItemDetailsInfo } from "./AdminFeedItemDetailsInfo";

export interface AdminFeedItemDetailsProps extends AdminViewProps {
  adminFeedItem: NonNullable<AdminFeedItemByIdQueryResult["data"]>["admin"]["adminFeedItem"];
}

export const AdminFeedItemDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useAdminFeedItemByIdQuery({
    variables: {
      adminFeedItemId: id ?? "",
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const adminFeedItem = data?.admin.adminFeedItem;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  // handle loading
  if (loading || !adminFeedItem) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <AdminFeedItemDetailsHeader adminFeedItem={adminFeedItem} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<AdminFeedItemDetailsInfo adminFeedItem={adminFeedItem} viewer={viewer} />} />
        <Route path="edit" element={<AdminFeedItemDetailsEdit adminFeedItem={adminFeedItem} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
