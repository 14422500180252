import { sentenceCase } from "change-case";
import { useMemo } from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { Container } from "../../components/Container/Container";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { GridBox } from "../../components/GridBox/GridBox";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { AdminFeedPollInfoFragment, CollaborationTypeEnum } from "../../schema";
import { formatDate } from "../../services/formatDate";
import { renderTranslationMap } from "../../services/renderTranslationMap";
import { AdminFeedPollDetailsResults } from "./AdminFeedPollDetailsResults";
import { AdminFeedPollDetailsProps } from "./AdminFeedPollDetailsView";

export const AdminFeedPollDetailsInfo: React.FC<AdminFeedPollDetailsProps> = ({ adminFeedPoll, viewer }) => {
  const optionHeaders = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Option",
        compact: true,
      },
      {
        label: "Icon",
        compact: true,
        center: true,
      },
      {
        label: "Answer Option",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(() => {
    return adminFeedPoll.adminFeedPollOptions
      .slice()
      .sort((prev, next) => prev.ordinal - next.ordinal)
      .map((adminFeedPollOption) => {
        return {
          id: adminFeedPollOption.id,
          cells: [
            {
              content: adminFeedPollOption.ordinal,
            },
            {
              content: adminFeedPollOption.iconUrl ? (
                <img
                  src={adminFeedPollOption.iconUrl}
                  alt="Small background"
                  style={{ width: "15rem", height: "15rem" }}
                />
              ) : (
                <Container>n/a</Container>
              ),
            },
            {
              content: renderTranslationMap(adminFeedPollOption.answer),
            },
          ],
        };
      });
  }, [adminFeedPoll]);

  function getAdminFeedPollNameValueList(adminFeedPoll: AdminFeedPollInfoFragment): NameValueListItem[] {
    return [
      {
        name: "Poll ID",
        value: adminFeedPoll.id,
      },
      {
        name: "Status",
        value: sentenceCase(adminFeedPoll.adminFeedItem.status),
      },
      {
        name: "Question",
        value: renderTranslationMap(adminFeedPoll.questionTranslations),
      },
      {
        name: "Image",
        value: adminFeedPoll.imageUrl ? (
          <img src={adminFeedPoll.imageUrl} alt="Small background" style={{ width: "50rem" }} />
        ) : (
          "n/a"
        ),
      },
      {
        name: "Audience",
        value: adminFeedPoll.adminFeedItem.audience.map((audience) => sentenceCase(audience)).join(", "),
      },
      {
        name: "Start showing from date",
        value: formatDate(adminFeedPoll.adminFeedItem.startDate),
      },
      {
        name: "Stop showing from date",
        value: formatDate(adminFeedPoll.adminFeedItem.endDate),
      },
      {
        name: "Start showing from days after registration",
        value: adminFeedPoll.adminFeedItem.startDay,
      },
      {
        name: "Stop showing from days after registration",
        value: adminFeedPoll.adminFeedItem.endDay,
      },
      {
        name: "Is pinned (showed first on top)",
        value: adminFeedPoll.adminFeedItem.isPinned,
      },
      {
        name: "Number of likes",
        value: adminFeedPoll.adminFeedItem.likeCount,
      },
      {
        name: "Number of shares",
        value: adminFeedPoll.adminFeedItem.shareCount,
      },
      {
        name: "Topics",
        value: adminFeedPoll.adminFeedItem.topics.map((topic) => topic.name).join(", "),
      },
      {
        name: "Created",
        value: adminFeedPoll.createdDate,
      },
      {
        name: "Updated",
        value: adminFeedPoll.updatedDate,
      },
      {
        name: "Options",
        value: <DataTable hideSelectRow headers={optionHeaders} rows={rows} />,
      },
    ];
  }

  return (
    <WithCollaboration referenceId={adminFeedPoll.id} type={CollaborationTypeEnum.ADMIN_FEED_POLL} viewer={viewer}>
      <NameValueList items={getAdminFeedPollNameValueList(adminFeedPoll)} />

      <GridBox />
      <Title>Results</Title>
      <AdminFeedPollDetailsResults adminFeedPoll={adminFeedPoll} viewer={viewer} />
    </WithCollaboration>
  );
};
