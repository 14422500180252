import classNames from "classnames";
import React, { useCallback } from "react";
import { Column } from "../Column/Column";
import { FlexProps } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./NameValuePair.module.scss";

export interface NameValuePairProps extends FlexProps {
  name: React.ReactNode;
  vertical?: boolean;
}

export const NameValuePair: React.FC<NameValuePairProps> = ({ name, vertical, className, children, ...rest }) => {
  const renderChildren = useCallback(() => {
    if (children === undefined || children === null) {
      <P small marginTop={1}>
        n/a
      </P>;
    }

    if (typeof children === "string" || typeof children === "number") {
      return (
        <P small marginTop={1}>
          {children}
        </P>
      );
    }

    if (Array.isArray(children)) {
      return (children as React.ReactNode[]).map((child, index) => (
        <P key={index} small>
          {child}
        </P>
      ));
    }

    return children;
  }, [children]);

  if (vertical) {
    return (
      <Column className={classNames(styles["name-value-pair"], className)} {...rest}>
        <P darker>{name}</P>
        {renderChildren()}
      </Column>
    );
  }

  return (
    <Row
      mainAxisAlignment="space-between"
      crossAxisAlignment="center"
      className={classNames(styles["name-value-pair"], className)}
      {...rest}
    >
      {typeof name === "string" ? <P darker>{name}</P> : name}
      {typeof children === "string" || typeof children === "number" ? <P>{children}</P> : children}
    </Row>
  );
};

export interface NameValueListItem {
  name: React.ReactNode;
  value: React.ReactNode;
}

export interface NameValueListProps extends FlexProps {
  items: NameValueListItem[];
}

export const NameValueList: React.FC<NameValueListProps> = ({ items, ...rest }) => (
  <Column {...rest}>
    {items.map((item, index) => (
      <NameValuePair vertical key={index} name={item.name}>
        {item.value}
      </NameValuePair>
    ))}
  </Column>
);
