import { JSONSchema7 } from "json-schema";

export const ExternalLinkMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    externalLinkImageUrl: {
      description: "Feed item image url",
      type: "string",
    },
    externalLinkPostTitle: {
      description: "Feed item title",
      type: "string",
    },
    externalLinkPostText: {
      description: "Feed item text",
      type: "string",
    },
    externalLinkText: {
      description: 'Link text ("Visit the link" etc)',
      type: "string",
    },
    externalLinkUrl: {
      description: "Link url to open when clicked",
      type: "string",
    },
  },
  required: [
    "externalLinkImageUrl",
    "externalLinkPostText",
    "externalLinkPostTitle",
    "externalLinkText",
    "externalLinkUrl",
  ],
  $schema: "http://json-schema.org/draft-07/schema#",
};
