import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";

export interface MarkUserAsTestUserModalProps extends ModalProps {
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  loading: boolean;
  userCount: number;
}

export const MarkUserAsTestUserModal: React.FC<MarkUserAsTestUserModalProps> = ({
  onSubmit,
  onCancel,
  loading,
  userCount,
  ...rest
}) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      loading: loading,
      onClick: onSubmit,
    },
  ];
  return (
    <Modal actions={modalActions} title="Confirm test user" {...rest}>
      <P center>Are you sure you wish to mark {userCount} user(s) as test user(s)?</P>
    </Modal>
  );
};
