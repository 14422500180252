import { gql } from "@apollo/client";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Form } from "../../components/Form/Form";
import { GridBox } from "../../components/GridBox/GridBox";
import { Logo } from "../../components/Logo/Logo";
import { Panel } from "../../components/Panel/Panel";
import { TextField } from "../../components/TextField/TextField";
import { View } from "../../components/View/View";
import { useReturnToStoredPath } from "../../hooks/useReturnToStoredPath";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useLoginMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFieldErrors } from "../../services/getFieldErrors";
import { ReactComponent as EmailIcon } from "../../theme/icons/email-icon.svg";
import { ReactComponent as PasswordIcon } from "../../theme/icons/password-icon.svg";
import { validateEmail } from "../../validators/validateEmail";
import { validateMinimumLength } from "../../validators/validateMinimumLength";
import styles from "./LoginView.module.scss";

interface LoginFormValues {
  email: string;
  password: string;
}

// log user in
gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...ViewerInfo
    }
  }
`;

export const LoginView: React.FC = () => {
  const { register, handleSubmit, formState } = useForm<LoginFormValues>();

  // setup next page url and use it if no previous route path has been stored to return to
  const nextPageUrl = buildUrl<AdminViewParams>(ADMIN_VIEW_PATH);
  const returnToStoredPath = useReturnToStoredPath(nextPageUrl);

  // setup login mutation
  const [login, loginResult] = useLoginMutation({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
  });

  // get combined client and server side field errors
  const fieldError = getFieldErrors(loginResult.error, formState.errors);

  // login user on submit
  const onSubmit: SubmitHandler<LoginFormValues> = async ({ email, password }) => {
    const response = await login({
      variables: { email, password },
    });

    // redirect to stored page or next page if login is successful
    if (response.data) {
      returnToStoredPath();
    }
  };

  return (
    <View center>
      <Panel pad overflow raised className={styles.panel}>
        <Logo small />
        <Form padTop="half" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="email"
            name="email"
            label={"Email"}
            leading={<EmailIcon />}
            error={fieldError.email}
            register={register("email", { required: "Email is required", validate: validateEmail })}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            autoComplete="current-password"
            leading={<PasswordIcon />}
            error={fieldError.password}
            register={register("password", { validate: validateMinimumLength(8) })}
          />
          <GridBox half />
          <BlockButton tertiary loading={loginResult.loading} type="submit" onClick={handleSubmit(onSubmit)}>
            Log In
          </BlockButton>
        </Form>
      </Panel>
    </View>
  );
};
