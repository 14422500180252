import { gql } from "@apollo/client";
import { paramCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateAuthorModal } from "../../modals/CreateAuthorModal/CreateAuthorModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, useAuthorsQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { limitTextLength } from "../../services/limitTextLength";
import { showNotImplemented } from "../../services/showNotImplemented";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

gql`
  query Authors($filter: AdminAuthorsFilterInput, $pagination: PaginationInput, $match: MatchInput) {
    admin {
      authors(filter: $filter, pagination: $pagination, match: $match) {
        skip
        take
        total
        authors {
          ...AuthorInfo
        }
      }
    }
  }
`;

interface AuthorsFilterData extends FilterBaseData {
  authorId: string;
  name: string;
}

export const AuthorListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const params = useUrlParams<AuthorsFilterData>((params) => ({
    authorId: params.authorId ?? "",
    name: params.name ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const {
    data: authorsData,
    loading: authorsLoading,
    error: authorsError,
  } = useAuthorsQuery({
    variables: {
      filter: {
        authorId: params.authorId.length > 0 ? params.authorId : undefined,
        name: params.name.length > 0 ? params.name : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const [isCreateAuthorModalOpen, setIsCreateAuthorModalOpen] = useState(false);

  // pagination info
  const authors = useMemo(() => authorsData?.admin.authors.authors ?? [], [authorsData?.admin.authors.authors]);

  const total = authorsData?.admin.authors.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "authorId",
        label: "Author id",
        defaultValue: params.authorId,
      },
      // TODO: find a solution to default value translations
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Name",
        defaultValue: params.name,
      },
    ],
    [params.authorId, params.name],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Name",
      },
      {
        label: "Title",
      },
      {
        label: "Description",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      authors.map((author) => {
        return {
          id: author.id,
          cells: [
            {
              content: author.name,
            },
            {
              content: author.title,
            },
            {
              content: limitTextLength(author.description),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_AUTHORS,
                UserScopeEnum.ADMIN_AUTHORS_INFO,
              ],
              onClick: (authorId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "authors",
                    page: "author",
                    id: authorId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMIN_AUTHORS,
                UserScopeEnum.ADMIN_AUTHORS_UPDATE_INFO,
              ],
              onClick: (authorId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "authors",
                    page: "author",
                    id: authorId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [authors, navigate],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      // TODO: implement deleting author
      {
        label: "Delete",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMIN_AUTHORS,
          UserScopeEnum.ADMIN_AUTHORS_UPDATE_INFO,
        ],
        onClick: (authorsIds) => showNotImplemented(`Disable author ${authorsIds.join(", ")} not implemented`),
      },
    ],
    [],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create author",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_AUTHORS, UserScopeEnum.ADMIN_AUTHORS_CREATE],
        onClick: () => setIsCreateAuthorModalOpen(true),
      },
    ],
    [],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: AuthorsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "authors" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (authorsError) {
    return <ErrorView title="Fetching authors failed" error={authorsError} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Authors"
          fields={filters}
          actions={viewActions}
          viewerscopes={viewer.scopes}
          loading={authorsLoading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={authorsLoading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          viewerscopes={viewer.scopes}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_AUTHORS,
            UserScopeEnum.ADMIN_AUTHORS_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "authors",
                page: "author",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "authors" }),
              search: getUrlSearchParamsString({ ...paramCase, page }),
            })
          }
        />
        <CreateAuthorModal open={isCreateAuthorModalOpen} onClickOutside={() => setIsCreateAuthorModalOpen(false)} />
      </View>
    </>
  );
};
