import React from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../components/Container/Container";
import { Expanded } from "../../components/Expanded/Expanded";
import { Row } from "../../components/Row/Row";
import { ViewerMenu } from "../../components/ViewerMenu/ViewerMenu";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { buildUrl } from "../../services/buildUrl";
import { AdminViewProps } from "../../views/AdminView/AdminView";
import { FlexProps } from "../Flex/Flex";
import styles from "./AppHeader.module.scss";

export type AppHeaderProps = AdminViewProps & FlexProps;

export const AppHeader: React.FC<AppHeaderProps> = ({ viewer }) => {
  const navigate = useNavigate();

  return (
    <Row overflow className={styles.header}>
      <Container
        mainAxisAlignment="center"
        className={styles.logo}
        onClick={() =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {}),
          })
        }
      >
        Beginning Admin
      </Container>

      <Expanded />
      <ViewerMenu padLeft viewer={viewer} />
    </Row>
  );
};
