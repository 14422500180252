import React from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";

export interface DeleteArticleModalProps extends ModalProps {
  onSubmit: VoidFunction;
  onCancel?: VoidFunction;
  loading: boolean;
}

export const DeleteArticleModal: React.FC<DeleteArticleModalProps> = ({ onSubmit, onCancel, loading, ...rest }) => {
  const modalActions: ModalAction[] = [
    {
      label: "Cancel",
      onClick: onCancel,
    },
    {
      label: "Confirm",
      danger: true,
      loading: loading,
      onClick: onSubmit,
    },
  ];

  return (
    <Modal title="Confirm deleting articles" actions={modalActions} {...rest}>
      <P center>Are you sure you wish to delete these articles</P>
    </Modal>
  );
};
