import { JSONSchema7 } from "json-schema";

export const BasicMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    basicText: {
      description: "text",
      type: "string",
    },
    basicTitle: {
      description: "title",
      type: "string",
    },
    basicImageUrl: {
      description: "image URL",
      type: "string",
    },
  },
  required: ["basicImageUrl", "basicText", "basicTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
