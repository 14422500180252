import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { AdminViewMenu } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { hasAnyScopes } from "../../services/hasAnyScopes";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { Expanded } from "../Expanded/Expanded";
import { FlexProps } from "../Flex/Flex";
import styles from "./MainMenu.module.scss";

export interface MainMenuItemInfo<TContentProps = unknown> {
  name: AdminViewMenu;
  title: string;
  url: string;
  authorizedScopes: UserScopeEnum[];
  icon: React.ReactElement;
  content?: (info: TContentProps) => React.ReactNode;
}

export type OnMenuItemClickCallback = (index: number) => void;

export interface MainMenuGroupInfo {
  title: string;
}

export type MainMenuItem<TContentProps = unknown> = MainMenuItemInfo<TContentProps> | MainMenuGroupInfo;

export interface MainMenuProps extends FlexProps {
  items: MainMenuItem[];
  activeItemIndex: number;
  viewerscopes: UserScopeEnum[];
}

export const MainMenu: React.FC<MainMenuProps> = ({ items, activeItemIndex, viewerscopes, className, ...rest }) => (
  <Column className={classNames(styles.menu, className)} {...rest}>
    {items.map((item, index) => {
      if (isMainMenuItem(item)) {
        if (!hasAnyScopes(item.authorizedScopes, viewerscopes)) {
          return null;
        }

        return (
          <Link
            key={index}
            to={item.url}
            className={classNames(styles.item, { [styles["item--active"]]: index === activeItemIndex })}
          >
            {item.icon} {item.title}
          </Link>
        );
      }
      return (
        <Container key={`menu_${index}`} className={styles.group}>
          {item.title}
        </Container>
      );
    })}
    <Expanded />
  </Column>
);

export function isMainMenuItem(info: any): info is MainMenuItemInfo {
  return info && info?.name;
}
