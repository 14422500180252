import { TranslationInput, LocaleEnum, TranslationMap } from "../schema";

// takes field name like "title" and uses "title-en", "title-et" etc keys in data to build translation input
// { EN: "English translation", ET: "Estonian translation" } etc
export function getTranslationInput(field: string, data: Record<string, string>): TranslationInput {
  const translationInput: TranslationInput = {};

  for (const _locale in LocaleEnum) {
    const locale = _locale as keyof Omit<TranslationMap, "__typename">;
    const value = data[`${field}-${locale}`] ?? "";

    translationInput[locale] = value;
  }

  return translationInput;
}
