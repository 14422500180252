import { sentenceCase } from "change-case";
import React, { useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Column } from "../../components/Column/Column";
import { Container } from "../../components/Container/Container";
import { Flex } from "../../components/Flex/Flex";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { P } from "../../components/Paragraph/Paragraph";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  AdminFeedItemAudienceTypeEnum,
  AdminFeedItemTypeEnum,
  AdminFeedTopic,
  useAdminFeedTopicsQuery,
  useCreateAdminFeedItemMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import formOptions from "../../services/getEnumFormOptions";
import { getFieldErrors } from "../../services/getFieldErrors";
import { getMetadataFromFields, MetadataFormFields } from "../../services/getMetadataFromFields";
import { getMetadataFormFields } from "../../services/getMetadataSchema";
import { validateNumeric } from "../../validators/validateNumeric";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

interface CreateAdminFeedItemData extends FieldValues {
  type: AdminFeedItemTypeEnum;
  audience: AdminFeedItemAudienceTypeEnum[];
  isPinned: "true" | "false";
  isRecommended: "true" | "false";
  isAdultOnly: "true" | "false";
  metadata: MetadataFormFields;
  startDate: string | null;
  endDate: string | null;
  startDay: string;
  endDay: string;
  likeCount: string;
  shareCount: string;
  topicIds: string[];
}

const userCreatableItemTypes: Array<AdminFeedItemTypeEnum> = [
  AdminFeedItemTypeEnum.BASIC,
  AdminFeedItemTypeEnum.BLOG,
  AdminFeedItemTypeEnum.CONSULTATION,
  AdminFeedItemTypeEnum.COURSE,
  AdminFeedItemTypeEnum.EXTERNAL_LINK,
  AdminFeedItemTypeEnum.FACT,
  AdminFeedItemTypeEnum.IMAGE_CAROUSEL,
  AdminFeedItemTypeEnum.QUOTE,
  AdminFeedItemTypeEnum.RECOMMENDED,
  AdminFeedItemTypeEnum.SALE,
  AdminFeedItemTypeEnum.SURVEY,
  AdminFeedItemTypeEnum.VIDEO,
  AdminFeedItemTypeEnum.JOURNEY,
];

const defaultType = userCreatableItemTypes[0];

export const AdminFeedItemCreateView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const formRef = useRef<HTMLFormElement>(null);
  const [metadataFormFields, setMetadataFormFields] = useState<FormFieldOptions[]>([]);

  const formMethods = useForm<CreateAdminFeedItemData>({
    mode: "onChange",
    defaultValues: {
      metadata: metadataFormFields,
    },
  });

  const { data, loading, error } = useAdminFeedTopicsQuery();

  const { handleSubmit, watch, resetField, setError } = formMethods;

  const feedItemType = watch("type");

  useEffect(() => {
    // update metadata part of the form
    const newFields = getMetadataFormFields(feedItemType ?? defaultType);

    setMetadataFormFields(newFields);
    resetField("metadata");
  }, [feedItemType, resetField]);

  const [createAdminFeedItem, createAdminFeedItemResult] = useCreateAdminFeedItemMutation({
    onError: (_error) => {
      setToast({
        type: ToastType.ERROR,
        title: "Creating a new admin feed item failed",
        message: `We were unable to submit the given feed item, check errors`,
        isOpen: true,
      });
    },
    onCompleted: (data) => {
      // show success toast message
      setToast({
        type: ToastType.SUCCESS,
        title: "Created a new admin feed item",
        message: `Admin feed item of type "${sentenceCase(data.createAdminFeedItem.type)}" was created`,
        isOpen: true,
      });

      // navigate to item details view
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "feed-items",
          page: "items",
          id: data.createAdminFeedItem.id,
        }),
      });
    },
    refetchQueries: ["AdminFeedItems"],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    const errors = getFieldErrors<CreateAdminFeedItemData>(createAdminFeedItemResult.error);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError(name, { type: "validate", message: errors[name]?.message });
        }
      });
    }
  }, [createAdminFeedItemResult.error, setError]);

  const onSubmit: SubmitHandler<CreateAdminFeedItemData> = (data) => {
    createAdminFeedItem({
      variables: {
        ...data,
        isPinned: data.isPinned === "true",
        isRecommended: data.isRecommended === "true",
        isAdultOnly: data.isAdultOnly === "true",
        startDay: data.startDay ? parseInt(data.startDay, 10) : null,
        endDay: data.endDay ? parseInt(data.endDay, 10) : null,
        endDate: data.endDate ? data.endDate : null,
        likeCount: data.likeCount ? parseInt(data.likeCount, 10) : null,
        shareCount: data.shareCount ? parseInt(data.shareCount, 10) : null,
        topicIds: data.topicIds,
        metadata: getMetadataFromFields(data.metadata),
      },
      refetchQueries: [],
      awaitRefetchQueries: true,
    });
  };

  if (loading) {
    return <LoadingView />;
  }

  // handle errors
  if (error || !data?.admin.adminFeedTopics || data?.admin.adminFeedTopics.length === 0) {
    return (
      <ErrorView
        title="Fetching admin feed topics failed"
        error={error || "No topics were found for Admin feed items"}
      />
    );
  }

  return (
    <View pad="half" scrollable>
      <Title>Create new feed item</Title>
      <FormProvider {...formMethods}>
        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Flex overflow row={false} expanded={false}>
            {getFormFields(data?.admin.adminFeedTopics).map((field) => (
              <Container overflow key={field.name} expanded>
                <FormField field={field} />
              </Container>
            ))}
            <h3>Metadata</h3>

            {metadataFormFields.length === 0 && <P>No metadata required</P>}

            {metadataFormFields.map((field) => {
              return (
                <Container overflow key={field.name} expanded>
                  <FormField field={field} />
                </Container>
              );
            })}
          </Flex>

          <Column marginTop="half" crossAxisAlignment="flex-end">
            <BlockButton inline tertiary loading={false} type="submit">
              Create new item
            </BlockButton>
          </Column>
        </Form>
      </FormProvider>
    </View>
  );
};

const getFormFields = (adminFeedTopics: AdminFeedTopic[]) => {
  return [
    {
      field: "radio",
      name: "type",
      label: "Item type",
      options: formOptions.fromArray(userCreatableItemTypes, [
        { value: AdminFeedItemTypeEnum.JOURNEY, label: "Journey Category" },
      ]),
      rules: { required: "Please choose type" },
      defaultValue: defaultType,
    },
    {
      field: "checkbox",
      name: "audience",
      label: "Audience who will see this item",
      options: formOptions.fromEnum(AdminFeedItemAudienceTypeEnum),
      rules: { required: "Please choose at least one audience type" },
    },
    {
      field: "radio",
      name: "isPinned",
      label: "Is pinned to the top",
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
      rules: { required: "Please choose whether the item is pinned to the top" },
      defaultValue: "false",
    },
    {
      field: "radio",
      name: "isRecommended",
      label: "Is the post marked as recommended (recommendations can be turned off by subscribed users)",
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
      rules: { required: "Please choose whether the item marked as 'recommended'" },
      defaultValue: "false",
    },
    {
      field: "radio",
      name: "isAdultOnly",
      label: "Is the post target for 18+ audience",
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
    },
    {
      field: "date",
      name: "startDate",
      label: "Show item from given date",
      defaultValue: new Date(),
      rules: { required: "Please provide starting date" },
    },
    {
      field: "date",
      name: "endDate",
      label: "Show item until given date (leave empty to not use)",
      clearable: true,
    },
    {
      field: "text",
      type: "text",
      name: "startDay",
      label: "Show item starting from given days after registration (0 for first day, empty to not use)",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive start day number",
        }),
      },
    },
    {
      field: "text",
      type: "text",
      name: "endDay",
      label: "Show item starting until given days after registration (leave empty to not use)",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive end day number",
        }),
      },
    },
    {
      field: "text",
      type: "number",
      name: "likeCount",
      label: "Initial like count",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive like count",
        }),
      },
      defaultValue: "0",
    },
    {
      field: "text",
      type: "number",
      name: "shareCount",
      label: "Initial share count",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive share count",
        }),
      },
      defaultValue: "0",
    },
    // {
    //   field: "text",
    //   type: "text",
    //   name: "version",
    //   label: "Metadata version",
    //   rules: { required: "Please provide metadata version" },
    //   defaultValue: "1",
    // },
    {
      field: "checkbox",
      name: "topicIds",
      label: "Admin feed item topics",
      options: adminFeedTopics.map((topic) => ({ value: topic.id, label: topic.name })),
      defaultValue: [],
      rules: { required: "Please choose at least one topic" },
    },
  ] as FormFieldOptions[];
};
