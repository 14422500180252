import { gql } from "@apollo/client";
import React from "react";
import { useSetRecoilState } from "recoil";
import { config } from "../../config";
import { useVersionQuery } from "../../schema";
import { showChangelogState } from "../Changelog/Changelog";
import { Container } from "../Container/Container";
import styles from "./Version.module.scss";

// fetch current version
gql`
  query Version {
    version
  }
`;

export const Version: React.FC = () => {
  const setShowChangelog = useSetRecoilState(showChangelogState);

  // fetch server version
  const { data } = useVersionQuery({
    errorPolicy: "ignore",
  });

  return (
    <Container className={styles.version} onClick={() => setShowChangelog(true)} title="Client and server versions">
      {data?.version ? `${config.clientVersion} / ${data.version}` : config.clientVersion}
    </Container>
  );
};
