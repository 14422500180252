import { gql } from "@apollo/client";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useCategoriesQuery, useAdminCreateTrackMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { validateNumeric } from "../../validators/validateNumeric";

gql`
  mutation CreateTrack(
    $createTrackCategoryId: ID
    $createTrackTitle: TranslationInput!
    $createTrackDescription: TranslationInput!
    $createTrackWowzaTrackName: String!
    $createTrackSmallBackgroundImage: Upload!
    $createTrackLargeBackgroundImage: Upload!
    $createTrackTrailerUrl: String
    $createTrackTrackDurationSeconds: Int!
    $createTrackIsFree: Boolean!
    $createTrackTrackNumber: Int
  ) {
    adminCreateTrack(
      createTrackCategoryId: $createTrackCategoryId
      createTrackTitle: $createTrackTitle
      createTrackDescription: $createTrackDescription
      createTrackWowzaTrackName: $createTrackWowzaTrackName
      createTrackSmallBackgroundImage: $createTrackSmallBackgroundImage
      createTrackLargeBackgroundImage: $createTrackLargeBackgroundImage
      createTrackTrailerUrl: $createTrackTrailerUrl
      createTrackTrackDurationSeconds: $createTrackTrackDurationSeconds
      createTrackIsFree: $createTrackIsFree
      createTrackTrackNumber: $createTrackTrackNumber
    ) {
      ...TrackInfo
    }
  }
`;

type CreateTrackData = {
  createTrackCategoryId: string;
  createTrackWowzaTrackName: string;
  createTrackSmallBackgroundImage: string;
  createTrackLargeBackgroundImage: string;
  createTrackTrailerUrl: string;
  createTrackTrackDurationSeconds: string;
  createTrackIsFree: "true" | "false";
  createTrackTrackNumber: string;
  comment: string;
};

export const CreateTrackModal: React.FC<ModalProps> = ({ ...rest }) => {
  const navigate = useNavigate();

  const { data: categoriesData } = useCategoriesQuery();

  const [adminCreateTrack, adminCreateTrackResult] = useAdminCreateTrackMutation();

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "createTrackCategoryId",
        label: "Category",
        options: categoriesData
          ? categoriesData.admin.categories.categories.map((category) => ({
              value: category.id,
              label: category.title,
            }))
          : [],
        rules: {
          required: "Please choose category",
        },
      },
      {
        field: "translation",
        name: "createTrackTitle",
        label: "Title",
        rules: {
          required: "Please provide title",
        },
      },
      {
        field: "translation",
        name: "createTrackDescription",
        label: "Description",
      },
      {
        field: "text",
        type: "text",
        name: "createTrackWowzaTrackName",
        label: "Wowza track name",
        defaultValue: "",
        rules: {
          required: "Please provide wowza track name",
        },
      },
      {
        field: "upload",
        type: "text",
        name: "createTrackSmallBackgroundImage",
        label: "Small background image",
        rules: {
          required: "Please provide small background image",
        },
      },
      {
        field: "upload",
        type: "text",
        name: "createTrackLargeBackgroundImage",
        label: "Large background image",
        rules: {
          required: "Please provide large background image",
        },
      },
      {
        field: "text",
        type: "text",
        name: "createTrackTrailerUrl",
        label: "Track trailer url",
      },
      {
        field: "text",
        type: "text",
        name: "createTrackTrackDurationSeconds",
        label: "Track duration seconds",
        defaultValue: "",
        rules: {
          required: "Please provide track duration seconds",
          validate: validateNumeric({ min: 1 }),
        },
      },
      {
        field: "radio",
        name: "createTrackIsFree",
        label: "Is track free",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: {
          required: "Please choose whether the track is free or not",
        },
      },
      {
        field: "text",
        type: "text",
        name: "createTrackTrackNumber",
        label: "Track number (larger shown later)",
      },
    ],
    [categoriesData],
  );

  const onCreateTrackSubmit = useCallback(
    async (data: CreateTrackData) => {
      const smallBackgroundImage = getFileUploadById("createTrackSmallBackgroundImage");
      const largeBackgroundImage = getFileUploadById("createTrackLargeBackgroundImage");

      // create track
      const response = await adminCreateTrack({
        variables: {
          ...data,
          createTrackTitle: getTranslationInput("createTrackTitle", data),
          createTrackDescription: getTranslationInput("createTrackDescription", data),
          createTrackTrackDurationSeconds: parseInt(data.createTrackTrackDurationSeconds),
          createTrackCategoryId: data.createTrackCategoryId,
          createTrackWowzaTrackName: data.createTrackWowzaTrackName,
          createTrackSmallBackgroundImage: smallBackgroundImage,
          createTrackLargeBackgroundImage: largeBackgroundImage,
          createTrackTrailerUrl: data.createTrackTrailerUrl,
          createTrackIsFree: data.createTrackIsFree === "true",
          createTrackTrackNumber: data.createTrackTrackNumber ? parseInt(data.createTrackTrackNumber) : null,
        },
        // update list of tracks after creating new one
        refetchQueries: ["Tracks"],
        awaitRefetchQueries: true,
      });

      // redirect to details (non-edit mode) when successfully created
      if (response.data) {
        navigate(
          buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "tracks",
            page: "track",
            id: response.data.adminCreateTrack.id,
          }),
        );
      }
    },
    [adminCreateTrack, navigate],
  );

  return (
    <Modal title="Create new track" error={adminCreateTrackResult.error} {...rest}>
      <GeneratedForm
        loading={adminCreateTrackResult.loading}
        error={adminCreateTrackResult.error}
        onSubmit={onCreateTrackSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
