import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { FieldError } from "react-hook-form";
import { DataTableProps, DataTableRow } from "../DataTable/DataTable";
import { P } from "../Paragraph/Paragraph";
import { Table, Td, Th, Tr } from "../Table/Table";
import styles from "./ReorderField.module.scss";

export interface ReorderFieldProps extends DataTableProps {
  name: string;
  label?: string;
  error?: FieldError;
  onReorder(result: DataTableRow[]): void;
}

export const ReorderField: React.FC<ReorderFieldProps> = ({ headers, rows, onReorder, ...rest }) => {
  const reorder = (list: DataTableRow[], source: number, destination: number) => {
    let result = Array.from(list);
    var element = list[source];
    result.splice(source, 1);
    result.splice(destination, 0, element);

    return result;
  };

  const handleValueChange = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const newTrackOrder = reorder(rows, result.source.index, result.destination!.index);

    // update parent state
    return onReorder([...newTrackOrder]);
  };

  return (
    <DragDropContext onDragEnd={handleValueChange}>
      <Table {...rest}>
        <thead>
          <Tr>
            {headers.map((header, index) => {
              const { label, ...rest } = header;

              return (
                <Th key={index} {...rest}>
                  {label}
                </Th>
              );
            })}
          </Tr>
        </thead>
        <Droppable droppableId="reorderList">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {rows.map((row, rowIndex) => {
                const { id, cells, ...rest } = row;
                return (
                  <Draggable draggableId={id} index={rowIndex} key={id}>
                    {(provided) => (
                      <Tr
                        flexRef={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        {...rest}
                        className={styles.reorderRow}
                      >
                        {cells.map((cell, cellIndex) => (
                          <Td key={cellIndex} center={cell.center}>
                            {cell.content}
                          </Td>
                        ))}
                      </Tr>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
      {rows.length === 0 && (
        <P center italic padTop>
          No entries yet
        </P>
      )}
    </DragDropContext>
  );
};
