import { JSONSchema7 } from "json-schema";

export const CourseMetadataSchema: JSONSchema7 = {
  type: "object",
  properties: {
    courseId: {
      description: "Unique identifier of the course",
      type: "string",
    },
  },
  required: ["courseId"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
