export function formatDuration(durationMs: number) {
  const seconds = Math.floor((durationMs / 1000) % 60);
  const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
  const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 60);

  const hoursString = hours > 0 ? `${hours.toString().padStart(2, "0")}:` : "";
  const minutesString = `${minutes.toString().padStart(2, "0")}:`;
  const secondsString = `${seconds.toString().padStart(2, "0")}`;

  return `${hoursString}${minutesString}${secondsString}`;
}
