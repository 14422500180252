import { sentenceCase } from "change-case";
import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, AdminFeedItemInfoFragment } from "../../schema";
import { formatDate } from "../../services/formatDate";
import { getMetadataNameValuePairs } from "../../services/getMetadataSchema";
import { AdminFeedItemDetailsProps } from "./AdminFeedItemDetailsView";

export const AdminFeedItemDetailsInfo: React.FC<AdminFeedItemDetailsProps> = ({ adminFeedItem, viewer }) => (
  <WithCollaboration referenceId={adminFeedItem.id} type={CollaborationTypeEnum.USER_FEED_EVENT} viewer={viewer}>
    <NameValueList items={getAdminFeedItemNameValueList(adminFeedItem)} />
    <h3>Metadata</h3>
    <NameValueList items={getMetadataNameValuePairs(adminFeedItem.type, adminFeedItem.adminFeedMetadata)} />
  </WithCollaboration>
);

export function getAdminFeedItemNameValueList(adminFeedItem: AdminFeedItemInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: adminFeedItem.id,
    },
    {
      name: "Type",
      value: sentenceCase(adminFeedItem.type),
    },
    {
      name: "Status",
      value: sentenceCase(adminFeedItem.status),
    },
    {
      name: "Audience",
      value: adminFeedItem.audience.map((audience) => sentenceCase(audience)).join(", "),
    },
    {
      name: "Start showing from date",
      value: formatDate(adminFeedItem.startDate),
    },
    {
      name: "Stop showing from date",
      value: formatDate(adminFeedItem.endDate),
    },
    {
      name: "Start showing from days after registration",
      value: adminFeedItem.startDay ?? "n/a",
    },
    {
      name: "Stop showing from days after registration",
      value: adminFeedItem.endDay ?? "n/a",
    },
    {
      name: "Is pinned (showed first on top)",
      value: adminFeedItem.isPinned ? "Yes" : "No",
    },
    {
      name: "Is the post target for 18+ audience",
      value: adminFeedItem.isAdultOnly ? "Yes" : "No",
    },
    {
      name: "Number of likes",
      value: adminFeedItem.likeCount,
    },
    {
      name: "Number of shares",
      value: adminFeedItem.shareCount,
    },
    {
      name: "topics",
      value: adminFeedItem.topics.length > 0 ? adminFeedItem.topics.map((topic) => topic.name).toString() : "n/a",
    },
    {
      name: "Created",
      value: adminFeedItem.createdDate,
    },
    {
      name: "Updated",
      value: adminFeedItem.updatedDate,
    },
  ];
}
