import { gql } from "@apollo/client";
import classNames from "classnames";
import React, { useState } from "react";
import { useLogoutMutation } from "../../schema";
import { showNotImplemented } from "../../services/showNotImplemented";
import { ReactComponent as LogoutIcon } from "../../theme/icons/logout-icon.svg";
import { ReactComponent as OptionsIcon } from "../../theme/icons/options-icon.svg";
import { ReactComponent as SettingsIcon } from "../../theme/icons/settings-icon.svg";
import { Avatar } from "../Avatar/Avatar";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { DropdownMenu, DropdownItem } from "../DropdownMenu/DropdownMenu";
import { FlexProps } from "../Flex/Flex";
import { Loading } from "../Loading/Loading";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./ViewerMenu.module.scss";

// log out current user
gql`
  mutation Logout {
    logout
  }
`;

export interface ViewerMenuProps extends FlexProps {
  viewer: {
    email: string;
    name: string;
    avatarUrl?: string | null;
  };
}

export const ViewerMenu: React.FC<ViewerMenuProps> = ({ viewer, ...rest }) => {
  const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);

  const [logout, logoutResult] = useLogoutMutation({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
  });

  return (
    <Row overflow relative crossAxisAlignment="center" {...rest}>
      <Avatar small image={viewer.avatarUrl} />
      <Column padLeft={2} mainAxisAlignment="center">
        <P compact small darker>
          {viewer.name}
        </P>
        <P compact tiny>
          {viewer.email}
        </P>
      </Column>
      <Container
        title="Options"
        className={classNames(styles.options, { [styles["options--open"]]: isOptionsDropdownOpen })}
        onClick={() => setIsOptionsDropdownOpen(!isOptionsDropdownOpen)}
      >
        <OptionsIcon />
      </Container>
      <DropdownMenu
        right={1}
        top={8}
        open={isOptionsDropdownOpen}
        onClickOutside={() => setIsOptionsDropdownOpen(false)}
      >
        <DropdownItem trailing={<SettingsIcon />} onClick={() => showNotImplemented()}>
          Settings
        </DropdownItem>
        <DropdownItem trailing={logoutResult.loading ? <Loading small /> : <LogoutIcon />} onClick={() => logout()}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Row>
  );
};
