import { Avatar } from "../components/Avatar/Avatar";
import { Markdown } from "../components/Markdown/Markdown";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { AuthorInfoFragment } from "../schema";

export function getAuthorNameValueList(author: AuthorInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: author.id,
    },
    {
      name: "Name",
      value: author.name,
    },
    {
      name: "Description",
      value: <Markdown>{author.description}</Markdown>,
    },
    {
      name: "Title",
      value: author.title,
    },
    {
      name: "Homepage url",
      value: author.homepageUrl ?? "n/a",
    },
    {
      name: "Facebook url",
      value: author.facebookUrl ?? "n/a",
    },
    {
      name: "Instagram url",
      value: author.instagramUrl ?? "n/a",
    },
    {
      name: "Twitter url",
      value: author.twitterUrl ?? "n/a",
    },
    {
      name: "Avatar",
      value: <Avatar large image={author.avatarUrl} />,
    },
    {
      name: "Background",
      value: <img src={author.backgroundUrl} alt="Author background" style={{ width: "50rem" }} />,
    },
  ];
}
