import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import {
  AdminFeedPollByIdQueryResult,
  AdminFeedTopicsQueryResult,
  useAdminFeedPollByIdQuery,
  useAdminFeedTopicsQuery,
} from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminFeedPollDetailsEdit } from "./AdminFeedPollDetailsEdit";
import { AdminFeedPollDetailsHeader } from "./AdminFeedPollDetailsHeader";
import { AdminFeedPollDetailsInfo } from "./AdminFeedPollDetailsInfo";

export interface AdminFeedPollDetailsProps extends AdminViewProps {
  adminFeedPoll: NonNullable<AdminFeedPollByIdQueryResult["data"]>["admin"]["adminFeedPoll"];
  topics?: NonNullable<AdminFeedTopicsQueryResult["data"]>["admin"]["adminFeedTopics"];
}

export const AdminFeedPollDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  const { id } = useParams<AdminViewParams>();

  const { data: topics, loading: topicsLoading, error: topicsError } = useAdminFeedTopicsQuery();

  const { data, loading, error } = useAdminFeedPollByIdQuery({
    variables: {
      adminFeedPollId: id ?? "",
    },
  });

  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  const adminFeedPoll = data?.admin.adminFeedPoll;

  if (error || topicsError) {
    return (
      <ErrorView
        title="Loading info failed"
        error={error || topicsError || "Something went wrong, this should not happen"}
      />
    );
  }

  if (loading || !adminFeedPoll || topicsLoading || !topics) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <AdminFeedPollDetailsHeader adminFeedPoll={adminFeedPoll} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<AdminFeedPollDetailsInfo adminFeedPoll={adminFeedPoll} viewer={viewer} />} />
        <Route
          path="edit"
          element={
            <AdminFeedPollDetailsEdit
              adminFeedPoll={adminFeedPoll}
              viewer={viewer}
              topics={topics.admin.adminFeedTopics}
            />
          }
        />
      </Routes>
    </View>
  );
};
