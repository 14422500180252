import { sentenceCase } from "change-case";
import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, AdminFeedTopicInfoFragment } from "../../schema";
import { AdminFeedTopicDetailsProps } from "./AdminFeedTopicDetailsView";

export const AdminFeedTopicDetailsInfo: React.FC<AdminFeedTopicDetailsProps> = ({ adminFeedTopic, viewer }) => (
  <WithCollaboration referenceId={adminFeedTopic.id} type={CollaborationTypeEnum.USER_FEED_EVENT} viewer={viewer}>
    <NameValueList items={getAdminFeedTopicNameValueList(adminFeedTopic)} />
  </WithCollaboration>
);

export function getAdminFeedTopicNameValueList(adminFeedTopic: AdminFeedTopicInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: adminFeedTopic.id,
    },
    {
      name: "Name",
      value: sentenceCase(adminFeedTopic.name),
    },
    {
      name: "Icon",
      value:
        adminFeedTopic.iconUrl.length > 0 ? (
          <img src={adminFeedTopic.iconUrl} alt="Topic icon" style={{ width: "20rem" }} />
        ) : (
          "n/a"
        ),
    },
    {
      name: "Is active",
      value: adminFeedTopic.isActive,
    },
    {
      name: "Is recommendations topic (for ads)",
      value: adminFeedTopic.isRecommendation,
    },
    {
      name: "Created",
      value: adminFeedTopic.createdDate,
    },
    {
      name: "Updated",
      value: adminFeedTopic.updatedDate,
    },
  ];
}
